// @ts-nocheck
import { name2num, num2name, } from "./(LearningCell)/State";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(LearningCell)/Preview";
import { name2num as name2num_1, num2name as num2name_1, } from "./(LearningCell)/ParentType";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(LearningCell)/Progress";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as Long, } from "../../runtime/Long";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        sessionId: "0",
        state: "STATE_UNSPECIFIED",
        preview: undefined,
        parentType: "PARENT_TYPE_UNSPECIFIED",
        progress: undefined,
        cycleId: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.sessionId !== undefined)
        result.sessionId = tsValueToJsonValueFns.int64(value.sessionId);
    if (value.state !== undefined)
        result.state = tsValueToJsonValueFns.enum(value.state);
    if (value.preview !== undefined)
        result.preview = encodeJson_1(value.preview);
    if (value.parentType !== undefined)
        result.parentType = tsValueToJsonValueFns.enum(value.parentType);
    if (value.progress !== undefined)
        result.progress = encodeJson_2(value.progress);
    if (value.cycleId !== undefined)
        result.cycleId = tsValueToJsonValueFns.int64(value.cycleId);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.sessionId !== undefined)
        result.sessionId = jsonValueToTsValueFns.int64(value.sessionId);
    if (value.state !== undefined)
        result.state = jsonValueToTsValueFns.enum(value.state);
    if (value.preview !== undefined)
        result.preview = decodeJson_1(value.preview);
    if (value.parentType !== undefined)
        result.parentType = jsonValueToTsValueFns.enum(value.parentType);
    if (value.progress !== undefined)
        result.progress = decodeJson_2(value.progress);
    if (value.cycleId !== undefined)
        result.cycleId = jsonValueToTsValueFns.int64(value.cycleId);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.sessionId !== undefined) {
        const tsValue = value.sessionId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.state !== undefined) {
        const tsValue = value.state;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.preview !== undefined) {
        const tsValue = value.preview;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.parentType !== undefined) {
        const tsValue = value.parentType;
        result.push([4, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.progress !== undefined) {
        const tsValue = value.progress;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.cycleId !== undefined) {
        const tsValue = value.cycleId;
        result.push([6, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.sessionId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.state = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.preview = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.parentType = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.progress = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.cycleId = value;
    }
    return result;
}
