// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../runtime/wire/scalar";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../../google/protobuf/Int64Value";
import { encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../../../google/protobuf/FloatValue";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        totalCount: "0",
        studiedCount: "0",
        unnecessaryCount: undefined,
        estimatedCorrectnessRate: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.totalCount !== undefined)
        result.totalCount = tsValueToJsonValueFns.int64(value.totalCount);
    if (value.studiedCount !== undefined)
        result.studiedCount = tsValueToJsonValueFns.int64(value.studiedCount);
    if (value.unnecessaryCount !== undefined)
        result.unnecessaryCount = value.unnecessaryCount;
    if (value.estimatedCorrectnessRate !== undefined)
        result.estimatedCorrectnessRate = value.estimatedCorrectnessRate;
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.totalCount !== undefined)
        result.totalCount = jsonValueToTsValueFns.int64(value.totalCount);
    if (value.studiedCount !== undefined)
        result.studiedCount = jsonValueToTsValueFns.int64(value.studiedCount);
    if (value.unnecessaryCount !== undefined)
        result.unnecessaryCount = value.unnecessaryCount;
    if (value.estimatedCorrectnessRate !== undefined)
        result.estimatedCorrectnessRate = value.estimatedCorrectnessRate;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.totalCount !== undefined) {
        const tsValue = value.totalCount;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.studiedCount !== undefined) {
        const tsValue = value.studiedCount;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.unnecessaryCount !== undefined) {
        const tsValue = value.unnecessaryCount;
        result.push([3, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.estimatedCorrectnessRate !== undefined) {
        const tsValue = value.estimatedCorrectnessRate;
        result.push([4, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a, _b;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.totalCount = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.studiedCount = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.unnecessaryCount = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = (_b = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _b === void 0 ? void 0 : _b.value;
        if (value === undefined)
            break field;
        result.estimatedCorrectnessRate = value;
    }
    return result;
}
