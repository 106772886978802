export const num2name = {
    0: "STATUS_UNSPECIFIED",
    1: "STATUS_REGISTERED",
    2: "STATUS_USED",
    3: "STATUS_CREATED",
    4: "STATUS_REVOKED",
    5: "STATUS_CREATED_EXPIRED",
    6: "STATUS_REGISTERED_EXPIRED",
};
export const name2num = {
    STATUS_UNSPECIFIED: 0,
    STATUS_REGISTERED: 1,
    STATUS_USED: 2,
    STATUS_CREATED: 3,
    STATUS_REVOKED: 4,
    STATUS_CREATED_EXPIRED: 5,
    STATUS_REGISTERED_EXPIRED: 6,
};
