// @ts-nocheck
import { name2num, num2name, } from "./ToeicResourceType";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as Long, } from "../../runtime/Long";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        contentResourceId: "",
        mimeType: "",
        displayName: "",
        type: "PRIVATE",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.contentResourceId !== undefined)
        result.contentResourceId = tsValueToJsonValueFns.string(value.contentResourceId);
    if (value.mimeType !== undefined)
        result.mimeType = tsValueToJsonValueFns.string(value.mimeType);
    if (value.displayName !== undefined)
        result.displayName = tsValueToJsonValueFns.string(value.displayName);
    if (value.type !== undefined)
        result.type = tsValueToJsonValueFns.enum(value.type);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.contentResourceId !== undefined)
        result.contentResourceId = jsonValueToTsValueFns.string(value.contentResourceId);
    if (value.mimeType !== undefined)
        result.mimeType = jsonValueToTsValueFns.string(value.mimeType);
    if (value.displayName !== undefined)
        result.displayName = jsonValueToTsValueFns.string(value.displayName);
    if (value.type !== undefined)
        result.type = jsonValueToTsValueFns.enum(value.type);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.contentResourceId !== undefined) {
        const tsValue = value.contentResourceId;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.mimeType !== undefined) {
        const tsValue = value.mimeType;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.displayName !== undefined) {
        const tsValue = value.displayName;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.type !== undefined) {
        const tsValue = value.type;
        result.push([5, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.contentResourceId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.mimeType = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.displayName = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.type = value;
    }
    return result;
}
