// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        orderId: "0",
        durationAmount: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.orderId !== undefined)
        result.orderId = tsValueToJsonValueFns.int64(value.orderId);
    switch ((_a = value.durationAmount) === null || _a === void 0 ? void 0 : _a.field) {
        case "days": {
            result.days = tsValueToJsonValueFns.int64(value.durationAmount.value);
            break;
        }
        case "months": {
            result.months = tsValueToJsonValueFns.int64(value.durationAmount.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.orderId !== undefined)
        result.orderId = jsonValueToTsValueFns.int64(value.orderId);
    if (value.days !== undefined)
        result.durationAmount = { field: "days", value: jsonValueToTsValueFns.int64(value.days) };
    if (value.months !== undefined)
        result.durationAmount = { field: "months", value: jsonValueToTsValueFns.int64(value.months) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.orderId !== undefined) {
        const tsValue = value.orderId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    switch ((_a = value.durationAmount) === null || _a === void 0 ? void 0 : _a.field) {
        case "days": {
            const tsValue = value.durationAmount.value;
            result.push([2, tsValueToWireValueFns.int64(tsValue)]);
            break;
        }
        case "months": {
            const tsValue = value.durationAmount.value;
            result.push([3, tsValueToWireValueFns.int64(tsValue)]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    durationAmount: new Set([2, 3]),
};
const oneofFieldNamesMap = {
    durationAmount: new Map([
        [2, "days"],
        [3, "months"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.orderId = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.durationAmount;
        const oneofFieldNames = oneofFieldNamesMap.durationAmount;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [2](wireValue) { return wireValueToTsValueFns.int64(wireValue); },
            [3](wireValue) { return wireValueToTsValueFns.int64(wireValue); },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.durationAmount = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
