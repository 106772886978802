// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        part1: "0",
        part2: "0",
        part3: "0",
        part4: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.part1 !== undefined)
        result.part1 = tsValueToJsonValueFns.int64(value.part1);
    if (value.part2 !== undefined)
        result.part2 = tsValueToJsonValueFns.int64(value.part2);
    if (value.part3 !== undefined)
        result.part3 = tsValueToJsonValueFns.int64(value.part3);
    if (value.part4 !== undefined)
        result.part4 = tsValueToJsonValueFns.int64(value.part4);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.part1 !== undefined)
        result.part1 = jsonValueToTsValueFns.int64(value.part1);
    if (value.part2 !== undefined)
        result.part2 = jsonValueToTsValueFns.int64(value.part2);
    if (value.part3 !== undefined)
        result.part3 = jsonValueToTsValueFns.int64(value.part3);
    if (value.part4 !== undefined)
        result.part4 = jsonValueToTsValueFns.int64(value.part4);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.part1 !== undefined) {
        const tsValue = value.part1;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.part2 !== undefined) {
        const tsValue = value.part2;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.part3 !== undefined) {
        const tsValue = value.part3;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.part4 !== undefined) {
        const tsValue = value.part4;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.part1 = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.part2 = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.part3 = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.part4 = value;
    }
    return result;
}
