// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./Product";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        description: "",
        boardCode: "",
        products: [],
        recommendedProducts: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.description !== undefined)
        result.description = tsValueToJsonValueFns.string(value.description);
    if (value.boardCode !== undefined)
        result.boardCode = tsValueToJsonValueFns.string(value.boardCode);
    result.products = value.products.map(value => encodeJson_1(value));
    result.recommendedProducts = value.recommendedProducts.map(value => encodeJson_1(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.description !== undefined)
        result.description = jsonValueToTsValueFns.string(value.description);
    if (value.boardCode !== undefined)
        result.boardCode = jsonValueToTsValueFns.string(value.boardCode);
    result.products = (_b = (_a = value.products) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    result.recommendedProducts = (_d = (_c = value.recommendedProducts) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_1(value))) !== null && _d !== void 0 ? _d : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.description !== undefined) {
        const tsValue = value.description;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.boardCode !== undefined) {
        const tsValue = value.boardCode;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.products) {
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.recommendedProducts) {
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.description = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.boardCode = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.products = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.recommendedProducts = value;
    }
    return result;
}
