// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Duration";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        totalElapsedTime: undefined,
        title: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.totalElapsedTime !== undefined)
        result.totalElapsedTime = encodeJson_1(value.totalElapsedTime);
    if (value.title !== undefined)
        result.title = tsValueToJsonValueFns.string(value.title);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.totalElapsedTime !== undefined)
        result.totalElapsedTime = decodeJson_1(value.totalElapsedTime);
    if (value.title !== undefined)
        result.title = jsonValueToTsValueFns.string(value.title);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.totalElapsedTime !== undefined) {
        const tsValue = value.totalElapsedTime;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.title !== undefined) {
        const tsValue = value.title;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.totalElapsedTime = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.title = value;
    }
    return result;
}
