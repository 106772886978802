export const num2name = {
    0: "UNSPECIFIED",
    1: "ASCENDING",
    2: "DESCENDING",
};
export const name2num = {
    UNSPECIFIED: 0,
    ASCENDING: 1,
    DESCENDING: 2,
};
