export const num2name = {
    0: "SUBSCRIPTION_ORDER_AVAILABILITY_UNSPECIFIED",
    1: "SUBSCRIPTION_ORDER_AVAILABILITY_AVAILABLE",
    2: "SUBSCRIPTION_ORDER_AVAILABILITY_UNAVAILABLE_NOT_EXPIRED_SUBSCRIPTION_EXIST",
    3: "SUBSCRIPTION_ORDER_AVAILABILITY_UNAVAILABLE_HAS_AUTHORITY",
    4: "SUBSCRIPTION_ORDER_AVAILABILITY_UNAVAILABLE_HAS_FULFILLMENT",
};
export const name2num = {
    SUBSCRIPTION_ORDER_AVAILABILITY_UNSPECIFIED: 0,
    SUBSCRIPTION_ORDER_AVAILABILITY_AVAILABLE: 1,
    SUBSCRIPTION_ORDER_AVAILABILITY_UNAVAILABLE_NOT_EXPIRED_SUBSCRIPTION_EXIST: 2,
    SUBSCRIPTION_ORDER_AVAILABILITY_UNAVAILABLE_HAS_AUTHORITY: 3,
    SUBSCRIPTION_ORDER_AVAILABILITY_UNAVAILABLE_HAS_FULFILLMENT: 4,
};
