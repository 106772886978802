export const num2name = {
    0: "LC",
    1: "RC",
    2: "EMPTY",
};
export const name2num = {
    LC: 0,
    RC: 1,
    EMPTY: 2,
};
