// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Duration";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        totalElapsedTime: undefined,
        recommendedElapsedTime: undefined,
        correctCount: "0",
        totalCount: "0",
        chapter: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.totalElapsedTime !== undefined)
        result.totalElapsedTime = encodeJson_1(value.totalElapsedTime);
    if (value.recommendedElapsedTime !== undefined)
        result.recommendedElapsedTime = encodeJson_1(value.recommendedElapsedTime);
    if (value.correctCount !== undefined)
        result.correctCount = tsValueToJsonValueFns.int64(value.correctCount);
    if (value.totalCount !== undefined)
        result.totalCount = tsValueToJsonValueFns.int64(value.totalCount);
    if (value.chapter !== undefined)
        result.chapter = tsValueToJsonValueFns.string(value.chapter);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.totalElapsedTime !== undefined)
        result.totalElapsedTime = decodeJson_1(value.totalElapsedTime);
    if (value.recommendedElapsedTime !== undefined)
        result.recommendedElapsedTime = decodeJson_1(value.recommendedElapsedTime);
    if (value.correctCount !== undefined)
        result.correctCount = jsonValueToTsValueFns.int64(value.correctCount);
    if (value.totalCount !== undefined)
        result.totalCount = jsonValueToTsValueFns.int64(value.totalCount);
    if (value.chapter !== undefined)
        result.chapter = jsonValueToTsValueFns.string(value.chapter);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.totalElapsedTime !== undefined) {
        const tsValue = value.totalElapsedTime;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.recommendedElapsedTime !== undefined) {
        const tsValue = value.recommendedElapsedTime;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.correctCount !== undefined) {
        const tsValue = value.correctCount;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.totalCount !== undefined) {
        const tsValue = value.totalCount;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.chapter !== undefined) {
        const tsValue = value.chapter;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.totalElapsedTime = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.recommendedElapsedTime = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.correctCount = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.totalCount = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.chapter = value;
    }
    return result;
}
