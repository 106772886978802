// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./EstimatedScore";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./Block";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./LearningCell";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "../google/protobuf/Duration";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./LearningInformation";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        ordinalNumber: "0",
        estimatedScore: undefined,
        blocks: [],
        cells: [],
        totalElapsedTime: undefined,
        learningInformation: undefined,
        previousEstimatedScore: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.ordinalNumber !== undefined)
        result.ordinalNumber = tsValueToJsonValueFns.int64(value.ordinalNumber);
    if (value.estimatedScore !== undefined)
        result.estimatedScore = encodeJson_1(value.estimatedScore);
    result.blocks = value.blocks.map(value => encodeJson_2(value));
    result.cells = value.cells.map(value => encodeJson_3(value));
    if (value.totalElapsedTime !== undefined)
        result.totalElapsedTime = encodeJson_4(value.totalElapsedTime);
    if (value.learningInformation !== undefined)
        result.learningInformation = encodeJson_5(value.learningInformation);
    if (value.previousEstimatedScore !== undefined)
        result.previousEstimatedScore = encodeJson_1(value.previousEstimatedScore);
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.ordinalNumber !== undefined)
        result.ordinalNumber = jsonValueToTsValueFns.int64(value.ordinalNumber);
    if (value.estimatedScore !== undefined)
        result.estimatedScore = decodeJson_1(value.estimatedScore);
    result.blocks = (_b = (_a = value.blocks) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_2(value))) !== null && _b !== void 0 ? _b : [];
    result.cells = (_d = (_c = value.cells) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_3(value))) !== null && _d !== void 0 ? _d : [];
    if (value.totalElapsedTime !== undefined)
        result.totalElapsedTime = decodeJson_4(value.totalElapsedTime);
    if (value.learningInformation !== undefined)
        result.learningInformation = decodeJson_5(value.learningInformation);
    if (value.previousEstimatedScore !== undefined)
        result.previousEstimatedScore = decodeJson_1(value.previousEstimatedScore);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.ordinalNumber !== undefined) {
        const tsValue = value.ordinalNumber;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.estimatedScore !== undefined) {
        const tsValue = value.estimatedScore;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.blocks) {
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    for (const tsValue of value.cells) {
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.totalElapsedTime !== undefined) {
        const tsValue = value.totalElapsedTime;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.learningInformation !== undefined) {
        const tsValue = value.learningInformation;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
    }
    if (value.previousEstimatedScore !== undefined) {
        const tsValue = value.previousEstimatedScore;
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.ordinalNumber = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.estimatedScore = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.blocks = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.cells = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.totalElapsedTime = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.learningInformation = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.previousEstimatedScore = value;
    }
    return result;
}
