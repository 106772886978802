export const num2name = {
    0: "NO_ERROR",
    1: "ERROR_INVALID_PRODUCT",
    2: "ERROR_PAYMENT_UNAVAILABLE",
};
export const name2num = {
    NO_ERROR: 0,
    ERROR_INVALID_PRODUCT: 1,
    ERROR_PAYMENT_UNAVAILABLE: 2,
};
