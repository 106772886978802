// @ts-nocheck
import { name2num, num2name, } from "./(B2bClass)/StartMode";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Timestamp";
import { name2num as name2num_1, num2name as num2name_1, } from "../../inside/Country";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(B2bClass)/Extra";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        organizationId: "0",
        startMode: "START_MODE_UNSPECIFIED",
        validFrom: undefined,
        validUntil: undefined,
        learningDays: "0",
        completionMinutes: "0",
        planName: "",
        productId: "0",
        zoneOffsetMinutes: "0",
        country: "UNSPECIFIED_COUNTRY",
        maxCapacity: "0",
        extra: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.organizationId !== undefined)
        result.organizationId = tsValueToJsonValueFns.int64(value.organizationId);
    if (value.startMode !== undefined)
        result.startMode = tsValueToJsonValueFns.enum(value.startMode);
    if (value.validFrom !== undefined)
        result.validFrom = encodeJson_1(value.validFrom);
    if (value.validUntil !== undefined)
        result.validUntil = encodeJson_1(value.validUntil);
    if (value.learningDays !== undefined)
        result.learningDays = tsValueToJsonValueFns.int64(value.learningDays);
    if (value.completionMinutes !== undefined)
        result.completionMinutes = tsValueToJsonValueFns.int64(value.completionMinutes);
    if (value.planName !== undefined)
        result.planName = tsValueToJsonValueFns.string(value.planName);
    if (value.productId !== undefined)
        result.productId = tsValueToJsonValueFns.int64(value.productId);
    if (value.zoneOffsetMinutes !== undefined)
        result.zoneOffsetMinutes = tsValueToJsonValueFns.int64(value.zoneOffsetMinutes);
    if (value.country !== undefined)
        result.country = tsValueToJsonValueFns.enum(value.country);
    if (value.maxCapacity !== undefined)
        result.maxCapacity = tsValueToJsonValueFns.int64(value.maxCapacity);
    if (value.extra !== undefined)
        result.extra = encodeJson_2(value.extra);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.organizationId !== undefined)
        result.organizationId = jsonValueToTsValueFns.int64(value.organizationId);
    if (value.startMode !== undefined)
        result.startMode = jsonValueToTsValueFns.enum(value.startMode);
    if (value.validFrom !== undefined)
        result.validFrom = decodeJson_1(value.validFrom);
    if (value.validUntil !== undefined)
        result.validUntil = decodeJson_1(value.validUntil);
    if (value.learningDays !== undefined)
        result.learningDays = jsonValueToTsValueFns.int64(value.learningDays);
    if (value.completionMinutes !== undefined)
        result.completionMinutes = jsonValueToTsValueFns.int64(value.completionMinutes);
    if (value.planName !== undefined)
        result.planName = jsonValueToTsValueFns.string(value.planName);
    if (value.productId !== undefined)
        result.productId = jsonValueToTsValueFns.int64(value.productId);
    if (value.zoneOffsetMinutes !== undefined)
        result.zoneOffsetMinutes = jsonValueToTsValueFns.int64(value.zoneOffsetMinutes);
    if (value.country !== undefined)
        result.country = jsonValueToTsValueFns.enum(value.country);
    if (value.maxCapacity !== undefined)
        result.maxCapacity = jsonValueToTsValueFns.int64(value.maxCapacity);
    if (value.extra !== undefined)
        result.extra = decodeJson_2(value.extra);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.organizationId !== undefined) {
        const tsValue = value.organizationId;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.startMode !== undefined) {
        const tsValue = value.startMode;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.validFrom !== undefined) {
        const tsValue = value.validFrom;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.validUntil !== undefined) {
        const tsValue = value.validUntil;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.learningDays !== undefined) {
        const tsValue = value.learningDays;
        result.push([6, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.completionMinutes !== undefined) {
        const tsValue = value.completionMinutes;
        result.push([7, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.planName !== undefined) {
        const tsValue = value.planName;
        result.push([8, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.productId !== undefined) {
        const tsValue = value.productId;
        result.push([9, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.zoneOffsetMinutes !== undefined) {
        const tsValue = value.zoneOffsetMinutes;
        result.push([10, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.country !== undefined) {
        const tsValue = value.country;
        result.push([11, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.maxCapacity !== undefined) {
        const tsValue = value.maxCapacity;
        result.push([12, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.extra !== undefined) {
        const tsValue = value.extra;
        result.push([13, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.organizationId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.startMode = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.validFrom = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.validUntil = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.learningDays = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.completionMinutes = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.planName = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.productId = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.zoneOffsetMinutes = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.country = value;
    }
    field: {
        const wireValue = wireFields.get(12);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.maxCapacity = value;
    }
    field: {
        const wireValue = wireFields.get(13);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.extra = value;
    }
    return result;
}
