export const num2name = {
    0: "START_MODE_UNSPECIFIED",
    1: "BY_ORGANIZATION",
    2: "BY_STUDENT",
};
export const name2num = {
    START_MODE_UNSPECIFIED: 0,
    BY_ORGANIZATION: 1,
    BY_STUDENT: 2,
};
