export const num2name = {
    0: "TYPE_UNSPECIFIED",
    1: "TYPE_ENTER",
    2: "TYPE_FOCUS",
    3: "TYPE_PLACE_ANSWER",
    4: "TYPE_SUBMIT",
};
export const name2num = {
    TYPE_UNSPECIFIED: 0,
    TYPE_ENTER: 1,
    TYPE_FOCUS: 2,
    TYPE_PLACE_ANSWER: 3,
    TYPE_SUBMIT: 4,
};
