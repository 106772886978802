// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../../../toeic/ToeicResourceImageSet";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        title: "",
        subText: "",
        description: "",
        imageSet: undefined,
        applySkillTab: false,
        isPartTag: false,
        isFree: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.title !== undefined)
        result.title = tsValueToJsonValueFns.string(value.title);
    if (value.subText !== undefined)
        result.subText = tsValueToJsonValueFns.string(value.subText);
    if (value.description !== undefined)
        result.description = tsValueToJsonValueFns.string(value.description);
    if (value.imageSet !== undefined)
        result.imageSet = encodeJson_1(value.imageSet);
    if (value.applySkillTab !== undefined)
        result.applySkillTab = tsValueToJsonValueFns.bool(value.applySkillTab);
    if (value.isPartTag !== undefined)
        result.isPartTag = tsValueToJsonValueFns.bool(value.isPartTag);
    if (value.isFree !== undefined)
        result.isFree = tsValueToJsonValueFns.bool(value.isFree);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.title !== undefined)
        result.title = jsonValueToTsValueFns.string(value.title);
    if (value.subText !== undefined)
        result.subText = jsonValueToTsValueFns.string(value.subText);
    if (value.description !== undefined)
        result.description = jsonValueToTsValueFns.string(value.description);
    if (value.imageSet !== undefined)
        result.imageSet = decodeJson_1(value.imageSet);
    if (value.applySkillTab !== undefined)
        result.applySkillTab = jsonValueToTsValueFns.bool(value.applySkillTab);
    if (value.isPartTag !== undefined)
        result.isPartTag = jsonValueToTsValueFns.bool(value.isPartTag);
    if (value.isFree !== undefined)
        result.isFree = jsonValueToTsValueFns.bool(value.isFree);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.title !== undefined) {
        const tsValue = value.title;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.subText !== undefined) {
        const tsValue = value.subText;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.description !== undefined) {
        const tsValue = value.description;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.imageSet !== undefined) {
        const tsValue = value.imageSet;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.applySkillTab !== undefined) {
        const tsValue = value.applySkillTab;
        result.push([5, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.isPartTag !== undefined) {
        const tsValue = value.isPartTag;
        result.push([6, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.isFree !== undefined) {
        const tsValue = value.isFree;
        result.push([7, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.title = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.subText = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.description = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.imageSet = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.applySkillTab = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isPartTag = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isFree = value;
    }
    return result;
}
