export const num2name = {
    0: "STATUS_UNSPECIFIED",
    1: "STATUS_UNPUBLISHED",
    2: "STATUS_PUBLISHED",
    3: "STATUS_OUTDATED",
};
export const name2num = {
    STATUS_UNSPECIFIED: 0,
    STATUS_UNPUBLISHED: 1,
    STATUS_PUBLISHED: 2,
    STATUS_OUTDATED: 3,
};
