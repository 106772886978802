export const num2name = {
    0: "PART_UNSPECIFIED",
    1: "PART_1",
    2: "PART_2",
    3: "PART_3",
    4: "PART_4",
    5: "PART_5",
    6: "PART_6",
    7: "PART_7",
};
export const name2num = {
    PART_UNSPECIFIED: 0,
    PART_1: 1,
    PART_2: 2,
    PART_3: 3,
    PART_4: 4,
    PART_5: 5,
    PART_6: 6,
    PART_7: 7,
};
