export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_CONTENT_NOT_FOUND",
    2: "ERROR_SENTENCE_STRUCTURE_DOES_NOT_EXIST",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_CONTENT_NOT_FOUND: 1,
    ERROR_SENTENCE_STRUCTURE_DOES_NOT_EXIST: 2,
};
