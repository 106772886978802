// @ts-nocheck
import { name2num, num2name, } from "./LearningCycleType";
import { name2num as name2num_1, num2name as num2name_1, } from "./LearningCycleStatus";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../analytics/v1/EstimatedScore";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { default as Long, } from "../../../../../runtime/Long";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        learningCycleId: "0",
        type: "LEARNING_CYCLE_TYPE_UNSPECIFIED",
        status: "LEARNING_CYCLE_STATUS_UNSPECIFIED",
        ordinalNumber: "0",
        estimatedScore: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.learningCycleId !== undefined)
        result.learningCycleId = tsValueToJsonValueFns.int64(value.learningCycleId);
    if (value.type !== undefined)
        result.type = tsValueToJsonValueFns.enum(value.type);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    if (value.ordinalNumber !== undefined)
        result.ordinalNumber = tsValueToJsonValueFns.int64(value.ordinalNumber);
    if (value.estimatedScore !== undefined)
        result.estimatedScore = encodeJson_1(value.estimatedScore);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.learningCycleId !== undefined)
        result.learningCycleId = jsonValueToTsValueFns.int64(value.learningCycleId);
    if (value.type !== undefined)
        result.type = jsonValueToTsValueFns.enum(value.type);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    if (value.ordinalNumber !== undefined)
        result.ordinalNumber = jsonValueToTsValueFns.int64(value.ordinalNumber);
    if (value.estimatedScore !== undefined)
        result.estimatedScore = decodeJson_1(value.estimatedScore);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.learningCycleId !== undefined) {
        const tsValue = value.learningCycleId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.type !== undefined) {
        const tsValue = value.type;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([3, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.ordinalNumber !== undefined) {
        const tsValue = value.ordinalNumber;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.estimatedScore !== undefined) {
        const tsValue = value.estimatedScore;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.learningCycleId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.type = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.ordinalNumber = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.estimatedScore = value;
    }
    return result;
}
