// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        lcElapsedTimeMs: "0",
        rcElapsedTimeMs: "0",
        part5ElapsedTimeMs: "0",
        part6ElapsedTimeMs: "0",
        part7ElapsedTimeMs: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.lcElapsedTimeMs !== undefined)
        result.lcElapsedTimeMs = tsValueToJsonValueFns.int64(value.lcElapsedTimeMs);
    if (value.rcElapsedTimeMs !== undefined)
        result.rcElapsedTimeMs = tsValueToJsonValueFns.int64(value.rcElapsedTimeMs);
    if (value.part5ElapsedTimeMs !== undefined)
        result.part5ElapsedTimeMs = tsValueToJsonValueFns.int64(value.part5ElapsedTimeMs);
    if (value.part6ElapsedTimeMs !== undefined)
        result.part6ElapsedTimeMs = tsValueToJsonValueFns.int64(value.part6ElapsedTimeMs);
    if (value.part7ElapsedTimeMs !== undefined)
        result.part7ElapsedTimeMs = tsValueToJsonValueFns.int64(value.part7ElapsedTimeMs);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.lcElapsedTimeMs !== undefined)
        result.lcElapsedTimeMs = jsonValueToTsValueFns.int64(value.lcElapsedTimeMs);
    if (value.rcElapsedTimeMs !== undefined)
        result.rcElapsedTimeMs = jsonValueToTsValueFns.int64(value.rcElapsedTimeMs);
    if (value.part5ElapsedTimeMs !== undefined)
        result.part5ElapsedTimeMs = jsonValueToTsValueFns.int64(value.part5ElapsedTimeMs);
    if (value.part6ElapsedTimeMs !== undefined)
        result.part6ElapsedTimeMs = jsonValueToTsValueFns.int64(value.part6ElapsedTimeMs);
    if (value.part7ElapsedTimeMs !== undefined)
        result.part7ElapsedTimeMs = jsonValueToTsValueFns.int64(value.part7ElapsedTimeMs);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.lcElapsedTimeMs !== undefined) {
        const tsValue = value.lcElapsedTimeMs;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.rcElapsedTimeMs !== undefined) {
        const tsValue = value.rcElapsedTimeMs;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.part5ElapsedTimeMs !== undefined) {
        const tsValue = value.part5ElapsedTimeMs;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.part6ElapsedTimeMs !== undefined) {
        const tsValue = value.part6ElapsedTimeMs;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.part7ElapsedTimeMs !== undefined) {
        const tsValue = value.part7ElapsedTimeMs;
        result.push([5, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.lcElapsedTimeMs = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.rcElapsedTimeMs = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.part5ElapsedTimeMs = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.part6ElapsedTimeMs = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.part7ElapsedTimeMs = value;
    }
    return result;
}
