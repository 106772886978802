// @ts-nocheck
import { name2num as name2num_1, num2name as num2name_1, } from "../(SelfCardCategory)/Lesson";
import { name2num as name2num_2, num2name as num2name_2, } from "../(SelfCardCategory)/Vocabulary";
import { name2num as name2num_3, num2name as num2name_3, } from "../(SelfCardCategory)/VirtualExam";
import { name2num as name2num_4, num2name as num2name_4, } from "../(SelfCardCategory)/LessonV2";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../Pageable";
import { name2num, num2name, } from "../Part";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
import { unpackFns, } from "../../../runtime/wire/scalar";
export function getDefaultValue() {
    return {
        pageable: undefined,
        lessonPartFilter: [],
        contentGroupList: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.pageable !== undefined)
        result.pageable = encodeJson_1(value.pageable);
    result.lessonPartFilter = value.lessonPartFilter.map(value => tsValueToJsonValueFns.enum(value));
    switch ((_a = value.contentGroupList) === null || _a === void 0 ? void 0 : _a.field) {
        case "lesson": {
            result.lesson = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
        case "vocabulary": {
            result.vocabulary = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
        case "virtualExam": {
            result.virtualExam = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
        case "lessonV2": {
            result.lessonV2 = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.pageable !== undefined)
        result.pageable = decodeJson_1(value.pageable);
    result.lessonPartFilter = (_b = (_a = value.lessonPartFilter) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _b !== void 0 ? _b : [];
    if (value.lesson !== undefined)
        result.contentGroupList = { field: "lesson", value: jsonValueToTsValueFns.enum(value.lesson) };
    if (value.vocabulary !== undefined)
        result.contentGroupList = { field: "vocabulary", value: jsonValueToTsValueFns.enum(value.vocabulary) };
    if (value.virtualExam !== undefined)
        result.contentGroupList = { field: "virtualExam", value: jsonValueToTsValueFns.enum(value.virtualExam) };
    if (value.lessonV2 !== undefined)
        result.contentGroupList = { field: "lessonV2", value: jsonValueToTsValueFns.enum(value.lessonV2) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.pageable !== undefined) {
        const tsValue = value.pageable;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.lessonPartFilter) {
        result.push([5, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    switch ((_a = value.contentGroupList) === null || _a === void 0 ? void 0 : _a.field) {
        case "lesson": {
            const tsValue = value.contentGroupList.value;
            result.push([2, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
            break;
        }
        case "vocabulary": {
            const tsValue = value.contentGroupList.value;
            result.push([4, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
            break;
        }
        case "virtualExam": {
            const tsValue = value.contentGroupList.value;
            result.push([6, { type: WireType.Varint, value: new Long(name2num_3[tsValue]) }]);
            break;
        }
        case "lessonV2": {
            const tsValue = value.contentGroupList.value;
            result.push([7, { type: WireType.Varint, value: new Long(name2num_4[tsValue]) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    contentGroupList: new Set([2, 4, 6, 7]),
};
const oneofFieldNamesMap = {
    contentGroupList: new Map([
        [2, "lesson"],
        [4, "vocabulary"],
        [6, "virtualExam"],
        [7, "lessonV2"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.pageable = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name[num]);
        if (!value.length)
            break collection;
        result.lessonPartFilter = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.contentGroupList;
        const oneofFieldNames = oneofFieldNamesMap.contentGroupList;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [2](wireValue) { return wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined; },
            [4](wireValue) { return wireValue.type === WireType.Varint ? num2name_2[wireValue.value[0]] : undefined; },
            [6](wireValue) { return wireValue.type === WireType.Varint ? num2name_3[wireValue.value[0]] : undefined; },
            [7](wireValue) { return wireValue.type === WireType.Varint ? num2name_4[wireValue.value[0]] : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.contentGroupList = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
