// @ts-nocheck
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./(FastCatSession)/OnGoing";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./(FastCatSession)/Finished";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../../inside/ContentInfo";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(FastCatSession)/QuestionLayout";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./FastCatInteraction";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        learningSessionId: "0",
        authId: "",
        contentGroupId: "",
        contentInfos: [],
        questionLayout: [],
        savedInteractions: [],
        state: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.learningSessionId !== undefined)
        result.learningSessionId = tsValueToJsonValueFns.int64(value.learningSessionId);
    if (value.authId !== undefined)
        result.authId = tsValueToJsonValueFns.string(value.authId);
    if (value.contentGroupId !== undefined)
        result.contentGroupId = tsValueToJsonValueFns.string(value.contentGroupId);
    result.contentInfos = value.contentInfos.map(value => encodeJson_1(value));
    result.questionLayout = value.questionLayout.map(value => encodeJson_2(value));
    result.savedInteractions = value.savedInteractions.map(value => encodeJson_3(value));
    switch ((_a = value.state) === null || _a === void 0 ? void 0 : _a.field) {
        case "ongoing": {
            result.ongoing = encodeJson_4(value.state.value);
            break;
        }
        case "finished": {
            result.finished = encodeJson_5(value.state.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f;
    const result = getDefaultValue();
    if (value.learningSessionId !== undefined)
        result.learningSessionId = jsonValueToTsValueFns.int64(value.learningSessionId);
    if (value.authId !== undefined)
        result.authId = jsonValueToTsValueFns.string(value.authId);
    if (value.contentGroupId !== undefined)
        result.contentGroupId = jsonValueToTsValueFns.string(value.contentGroupId);
    result.contentInfos = (_b = (_a = value.contentInfos) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    result.questionLayout = (_d = (_c = value.questionLayout) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_2(value))) !== null && _d !== void 0 ? _d : [];
    result.savedInteractions = (_f = (_e = value.savedInteractions) === null || _e === void 0 ? void 0 : _e.map((value) => decodeJson_3(value))) !== null && _f !== void 0 ? _f : [];
    if (value.ongoing !== undefined)
        result.state = { field: "ongoing", value: decodeJson_4(value.ongoing) };
    if (value.finished !== undefined)
        result.state = { field: "finished", value: decodeJson_5(value.finished) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.learningSessionId !== undefined) {
        const tsValue = value.learningSessionId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.authId !== undefined) {
        const tsValue = value.authId;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.contentGroupId !== undefined) {
        const tsValue = value.contentGroupId;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.contentInfos) {
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.questionLayout) {
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    for (const tsValue of value.savedInteractions) {
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    switch ((_a = value.state) === null || _a === void 0 ? void 0 : _a.field) {
        case "ongoing": {
            const tsValue = value.state.value;
            result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
            break;
        }
        case "finished": {
            const tsValue = value.state.value;
            result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    state: new Set([5, 6]),
};
const oneofFieldNamesMap = {
    state: new Map([
        [5, "ongoing"],
        [6, "finished"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.learningSessionId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.authId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.contentGroupId = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.contentInfos = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 7).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.questionLayout = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 8).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.savedInteractions = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.state;
        const oneofFieldNames = oneofFieldNamesMap.state;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [5](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined; },
            [6](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.state = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
