export const num2name = {
    0: "STATUS_UNSPECIFIED",
    1: "STATUS_SAVE",
    2: "STATUS_ACTIVE",
    3: "STATUS_END",
};
export const name2num = {
    STATUS_UNSPECIFIED: 0,
    STATUS_SAVE: 1,
    STATUS_ACTIVE: 2,
    STATUS_END: 3,
};
