// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../commerce/Product";
import { name2num, num2name, } from "./(GetProductResponse)/ErrorStatus";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../runtime/Long";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        product: undefined,
        errorStatus: "NO_ERROR",
        maxPurchasableCountReached: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.product !== undefined)
        result.product = encodeJson_1(value.product);
    if (value.errorStatus !== undefined)
        result.errorStatus = tsValueToJsonValueFns.enum(value.errorStatus);
    if (value.maxPurchasableCountReached !== undefined)
        result.maxPurchasableCountReached = tsValueToJsonValueFns.bool(value.maxPurchasableCountReached);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.product !== undefined)
        result.product = decodeJson_1(value.product);
    if (value.errorStatus !== undefined)
        result.errorStatus = jsonValueToTsValueFns.enum(value.errorStatus);
    if (value.maxPurchasableCountReached !== undefined)
        result.maxPurchasableCountReached = jsonValueToTsValueFns.bool(value.maxPurchasableCountReached);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.product !== undefined) {
        const tsValue = value.product;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.errorStatus !== undefined) {
        const tsValue = value.errorStatus;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.maxPurchasableCountReached !== undefined) {
        const tsValue = value.maxPurchasableCountReached;
        result.push([3, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.product = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.errorStatus = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.maxPurchasableCountReached = value;
    }
    return result;
}
