export const num2name = {
    0: "SOURCE_TYPE_UNSPECIFIED",
    1: "FULFILMENT",
    2: "AUTHORITY_CONFIG_SET",
    3: "BASE_AUTHORITY_CONFIG",
    4: "SUBSCRIPTION",
};
export const name2num = {
    SOURCE_TYPE_UNSPECIFIED: 0,
    FULFILMENT: 1,
    AUTHORITY_CONFIG_SET: 2,
    BASE_AUTHORITY_CONFIG: 3,
    SUBSCRIPTION: 4,
};
