// @ts-nocheck
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../google/protobuf/Empty";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../StringList";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./(ListResourceRequest)/ResourceSelection";
import { name2num, num2name, } from "../Domain";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(ListResourceRequest)/Order";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        size: "0",
        offset: "0",
        domain: "UNSPECIFIED_DOMAIN",
        orders: [],
        resourceFilter: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.size !== undefined)
        result.size = tsValueToJsonValueFns.uint64(value.size);
    if (value.offset !== undefined)
        result.offset = tsValueToJsonValueFns.uint64(value.offset);
    if (value.domain !== undefined)
        result.domain = tsValueToJsonValueFns.enum(value.domain);
    result.orders = value.orders.map(value => encodeJson_1(value));
    switch ((_a = value.resourceFilter) === null || _a === void 0 ? void 0 : _a.field) {
        case "everyResources": {
            result.everyResources = encodeJson_2(value.resourceFilter.value);
            break;
        }
        case "ids": {
            result.ids = encodeJson_3(value.resourceFilter.value);
            break;
        }
        case "selection": {
            result.selection = encodeJson_4(value.resourceFilter.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.size !== undefined)
        result.size = jsonValueToTsValueFns.uint64(value.size);
    if (value.offset !== undefined)
        result.offset = jsonValueToTsValueFns.uint64(value.offset);
    if (value.domain !== undefined)
        result.domain = jsonValueToTsValueFns.enum(value.domain);
    result.orders = (_b = (_a = value.orders) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    if (value.everyResources !== undefined)
        result.resourceFilter = { field: "everyResources", value: decodeJson_2(value.everyResources) };
    if (value.ids !== undefined)
        result.resourceFilter = { field: "ids", value: decodeJson_3(value.ids) };
    if (value.selection !== undefined)
        result.resourceFilter = { field: "selection", value: decodeJson_4(value.selection) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.size !== undefined) {
        const tsValue = value.size;
        result.push([1, tsValueToWireValueFns.uint64(tsValue)]);
    }
    if (value.offset !== undefined) {
        const tsValue = value.offset;
        result.push([2, tsValueToWireValueFns.uint64(tsValue)]);
    }
    if (value.domain !== undefined) {
        const tsValue = value.domain;
        result.push([5, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    for (const tsValue of value.orders) {
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    switch ((_a = value.resourceFilter) === null || _a === void 0 ? void 0 : _a.field) {
        case "everyResources": {
            const tsValue = value.resourceFilter.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "ids": {
            const tsValue = value.resourceFilter.value;
            result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
            break;
        }
        case "selection": {
            const tsValue = value.resourceFilter.value;
            result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    resourceFilter: new Set([3, 4, 7]),
};
const oneofFieldNamesMap = {
    resourceFilter: new Map([
        [3, "everyResources"],
        [4, "ids"],
        [7, "selection"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint64(wireValue);
        if (value === undefined)
            break field;
        result.size = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint64(wireValue);
        if (value === undefined)
            break field;
        result.offset = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.domain = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 6).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.orders = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.resourceFilter;
        const oneofFieldNames = oneofFieldNamesMap.resourceFilter;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [4](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined; },
            [7](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.resourceFilter = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
