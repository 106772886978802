import {createGrpcWebClientImpl, CreateGrpcWebClientImplConfig} from '@pbkit/grpc-web-client';

import {createServiceClient as createResourceService} from '@santa-web/gen/ssp/services/inside/content/ResourceService';
import {createServiceClient as createSubscriptionOrderService} from '@santa-web/gen/ssp/services/santa/commerce/subscription/v1/SubscriptionOrderService';
import {createServiceClient as createSubscriptionBoardService} from '@santa-web/gen/ssp/services/santa/commerce/subscription/v1/SubscriptionProductBoardService';
import {createServiceClient as createSubscriptionProductService} from '@santa-web/gen/ssp/services/santa/commerce/subscription/v1/SubscriptionProductService';
import {createServiceClient as createUserConfigService} from '@santa-web/gen/ssp/services/santa/config/user_config/v1/UserConfigService';
import {createServiceClient as createContentInteractionStateBehaviorService} from '@santa-web/gen/ssp/services/santa/contentlearning/content_interaction_state/v1/ContentInteractionStateBehaviorService';
import {createServiceClient as createSantaLearningCycleService} from '@santa-web/gen/ssp/services/santa/contentlearning/learning_cycle/v1/LearningCycleService';
import {createServiceClient as createSantaSelfLearningService} from '@santa-web/gen/ssp/services/santa/contentlearning/self_learning/v1/SelfLearningService';
import {createServiceClient as createSentenceStructureService} from '@santa-web/gen/ssp/services/santa/contentlearning/sentence_structure/v1/SentenceStructureService';
import {createServiceClient as createToeicFastCatService} from '@santa-web/gen/ssp/services/santa/contentlearning/toeic/cat/v1/ToeicFastCatService';
import {createServiceClient as createGtelpService} from '@santa-web/gen/ssp/services/santa/events/gtelp/v1/GtelpService';
import {createServiceClient as createABTestService} from '@santa-web/gen/ssp/services/toeic/app/ABTestService';
import {createServiceClient as createB2BStudentRegistrationService} from '@santa-web/gen/ssp/services/toeic/app/b2b/B2bStudentRegistrationService';
import {createServiceClient as createCISHighlightInfoService} from '@santa-web/gen/ssp/services/toeic/app/CISHighlightInfoService';
import {createServiceClient as createCISReviewService} from '@santa-web/gen/ssp/services/toeic/app/CISReviewService';
import {createServiceClient as createClientLoggerService} from '@santa-web/gen/ssp/services/toeic/app/ClientLoggerService';
import {createServiceClient as createFulfillmentService} from '@santa-web/gen/ssp/services/toeic/app/commerce/FulfillmentService';
import {createServiceClient as createToeicOrderService} from '@santa-web/gen/ssp/services/toeic/app/commerce/ToeicOrderService';
import {createServiceClient as createToeicProductService} from '@santa-web/gen/ssp/services/toeic/app/commerce/ToeicProductService';
import {createServiceClient as createCouponBoxService} from '@santa-web/gen/ssp/services/toeic/app/CouponBoxService';
import {createServiceClient as createExamDateService} from '@santa-web/gen/ssp/services/toeic/app/ExamDateService';
import {createServiceClient as createFeedbackService} from '@santa-web/gen/ssp/services/toeic/app/FeedbackService';
import {createServiceClient as createInboxNotificationService} from '@santa-web/gen/ssp/services/toeic/app/InboxNotificationService';
import {createServiceClient as createLearningCellReportService} from '@santa-web/gen/ssp/services/toeic/app/LearningCellReportService';
import {createServiceClient as createLearningCellService} from '@santa-web/gen/ssp/services/toeic/app/LearningCellService';
import {createServiceClient as createLearningCycleReportService} from '@santa-web/gen/ssp/services/toeic/app/LearningCycleReportService';
import {createServiceClient as createToeicLearningCycleService} from '@santa-web/gen/ssp/services/toeic/app/LearningCycleService';
import {createServiceClient as createMarketingService} from '@santa-web/gen/ssp/services/toeic/app/marketing/MarketingService';
import {createServiceClient as createMyNoteService} from '@santa-web/gen/ssp/services/toeic/app/MyNoteService';
import {createServiceClient as createPromotionService} from '@santa-web/gen/ssp/services/toeic/app/promotion/PromotionService';
import {createServiceClient as createToeicSelfLearningService} from '@santa-web/gen/ssp/services/toeic/app/SelfLearningService';
import {createServiceClient as createSystemConfigService} from '@santa-web/gen/ssp/services/toeic/app/SystemConfigService';
import {createServiceClient as createAuthorityService} from '@santa-web/gen/ssp/services/toeic/app/ToeicAuthorityService';
import {createServiceClient as createToeicResourceService} from '@santa-web/gen/ssp/services/toeic/app/ToeicResourceService';
import {createServiceClient as createVirtualExamService} from '@santa-web/gen/ssp/services/toeic/app/virtual_exam/VirtualExamService';
import {retry} from '@app/api/grpc-services/retry';
import {TokenManager} from '@app/atoms/core/token-manager';
import appConfig from '@app/config';

export type GrpcServices = ReturnType<typeof createGrpcServices>;

export const createGrpcServices = (tokenManager: TokenManager, config?: Partial<CreateGrpcWebClientImplConfig>) => {
  const grpcWebClient = retry({
    rpcClientImpl: createGrpcWebClientImpl({
      host: appConfig.domainServer,
      metadata: {
        'access-token': async () => (await tokenManager.getToken()).accessToken ?? '<unknown>',
        Authorization: async () => {
          const token = (await tokenManager.getToken()).accessToken;
          return token ? `Bearer ${token}` : undefined;
        },
      },
      ...config,
    }),
    retryCount: 1,
    refreshTokenFn: () => tokenManager.refreshToken(),
  });

  const grpcServiceConfig = {
    devtools: appConfig.env !== 'production' || appConfig.enableProdDevtools === 'true' || undefined,
  };

  return {
    ProductService: createToeicProductService(grpcWebClient, grpcServiceConfig),
    SubscriptionBoardService: createSubscriptionBoardService(grpcWebClient, grpcServiceConfig),
    SubscriptionProductService: createSubscriptionProductService(grpcWebClient, grpcServiceConfig),
    SubscriptionOrderService: createSubscriptionOrderService(grpcWebClient, grpcServiceConfig),
    ResourceService: createResourceService(grpcWebClient, grpcServiceConfig),
    OrderService: createToeicOrderService(grpcWebClient, grpcServiceConfig),
    FulfillmentService: createFulfillmentService(grpcWebClient, grpcServiceConfig),
    LearningCellService: createLearningCellService(grpcWebClient, grpcServiceConfig),
    /** @deprecated will be replaced by SantaLearningCycleService */
    ToeicLearningCycleService: createToeicLearningCycleService(grpcWebClient, grpcServiceConfig),
    /** @deprecated 추후 SantaSelfLearningService로 모두 대체될 예정 */
    ToeicSelfLearningService: createToeicSelfLearningService(grpcWebClient, grpcServiceConfig),
    MyNoteService: createMyNoteService(grpcWebClient, grpcServiceConfig),
    VirtualExamService: createVirtualExamService(grpcWebClient, grpcServiceConfig),
    CouponBoxService: createCouponBoxService(grpcWebClient, grpcServiceConfig),
    LearningCellReportService: createLearningCellReportService(grpcWebClient, grpcServiceConfig),
    PromotionService: createPromotionService(grpcWebClient, grpcServiceConfig),
    AuthorityService: createAuthorityService(grpcWebClient, grpcServiceConfig),
    B2bStudentRegistrationService: createB2BStudentRegistrationService(grpcWebClient, grpcServiceConfig),
    MarketingService: createMarketingService(grpcWebClient, grpcServiceConfig),
    ExamDateService: createExamDateService(grpcWebClient, grpcServiceConfig),
    LearningCycleReportService: createLearningCycleReportService(grpcWebClient, grpcServiceConfig),
    HighlightInfoService: createCISHighlightInfoService(grpcWebClient, grpcServiceConfig),
    ReviewService: createCISReviewService(grpcWebClient, grpcServiceConfig),
    InboxNotificationService: createInboxNotificationService(grpcWebClient, grpcServiceConfig),
    ClientLoggerService: createClientLoggerService(grpcWebClient, grpcServiceConfig),
    GtelpService: createGtelpService(grpcWebClient, grpcServiceConfig),
    ToeicResourceService: createToeicResourceService(grpcWebClient, grpcServiceConfig),
    ABTestService: createABTestService(grpcWebClient, grpcServiceConfig),
    SentenceStructureService: createSentenceStructureService(grpcWebClient, grpcServiceConfig),
    FeedbackService: createFeedbackService(grpcWebClient, grpcServiceConfig),
    UserConfigService: createUserConfigService(grpcWebClient, grpcServiceConfig),
    ContentInteractionStateBehaviorService: createContentInteractionStateBehaviorService(
      grpcWebClient,
      grpcServiceConfig
    ),
    SantaSelfLearningService: createSantaSelfLearningService(grpcWebClient, grpcServiceConfig),
    SantaLearningCycleService: createSantaLearningCycleService(grpcWebClient, grpcServiceConfig),
    ToeicFastCatService: createToeicFastCatService(grpcWebClient, grpcServiceConfig),
    SystemConfigService: createSystemConfigService(grpcWebClient, grpcServiceConfig),
  };
};
