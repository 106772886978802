// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        availableCouponsOnly: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.availableCouponsOnly !== undefined)
        result.availableCouponsOnly = tsValueToJsonValueFns.bool(value.availableCouponsOnly);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.availableCouponsOnly !== undefined)
        result.availableCouponsOnly = jsonValueToTsValueFns.bool(value.availableCouponsOnly);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.availableCouponsOnly !== undefined) {
        const tsValue = value.availableCouponsOnly;
        result.push([1, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.availableCouponsOnly = value;
    }
    return result;
}
