// @ts-nocheck
import { name2num, num2name, } from "./(GetReportResponse)/Error";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(GetReportResponse)/PartCorrectStat";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(GetReportResponse)/ElapsedTimeStat";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../TagProbabilityComparison";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./(GetReportResponse)/WeakTag";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../runtime/Long";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        error: "ERROR_UNSPECIFIED",
        targetScore: "0",
        lcScore: "0",
        rcScore: "0",
        partCorrectStat: undefined,
        partElapsedStat: undefined,
        pentagonalCorrectRates: [],
        lcWeakTags: [],
        rcWeakTags: [],
        unansweredCount: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.error !== undefined)
        result.error = tsValueToJsonValueFns.enum(value.error);
    if (value.targetScore !== undefined)
        result.targetScore = tsValueToJsonValueFns.int64(value.targetScore);
    if (value.lcScore !== undefined)
        result.lcScore = tsValueToJsonValueFns.int64(value.lcScore);
    if (value.rcScore !== undefined)
        result.rcScore = tsValueToJsonValueFns.int64(value.rcScore);
    if (value.partCorrectStat !== undefined)
        result.partCorrectStat = encodeJson_1(value.partCorrectStat);
    if (value.partElapsedStat !== undefined)
        result.partElapsedStat = encodeJson_2(value.partElapsedStat);
    result.pentagonalCorrectRates = value.pentagonalCorrectRates.map(value => encodeJson_3(value));
    result.lcWeakTags = value.lcWeakTags.map(value => encodeJson_4(value));
    result.rcWeakTags = value.rcWeakTags.map(value => encodeJson_4(value));
    if (value.unansweredCount !== undefined)
        result.unansweredCount = tsValueToJsonValueFns.int64(value.unansweredCount);
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f;
    const result = getDefaultValue();
    if (value.error !== undefined)
        result.error = jsonValueToTsValueFns.enum(value.error);
    if (value.targetScore !== undefined)
        result.targetScore = jsonValueToTsValueFns.int64(value.targetScore);
    if (value.lcScore !== undefined)
        result.lcScore = jsonValueToTsValueFns.int64(value.lcScore);
    if (value.rcScore !== undefined)
        result.rcScore = jsonValueToTsValueFns.int64(value.rcScore);
    if (value.partCorrectStat !== undefined)
        result.partCorrectStat = decodeJson_1(value.partCorrectStat);
    if (value.partElapsedStat !== undefined)
        result.partElapsedStat = decodeJson_2(value.partElapsedStat);
    result.pentagonalCorrectRates = (_b = (_a = value.pentagonalCorrectRates) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_3(value))) !== null && _b !== void 0 ? _b : [];
    result.lcWeakTags = (_d = (_c = value.lcWeakTags) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_4(value))) !== null && _d !== void 0 ? _d : [];
    result.rcWeakTags = (_f = (_e = value.rcWeakTags) === null || _e === void 0 ? void 0 : _e.map((value) => decodeJson_4(value))) !== null && _f !== void 0 ? _f : [];
    if (value.unansweredCount !== undefined)
        result.unansweredCount = jsonValueToTsValueFns.int64(value.unansweredCount);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.error !== undefined) {
        const tsValue = value.error;
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.targetScore !== undefined) {
        const tsValue = value.targetScore;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.lcScore !== undefined) {
        const tsValue = value.lcScore;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.rcScore !== undefined) {
        const tsValue = value.rcScore;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.partCorrectStat !== undefined) {
        const tsValue = value.partCorrectStat;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.partElapsedStat !== undefined) {
        const tsValue = value.partElapsedStat;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    for (const tsValue of value.pentagonalCorrectRates) {
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    for (const tsValue of value.lcWeakTags) {
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    for (const tsValue of value.rcWeakTags) {
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.unansweredCount !== undefined) {
        const tsValue = value.unansweredCount;
        result.push([10, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.error = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.targetScore = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.lcScore = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.rcScore = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.partCorrectStat = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.partElapsedStat = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 7).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.pentagonalCorrectRates = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 8).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.lcWeakTags = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 9).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.rcWeakTags = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.unansweredCount = value;
    }
    return result;
}
