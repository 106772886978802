export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "INVALID_ORGANIZATION_OR_CLASS_ID",
    2: "EMAIL_ALREADY_EXISTS",
    3: "INVALID_EMAIL_VERIFICATION_CODE",
    4: "CLASS_CAPACITY_EXCEEDED",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    INVALID_ORGANIZATION_OR_CLASS_ID: 1,
    EMAIL_ALREADY_EXISTS: 2,
    INVALID_EMAIL_VERIFICATION_CODE: 3,
    CLASS_CAPACITY_EXCEEDED: 4,
};
