export const num2name = {
    0: "CORRECTNESS_UNSPECIFIED",
    1: "INCORRECT_ONLY",
    2: "UNANSWERED_ONLY",
};
export const name2num = {
    CORRECTNESS_UNSPECIFIED: 0,
    INCORRECT_ONLY: 1,
    UNANSWERED_ONLY: 2,
};
