// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        createdAt: undefined,
        total: "0",
        lc: "0",
        rc: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.createdAt !== undefined)
        result.createdAt = encodeJson_1(value.createdAt);
    if (value.total !== undefined)
        result.total = tsValueToJsonValueFns.int64(value.total);
    if (value.lc !== undefined)
        result.lc = tsValueToJsonValueFns.int64(value.lc);
    if (value.rc !== undefined)
        result.rc = tsValueToJsonValueFns.int64(value.rc);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.createdAt !== undefined)
        result.createdAt = decodeJson_1(value.createdAt);
    if (value.total !== undefined)
        result.total = jsonValueToTsValueFns.int64(value.total);
    if (value.lc !== undefined)
        result.lc = jsonValueToTsValueFns.int64(value.lc);
    if (value.rc !== undefined)
        result.rc = jsonValueToTsValueFns.int64(value.rc);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.createdAt !== undefined) {
        const tsValue = value.createdAt;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.total !== undefined) {
        const tsValue = value.total;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.lc !== undefined) {
        const tsValue = value.lc;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.rc !== undefined) {
        const tsValue = value.rc;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createdAt = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.total = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.lc = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.rc = value;
    }
    return result;
}
