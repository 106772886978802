// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        bookmarked: false,
        memorized: false,
        completedAt: undefined,
        vocabulary: "",
        meaning: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.bookmarked !== undefined)
        result.bookmarked = tsValueToJsonValueFns.bool(value.bookmarked);
    if (value.memorized !== undefined)
        result.memorized = tsValueToJsonValueFns.bool(value.memorized);
    if (value.completedAt !== undefined)
        result.completedAt = encodeJson_1(value.completedAt);
    if (value.vocabulary !== undefined)
        result.vocabulary = tsValueToJsonValueFns.string(value.vocabulary);
    if (value.meaning !== undefined)
        result.meaning = tsValueToJsonValueFns.string(value.meaning);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.bookmarked !== undefined)
        result.bookmarked = jsonValueToTsValueFns.bool(value.bookmarked);
    if (value.memorized !== undefined)
        result.memorized = jsonValueToTsValueFns.bool(value.memorized);
    if (value.completedAt !== undefined)
        result.completedAt = decodeJson_1(value.completedAt);
    if (value.vocabulary !== undefined)
        result.vocabulary = jsonValueToTsValueFns.string(value.vocabulary);
    if (value.meaning !== undefined)
        result.meaning = jsonValueToTsValueFns.string(value.meaning);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.bookmarked !== undefined) {
        const tsValue = value.bookmarked;
        result.push([1, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.memorized !== undefined) {
        const tsValue = value.memorized;
        result.push([2, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.completedAt !== undefined) {
        const tsValue = value.completedAt;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.vocabulary !== undefined) {
        const tsValue = value.vocabulary;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.meaning !== undefined) {
        const tsValue = value.meaning;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.bookmarked = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.memorized = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.completedAt = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.vocabulary = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.meaning = value;
    }
    return result;
}
