export const num2name = {
    0: "NO_ERROR",
    1: "ERROR_INVALID_ARGUMENT",
    2: "ERROR_MY_NOTE_QUIZ_ALREADY_EXISTS",
    3: "ERROR_ABORTED_DUPLICATED_REQUEST",
    4: "ERROR_CONSUMABLE_CONTENT_NOT_FOUND",
};
export const name2num = {
    NO_ERROR: 0,
    ERROR_INVALID_ARGUMENT: 1,
    ERROR_MY_NOTE_QUIZ_ALREADY_EXISTS: 2,
    ERROR_ABORTED_DUPLICATED_REQUEST: 3,
    ERROR_CONSUMABLE_CONTENT_NOT_FOUND: 4,
};
