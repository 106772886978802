export const num2name = {
    0: "NO_ERROR",
    1: "ERROR_INVALID_MY_NOTE_SELECTION",
    2: "ERROR_HISTORY_NOT_FOUND",
};
export const name2num = {
    NO_ERROR: 0,
    ERROR_INVALID_MY_NOTE_SELECTION: 1,
    ERROR_HISTORY_NOT_FOUND: 2,
};
