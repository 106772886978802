export const num2name = {
    0: "FULFILLMENT_STATUS_UNSPECIFIED",
    2: "WITHDRAW_VOID",
    3: "FULFILLED",
    4: "WITHDRAW_CANCEL",
};
export const name2num = {
    FULFILLMENT_STATUS_UNSPECIFIED: 0,
    WITHDRAW_VOID: 2,
    FULFILLED: 3,
    WITHDRAW_CANCEL: 4,
};
