// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(CISReview)/CIS";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        contentInteractionStateId: "0",
        contentId: "",
        cis: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.contentInteractionStateId !== undefined)
        result.contentInteractionStateId = tsValueToJsonValueFns.int64(value.contentInteractionStateId);
    if (value.contentId !== undefined)
        result.contentId = tsValueToJsonValueFns.string(value.contentId);
    if (value.cis !== undefined)
        result.cis = encodeJson_1(value.cis);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.contentInteractionStateId !== undefined)
        result.contentInteractionStateId = jsonValueToTsValueFns.int64(value.contentInteractionStateId);
    if (value.contentId !== undefined)
        result.contentId = jsonValueToTsValueFns.string(value.contentId);
    if (value.cis !== undefined)
        result.cis = decodeJson_1(value.cis);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.contentInteractionStateId !== undefined) {
        const tsValue = value.contentInteractionStateId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.contentId !== undefined) {
        const tsValue = value.contentId;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.cis !== undefined) {
        const tsValue = value.cis;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.contentInteractionStateId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.contentId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.cis = value;
    }
    return result;
}
