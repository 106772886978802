export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_UNAUTHORIZED_BUT_NO_BOARD_CODE",
    2: "ERROR_SUBSCRIPTION_PRODUCT_BOARD_BY_BOARD_CODE_DOES_NOT_EXIST",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_UNAUTHORIZED_BUT_NO_BOARD_CODE: 1,
    ERROR_SUBSCRIPTION_PRODUCT_BOARD_BY_BOARD_CODE_DOES_NOT_EXIST: 2,
};
