export const num2name = {
    0: "ORDER_AVAILABILITY_UNSPECIFIED",
    1: "ORDER_AVAILABILITY_AVAILABLE",
    2: "ORDER_AVAILABILITY_UNAVAILABLE_NOT_EXPIRED_SUBSCRIPTION_EXIST",
};
export const name2num = {
    ORDER_AVAILABILITY_UNSPECIFIED: 0,
    ORDER_AVAILABILITY_AVAILABLE: 1,
    ORDER_AVAILABILITY_UNAVAILABLE_NOT_EXPIRED_SUBSCRIPTION_EXIST: 2,
};
