// @ts-nocheck
import { name2num, num2name, } from "./Domain";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as Long, } from "../../runtime/Long";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "",
        domain: "UNSPECIFIED_DOMAIN",
        mimeType: "",
        downloadUrl: "",
        displayName: "",
        createTime: undefined,
        createdBy: "",
        referredBy: [],
        uploadedStatus: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.string(value.id);
    if (value.domain !== undefined)
        result.domain = tsValueToJsonValueFns.enum(value.domain);
    if (value.mimeType !== undefined)
        result.mimeType = tsValueToJsonValueFns.string(value.mimeType);
    if (value.downloadUrl !== undefined)
        result.downloadUrl = tsValueToJsonValueFns.string(value.downloadUrl);
    if (value.displayName !== undefined)
        result.displayName = tsValueToJsonValueFns.string(value.displayName);
    if (value.createTime !== undefined)
        result.createTime = encodeJson_1(value.createTime);
    if (value.createdBy !== undefined)
        result.createdBy = tsValueToJsonValueFns.string(value.createdBy);
    result.referredBy = value.referredBy.map(value => tsValueToJsonValueFns.string(value));
    if (value.uploadedStatus !== undefined)
        result.uploadedStatus = tsValueToJsonValueFns.string(value.uploadedStatus);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.string(value.id);
    if (value.domain !== undefined)
        result.domain = jsonValueToTsValueFns.enum(value.domain);
    if (value.mimeType !== undefined)
        result.mimeType = jsonValueToTsValueFns.string(value.mimeType);
    if (value.downloadUrl !== undefined)
        result.downloadUrl = jsonValueToTsValueFns.string(value.downloadUrl);
    if (value.displayName !== undefined)
        result.displayName = jsonValueToTsValueFns.string(value.displayName);
    if (value.createTime !== undefined)
        result.createTime = decodeJson_1(value.createTime);
    if (value.createdBy !== undefined)
        result.createdBy = jsonValueToTsValueFns.string(value.createdBy);
    result.referredBy = (_b = (_a = value.referredBy) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.string(value))) !== null && _b !== void 0 ? _b : [];
    if (value.uploadedStatus !== undefined)
        result.uploadedStatus = jsonValueToTsValueFns.string(value.uploadedStatus);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.domain !== undefined) {
        const tsValue = value.domain;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.mimeType !== undefined) {
        const tsValue = value.mimeType;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.downloadUrl !== undefined) {
        const tsValue = value.downloadUrl;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.displayName !== undefined) {
        const tsValue = value.displayName;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.createTime !== undefined) {
        const tsValue = value.createTime;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.createdBy !== undefined) {
        const tsValue = value.createdBy;
        result.push([7, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.referredBy) {
        result.push([8, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.uploadedStatus !== undefined) {
        const tsValue = value.uploadedStatus;
        result.push([9, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.domain = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.mimeType = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.downloadUrl = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.displayName = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createTime = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.createdBy = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 8).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValueToTsValueFns.string(wireValue)).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.referredBy = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.uploadedStatus = value;
    }
    return result;
}
