export const num2name = {
    0: "SUBSCRIPTION_STATUS_UNSPECIFIED",
    1: "SUBSCRIPTION_STATUS_CREATED",
    2: "SUBSCRIPTION_STATUS_ACTIVE",
    3: "SUBSCRIPTION_STATUS_CANCELED",
    4: "SUBSCRIPTION_STATUS_ACCOUNT_HOLD",
    5: "SUBSCRIPTION_STATUS_EXPIRED",
};
export const name2num = {
    SUBSCRIPTION_STATUS_UNSPECIFIED: 0,
    SUBSCRIPTION_STATUS_CREATED: 1,
    SUBSCRIPTION_STATUS_ACTIVE: 2,
    SUBSCRIPTION_STATUS_CANCELED: 3,
    SUBSCRIPTION_STATUS_ACCOUNT_HOLD: 4,
    SUBSCRIPTION_STATUS_EXPIRED: 5,
};
