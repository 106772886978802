// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        estimatedCorrectnessRate: 0,
        rank: 0,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.estimatedCorrectnessRate !== undefined)
        result.estimatedCorrectnessRate = tsValueToJsonValueFns.float(value.estimatedCorrectnessRate);
    if (value.rank !== undefined)
        result.rank = tsValueToJsonValueFns.int32(value.rank);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.estimatedCorrectnessRate !== undefined)
        result.estimatedCorrectnessRate = jsonValueToTsValueFns.float(value.estimatedCorrectnessRate);
    if (value.rank !== undefined)
        result.rank = jsonValueToTsValueFns.int32(value.rank);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.estimatedCorrectnessRate !== undefined) {
        const tsValue = value.estimatedCorrectnessRate;
        result.push([1, tsValueToWireValueFns.float(tsValue)]);
    }
    if (value.rank !== undefined) {
        const tsValue = value.rank;
        result.push([2, tsValueToWireValueFns.int32(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.float(wireValue);
        if (value === undefined)
            break field;
        result.estimatedCorrectnessRate = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.rank = value;
    }
    return result;
}
