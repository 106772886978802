// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../TimeRange";
import { name2num, num2name, } from "./(Coupon)/Status";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as Long, } from "../../../../runtime/Long";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        title: "",
        code: "",
        registerableTimeRange: undefined,
        usableTimeRange: undefined,
        status: "STATUS_UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.title !== undefined)
        result.title = tsValueToJsonValueFns.string(value.title);
    if (value.code !== undefined)
        result.code = tsValueToJsonValueFns.string(value.code);
    if (value.registerableTimeRange !== undefined)
        result.registerableTimeRange = encodeJson_1(value.registerableTimeRange);
    if (value.usableTimeRange !== undefined)
        result.usableTimeRange = encodeJson_1(value.usableTimeRange);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.title !== undefined)
        result.title = jsonValueToTsValueFns.string(value.title);
    if (value.code !== undefined)
        result.code = jsonValueToTsValueFns.string(value.code);
    if (value.registerableTimeRange !== undefined)
        result.registerableTimeRange = decodeJson_1(value.registerableTimeRange);
    if (value.usableTimeRange !== undefined)
        result.usableTimeRange = decodeJson_1(value.usableTimeRange);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.title !== undefined) {
        const tsValue = value.title;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.code !== undefined) {
        const tsValue = value.code;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.registerableTimeRange !== undefined) {
        const tsValue = value.registerableTimeRange;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.usableTimeRange !== undefined) {
        const tsValue = value.usableTimeRange;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([6, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.title = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.code = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.registerableTimeRange = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.usableTimeRange = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    return result;
}
