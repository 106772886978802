// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        subscriptionOrderId: "0",
        paymentProviderId: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.subscriptionOrderId !== undefined)
        result.subscriptionOrderId = tsValueToJsonValueFns.uint64(value.subscriptionOrderId);
    if (value.paymentProviderId !== undefined)
        result.paymentProviderId = tsValueToJsonValueFns.uint64(value.paymentProviderId);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.subscriptionOrderId !== undefined)
        result.subscriptionOrderId = jsonValueToTsValueFns.uint64(value.subscriptionOrderId);
    if (value.paymentProviderId !== undefined)
        result.paymentProviderId = jsonValueToTsValueFns.uint64(value.paymentProviderId);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.subscriptionOrderId !== undefined) {
        const tsValue = value.subscriptionOrderId;
        result.push([1, tsValueToWireValueFns.uint64(tsValue)]);
    }
    if (value.paymentProviderId !== undefined) {
        const tsValue = value.paymentProviderId;
        result.push([2, tsValueToWireValueFns.uint64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint64(wireValue);
        if (value === undefined)
            break field;
        result.subscriptionOrderId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint64(wireValue);
        if (value === undefined)
            break field;
        result.paymentProviderId = value;
    }
    return result;
}
