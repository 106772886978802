// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./CorrectStat";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        part1: undefined,
        part2: undefined,
        part3: undefined,
        part4: undefined,
        part5: undefined,
        part6: undefined,
        part7: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.part1 !== undefined)
        result.part1 = encodeJson_1(value.part1);
    if (value.part2 !== undefined)
        result.part2 = encodeJson_1(value.part2);
    if (value.part3 !== undefined)
        result.part3 = encodeJson_1(value.part3);
    if (value.part4 !== undefined)
        result.part4 = encodeJson_1(value.part4);
    if (value.part5 !== undefined)
        result.part5 = encodeJson_1(value.part5);
    if (value.part6 !== undefined)
        result.part6 = encodeJson_1(value.part6);
    if (value.part7 !== undefined)
        result.part7 = encodeJson_1(value.part7);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.part1 !== undefined)
        result.part1 = decodeJson_1(value.part1);
    if (value.part2 !== undefined)
        result.part2 = decodeJson_1(value.part2);
    if (value.part3 !== undefined)
        result.part3 = decodeJson_1(value.part3);
    if (value.part4 !== undefined)
        result.part4 = decodeJson_1(value.part4);
    if (value.part5 !== undefined)
        result.part5 = decodeJson_1(value.part5);
    if (value.part6 !== undefined)
        result.part6 = decodeJson_1(value.part6);
    if (value.part7 !== undefined)
        result.part7 = decodeJson_1(value.part7);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.part1 !== undefined) {
        const tsValue = value.part1;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.part2 !== undefined) {
        const tsValue = value.part2;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.part3 !== undefined) {
        const tsValue = value.part3;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.part4 !== undefined) {
        const tsValue = value.part4;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.part5 !== undefined) {
        const tsValue = value.part5;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.part6 !== undefined) {
        const tsValue = value.part6;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.part7 !== undefined) {
        const tsValue = value.part7;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.part1 = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.part2 = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.part3 = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.part4 = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.part5 = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.part6 = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.part7 = value;
    }
    return result;
}
