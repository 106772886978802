export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_SUBSCRIPTION_PRODUCT_UNAVAILABLE",
    2: "ERROR_SUBSCRIPTION_PRODUCT_UNAVAILABLE_FOR_USER",
    3: "ERROR_SUBSCRIPTION_PRODUCT_NOT_FOUND",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_SUBSCRIPTION_PRODUCT_UNAVAILABLE: 1,
    ERROR_SUBSCRIPTION_PRODUCT_UNAVAILABLE_FOR_USER: 2,
    ERROR_SUBSCRIPTION_PRODUCT_NOT_FOUND: 3,
};
