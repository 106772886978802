export const num2name = {
    0: "SELF_LEARNING_HIERARCHY_TYPE_UNSPECIFIED",
    1: "SELF_LEARNING_HIERARCHY_TYPE_UNDEFINED",
    2: "SELF_LEARNING_HIERARCHY_TYPE_QUESTION_ROOT",
    3: "SELF_LEARNING_HIERARCHY_TYPE_QUESTION_1DEPTH",
    4: "SELF_LEARNING_HIERARCHY_TYPE_QUESTION_2DEPTH",
    5: "SELF_LEARNING_HIERARCHY_TYPE_LESSON_ROOT",
    6: "SELF_LEARNING_HIERARCHY_TYPE_LESSON_1DEPTH",
    7: "SELF_LEARNING_HIERARCHY_TYPE_LESSON_2DEPTH",
};
export const name2num = {
    SELF_LEARNING_HIERARCHY_TYPE_UNSPECIFIED: 0,
    SELF_LEARNING_HIERARCHY_TYPE_UNDEFINED: 1,
    SELF_LEARNING_HIERARCHY_TYPE_QUESTION_ROOT: 2,
    SELF_LEARNING_HIERARCHY_TYPE_QUESTION_1DEPTH: 3,
    SELF_LEARNING_HIERARCHY_TYPE_QUESTION_2DEPTH: 4,
    SELF_LEARNING_HIERARCHY_TYPE_LESSON_ROOT: 5,
    SELF_LEARNING_HIERARCHY_TYPE_LESSON_1DEPTH: 6,
    SELF_LEARNING_HIERARCHY_TYPE_LESSON_2DEPTH: 7,
};
