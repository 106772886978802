// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        score: "0",
        elapsedTimeMs: "0",
        contentCount: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.score !== undefined)
        result.score = tsValueToJsonValueFns.int64(value.score);
    if (value.elapsedTimeMs !== undefined)
        result.elapsedTimeMs = tsValueToJsonValueFns.int64(value.elapsedTimeMs);
    if (value.contentCount !== undefined)
        result.contentCount = tsValueToJsonValueFns.int64(value.contentCount);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.score !== undefined)
        result.score = jsonValueToTsValueFns.int64(value.score);
    if (value.elapsedTimeMs !== undefined)
        result.elapsedTimeMs = jsonValueToTsValueFns.int64(value.elapsedTimeMs);
    if (value.contentCount !== undefined)
        result.contentCount = jsonValueToTsValueFns.int64(value.contentCount);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.score !== undefined) {
        const tsValue = value.score;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.elapsedTimeMs !== undefined) {
        const tsValue = value.elapsedTimeMs;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.contentCount !== undefined) {
        const tsValue = value.contentCount;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.score = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.elapsedTimeMs = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.contentCount = value;
    }
    return result;
}
