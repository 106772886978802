export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_SELF_LEARNING_HIERARCHY_NOT_FOUND",
    2: "ERROR_INVALID_SELF_LEARNING_HIERARCHY",
    3: "ERROR_NO_AUTHORITY",
    4: "ERROR_DUPLICATED_REQUESTS",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_SELF_LEARNING_HIERARCHY_NOT_FOUND: 1,
    ERROR_INVALID_SELF_LEARNING_HIERARCHY: 2,
    ERROR_NO_AUTHORITY: 3,
    ERROR_DUPLICATED_REQUESTS: 4,
};
