// @ts-nocheck
import { name2num, num2name, } from "./(Selection)/CorrectnessSelection";
import { name2num as name2num_1, num2name as num2name_1, } from "./(Selection)/CellTypeSelection";
import { name2num as name2num_2, num2name as num2name_2, } from "../../Part";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, unpackFns, } from "../../../../runtime/wire/scalar";
import { default as Long, } from "../../../../runtime/Long";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        bookmarkedOnly: false,
        correctnessSelections: [],
        cellTypeSelections: [],
        partSelections: [],
        virtualExamIds: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.bookmarkedOnly !== undefined)
        result.bookmarkedOnly = tsValueToJsonValueFns.bool(value.bookmarkedOnly);
    result.correctnessSelections = value.correctnessSelections.map(value => tsValueToJsonValueFns.enum(value));
    result.cellTypeSelections = value.cellTypeSelections.map(value => tsValueToJsonValueFns.enum(value));
    result.partSelections = value.partSelections.map(value => tsValueToJsonValueFns.enum(value));
    result.virtualExamIds = value.virtualExamIds.map(value => tsValueToJsonValueFns.int64(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const result = getDefaultValue();
    if (value.bookmarkedOnly !== undefined)
        result.bookmarkedOnly = jsonValueToTsValueFns.bool(value.bookmarkedOnly);
    result.correctnessSelections = (_b = (_a = value.correctnessSelections) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _b !== void 0 ? _b : [];
    result.cellTypeSelections = (_d = (_c = value.cellTypeSelections) === null || _c === void 0 ? void 0 : _c.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _d !== void 0 ? _d : [];
    result.partSelections = (_f = (_e = value.partSelections) === null || _e === void 0 ? void 0 : _e.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _f !== void 0 ? _f : [];
    result.virtualExamIds = (_h = (_g = value.virtualExamIds) === null || _g === void 0 ? void 0 : _g.map((value) => jsonValueToTsValueFns.int64(value))) !== null && _h !== void 0 ? _h : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.bookmarkedOnly !== undefined) {
        const tsValue = value.bookmarkedOnly;
        result.push([1, tsValueToWireValueFns.bool(tsValue)]);
    }
    for (const tsValue of value.correctnessSelections) {
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    for (const tsValue of value.cellTypeSelections) {
        result.push([3, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    for (const tsValue of value.partSelections) {
        result.push([4, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
    }
    for (const tsValue of value.virtualExamIds) {
        result.push([5, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.bookmarkedOnly = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 2).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name[num]);
        if (!value.length)
            break collection;
        result.correctnessSelections = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_1[num]);
        if (!value.length)
            break collection;
        result.cellTypeSelections = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_2[num]);
        if (!value.length)
            break collection;
        result.partSelections = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int64(wireValues));
        if (!value.length)
            break collection;
        result.virtualExamIds = value;
    }
    return result;
}
