// @ts-nocheck
import { name2num, num2name, } from "../(SelfCardCategory)/Lesson";
import { name2num as name2num_1, num2name as num2name_1, } from "../(SelfCardCategory)/Vocabulary";
import { name2num as name2num_2, num2name as num2name_2, } from "../(SelfCardCategory)/VirtualExam";
import { name2num as name2num_3, num2name as num2name_3, } from "../(SelfCardCategory)/LessonV2";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        contentGroupList: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    switch ((_a = value.contentGroupList) === null || _a === void 0 ? void 0 : _a.field) {
        case "lesson": {
            result.lesson = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
        case "vocabulary": {
            result.vocabulary = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
        case "virtualExam": {
            result.virtualExam = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
        case "lessonV2": {
            result.lessonV2 = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.lesson !== undefined)
        result.contentGroupList = { field: "lesson", value: jsonValueToTsValueFns.enum(value.lesson) };
    if (value.vocabulary !== undefined)
        result.contentGroupList = { field: "vocabulary", value: jsonValueToTsValueFns.enum(value.vocabulary) };
    if (value.virtualExam !== undefined)
        result.contentGroupList = { field: "virtualExam", value: jsonValueToTsValueFns.enum(value.virtualExam) };
    if (value.lessonV2 !== undefined)
        result.contentGroupList = { field: "lessonV2", value: jsonValueToTsValueFns.enum(value.lessonV2) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    switch ((_a = value.contentGroupList) === null || _a === void 0 ? void 0 : _a.field) {
        case "lesson": {
            const tsValue = value.contentGroupList.value;
            result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
            break;
        }
        case "vocabulary": {
            const tsValue = value.contentGroupList.value;
            result.push([3, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
            break;
        }
        case "virtualExam": {
            const tsValue = value.contentGroupList.value;
            result.push([4, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
            break;
        }
        case "lessonV2": {
            const tsValue = value.contentGroupList.value;
            result.push([5, { type: WireType.Varint, value: new Long(name2num_3[tsValue]) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    contentGroupList: new Set([1, 3, 4, 5]),
};
const oneofFieldNamesMap = {
    contentGroupList: new Map([
        [1, "lesson"],
        [3, "vocabulary"],
        [4, "virtualExam"],
        [5, "lessonV2"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.contentGroupList;
        const oneofFieldNames = oneofFieldNamesMap.contentGroupList;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [1](wireValue) { return wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined; },
            [3](wireValue) { return wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined; },
            [4](wireValue) { return wireValue.type === WireType.Varint ? num2name_2[wireValue.value[0]] : undefined; },
            [5](wireValue) { return wireValue.type === WireType.Varint ? num2name_3[wireValue.value[0]] : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.contentGroupList = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
