// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../../../toeic/ToeicResourceImageSet";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./Tag";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        title: "",
        superordinateCategoryName: "",
        subText: "",
        description: "",
        imageSet: undefined,
        tags: [],
        isFree: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.title !== undefined)
        result.title = tsValueToJsonValueFns.string(value.title);
    if (value.superordinateCategoryName !== undefined)
        result.superordinateCategoryName = tsValueToJsonValueFns.string(value.superordinateCategoryName);
    if (value.subText !== undefined)
        result.subText = tsValueToJsonValueFns.string(value.subText);
    if (value.description !== undefined)
        result.description = tsValueToJsonValueFns.string(value.description);
    if (value.imageSet !== undefined)
        result.imageSet = encodeJson_1(value.imageSet);
    result.tags = value.tags.map(value => encodeJson_2(value));
    if (value.isFree !== undefined)
        result.isFree = tsValueToJsonValueFns.bool(value.isFree);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.title !== undefined)
        result.title = jsonValueToTsValueFns.string(value.title);
    if (value.superordinateCategoryName !== undefined)
        result.superordinateCategoryName = jsonValueToTsValueFns.string(value.superordinateCategoryName);
    if (value.subText !== undefined)
        result.subText = jsonValueToTsValueFns.string(value.subText);
    if (value.description !== undefined)
        result.description = jsonValueToTsValueFns.string(value.description);
    if (value.imageSet !== undefined)
        result.imageSet = decodeJson_1(value.imageSet);
    result.tags = (_b = (_a = value.tags) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_2(value))) !== null && _b !== void 0 ? _b : [];
    if (value.isFree !== undefined)
        result.isFree = jsonValueToTsValueFns.bool(value.isFree);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.title !== undefined) {
        const tsValue = value.title;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.superordinateCategoryName !== undefined) {
        const tsValue = value.superordinateCategoryName;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.subText !== undefined) {
        const tsValue = value.subText;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.description !== undefined) {
        const tsValue = value.description;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.imageSet !== undefined) {
        const tsValue = value.imageSet;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.tags) {
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.isFree !== undefined) {
        const tsValue = value.isFree;
        result.push([7, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.title = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.superordinateCategoryName = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.subText = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.description = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.imageSet = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 6).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.tags = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isFree = value;
    }
    return result;
}
