export const num2name = {
    0: "TYPE_UNSPECIFIED",
    1: "TYPE_FULL",
    2: "TYPE_HALF",
    3: "TYPE_MINI",
};
export const name2num = {
    TYPE_UNSPECIFIED: 0,
    TYPE_FULL: 1,
    TYPE_HALF: 2,
    TYPE_MINI: 3,
};
