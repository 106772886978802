// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./TagProbabilityWithTarget";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        listening: undefined,
        structure: undefined,
        grammar: undefined,
        reading: undefined,
        voca: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.listening !== undefined)
        result.listening = encodeJson_1(value.listening);
    if (value.structure !== undefined)
        result.structure = encodeJson_1(value.structure);
    if (value.grammar !== undefined)
        result.grammar = encodeJson_1(value.grammar);
    if (value.reading !== undefined)
        result.reading = encodeJson_1(value.reading);
    if (value.voca !== undefined)
        result.voca = encodeJson_1(value.voca);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.listening !== undefined)
        result.listening = decodeJson_1(value.listening);
    if (value.structure !== undefined)
        result.structure = decodeJson_1(value.structure);
    if (value.grammar !== undefined)
        result.grammar = decodeJson_1(value.grammar);
    if (value.reading !== undefined)
        result.reading = decodeJson_1(value.reading);
    if (value.voca !== undefined)
        result.voca = decodeJson_1(value.voca);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.listening !== undefined) {
        const tsValue = value.listening;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.structure !== undefined) {
        const tsValue = value.structure;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.grammar !== undefined) {
        const tsValue = value.grammar;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.reading !== undefined) {
        const tsValue = value.reading;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.voca !== undefined) {
        const tsValue = value.voca;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.listening = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.structure = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.grammar = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.reading = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.voca = value;
    }
    return result;
}
