// @ts-nocheck
import { name2num, num2name, } from "../../inside/Country";
import { name2num as name2num_1, num2name as num2name_1, } from "./(MainPopupBanner)/TargetUsersByPayment";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../ToeicResourceImageSet";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../ToeicResourceDesignAsset";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(MainPopupBanner)/DetailButton";
import { name2num as name2num_2, num2name as num2name_2, } from "./(MainPopupBanner)/Status";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "../../google/protobuf/Timestamp";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./(MainPopupBanner)/CTAButton";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        country: "UNSPECIFIED_COUNTRY",
        targetUsersByPayment: "TARGET_USER_BY_PAYMENT_UNSPECIFIED",
        title: "",
        mainThumbnailImage: undefined,
        detailPage: [],
        detailButton: undefined,
        status: "STATUS_UNSPECIFIED",
        registeredAt: undefined,
        updatedAt: undefined,
        startsAt: undefined,
        endsAt: undefined,
        ctaButton: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.country !== undefined)
        result.country = tsValueToJsonValueFns.enum(value.country);
    if (value.targetUsersByPayment !== undefined)
        result.targetUsersByPayment = tsValueToJsonValueFns.enum(value.targetUsersByPayment);
    if (value.title !== undefined)
        result.title = tsValueToJsonValueFns.string(value.title);
    if (value.mainThumbnailImage !== undefined)
        result.mainThumbnailImage = encodeJson_1(value.mainThumbnailImage);
    result.detailPage = value.detailPage.map(value => encodeJson_2(value));
    if (value.detailButton !== undefined)
        result.detailButton = encodeJson_3(value.detailButton);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    if (value.registeredAt !== undefined)
        result.registeredAt = encodeJson_4(value.registeredAt);
    if (value.updatedAt !== undefined)
        result.updatedAt = encodeJson_4(value.updatedAt);
    if (value.startsAt !== undefined)
        result.startsAt = encodeJson_4(value.startsAt);
    if (value.endsAt !== undefined)
        result.endsAt = encodeJson_4(value.endsAt);
    if (value.ctaButton !== undefined)
        result.ctaButton = encodeJson_5(value.ctaButton);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.country !== undefined)
        result.country = jsonValueToTsValueFns.enum(value.country);
    if (value.targetUsersByPayment !== undefined)
        result.targetUsersByPayment = jsonValueToTsValueFns.enum(value.targetUsersByPayment);
    if (value.title !== undefined)
        result.title = jsonValueToTsValueFns.string(value.title);
    if (value.mainThumbnailImage !== undefined)
        result.mainThumbnailImage = decodeJson_1(value.mainThumbnailImage);
    result.detailPage = (_b = (_a = value.detailPage) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_2(value))) !== null && _b !== void 0 ? _b : [];
    if (value.detailButton !== undefined)
        result.detailButton = decodeJson_3(value.detailButton);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    if (value.registeredAt !== undefined)
        result.registeredAt = decodeJson_4(value.registeredAt);
    if (value.updatedAt !== undefined)
        result.updatedAt = decodeJson_4(value.updatedAt);
    if (value.startsAt !== undefined)
        result.startsAt = decodeJson_4(value.startsAt);
    if (value.endsAt !== undefined)
        result.endsAt = decodeJson_4(value.endsAt);
    if (value.ctaButton !== undefined)
        result.ctaButton = decodeJson_5(value.ctaButton);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.country !== undefined) {
        const tsValue = value.country;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.targetUsersByPayment !== undefined) {
        const tsValue = value.targetUsersByPayment;
        result.push([3, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    if (value.title !== undefined) {
        const tsValue = value.title;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.mainThumbnailImage !== undefined) {
        const tsValue = value.mainThumbnailImage;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.detailPage) {
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.detailButton !== undefined) {
        const tsValue = value.detailButton;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([8, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
    }
    if (value.registeredAt !== undefined) {
        const tsValue = value.registeredAt;
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.updatedAt !== undefined) {
        const tsValue = value.updatedAt;
        result.push([10, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.startsAt !== undefined) {
        const tsValue = value.startsAt;
        result.push([11, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.endsAt !== undefined) {
        const tsValue = value.endsAt;
        result.push([12, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    if (value.ctaButton !== undefined) {
        const tsValue = value.ctaButton;
        result.push([13, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.country = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.targetUsersByPayment = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.title = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.mainThumbnailImage = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 6).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.detailPage = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.detailButton = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_2[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.registeredAt = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.updatedAt = value;
    }
    field: {
        const wireValue = wireFields.get(11);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.startsAt = value;
    }
    field: {
        const wireValue = wireFields.get(12);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.endsAt = value;
    }
    field: {
        const wireValue = wireFields.get(13);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.ctaButton = value;
    }
    return result;
}
