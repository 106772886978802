// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./CISHighlightInfo";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        contentInteractionStateId: "0",
        cisHighlightInfos: [],
        firstHighlightCreatedAt: undefined,
        cisTitle: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.contentInteractionStateId !== undefined)
        result.contentInteractionStateId = tsValueToJsonValueFns.int64(value.contentInteractionStateId);
    result.cisHighlightInfos = value.cisHighlightInfos.map(value => encodeJson_1(value));
    if (value.firstHighlightCreatedAt !== undefined)
        result.firstHighlightCreatedAt = encodeJson_2(value.firstHighlightCreatedAt);
    if (value.cisTitle !== undefined)
        result.cisTitle = tsValueToJsonValueFns.string(value.cisTitle);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.contentInteractionStateId !== undefined)
        result.contentInteractionStateId = jsonValueToTsValueFns.int64(value.contentInteractionStateId);
    result.cisHighlightInfos = (_b = (_a = value.cisHighlightInfos) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    if (value.firstHighlightCreatedAt !== undefined)
        result.firstHighlightCreatedAt = decodeJson_2(value.firstHighlightCreatedAt);
    if (value.cisTitle !== undefined)
        result.cisTitle = jsonValueToTsValueFns.string(value.cisTitle);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.contentInteractionStateId !== undefined) {
        const tsValue = value.contentInteractionStateId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    for (const tsValue of value.cisHighlightInfos) {
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.firstHighlightCreatedAt !== undefined) {
        const tsValue = value.firstHighlightCreatedAt;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.cisTitle !== undefined) {
        const tsValue = value.cisTitle;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.contentInteractionStateId = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 2).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.cisHighlightInfos = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.firstHighlightCreatedAt = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.cisTitle = value;
    }
    return result;
}
