// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../b2b/(B2bStudent)/PrivacyInfo";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../(User)/Agreement";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        organizationId: "0",
        classId: "0",
        email: "",
        emailVerificationCode: "",
        password: "",
        privacyInfo: undefined,
        agreement: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.organizationId !== undefined)
        result.organizationId = tsValueToJsonValueFns.int64(value.organizationId);
    if (value.classId !== undefined)
        result.classId = tsValueToJsonValueFns.int64(value.classId);
    if (value.email !== undefined)
        result.email = tsValueToJsonValueFns.string(value.email);
    if (value.emailVerificationCode !== undefined)
        result.emailVerificationCode = tsValueToJsonValueFns.string(value.emailVerificationCode);
    if (value.password !== undefined)
        result.password = tsValueToJsonValueFns.string(value.password);
    if (value.privacyInfo !== undefined)
        result.privacyInfo = encodeJson_1(value.privacyInfo);
    if (value.agreement !== undefined)
        result.agreement = encodeJson_2(value.agreement);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.organizationId !== undefined)
        result.organizationId = jsonValueToTsValueFns.int64(value.organizationId);
    if (value.classId !== undefined)
        result.classId = jsonValueToTsValueFns.int64(value.classId);
    if (value.email !== undefined)
        result.email = jsonValueToTsValueFns.string(value.email);
    if (value.emailVerificationCode !== undefined)
        result.emailVerificationCode = jsonValueToTsValueFns.string(value.emailVerificationCode);
    if (value.password !== undefined)
        result.password = jsonValueToTsValueFns.string(value.password);
    if (value.privacyInfo !== undefined)
        result.privacyInfo = decodeJson_1(value.privacyInfo);
    if (value.agreement !== undefined)
        result.agreement = decodeJson_2(value.agreement);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.organizationId !== undefined) {
        const tsValue = value.organizationId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.classId !== undefined) {
        const tsValue = value.classId;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.email !== undefined) {
        const tsValue = value.email;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.emailVerificationCode !== undefined) {
        const tsValue = value.emailVerificationCode;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.password !== undefined) {
        const tsValue = value.password;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.privacyInfo !== undefined) {
        const tsValue = value.privacyInfo;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.agreement !== undefined) {
        const tsValue = value.agreement;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.organizationId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.classId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.email = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.emailVerificationCode = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.password = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.privacyInfo = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.agreement = value;
    }
    return result;
}
