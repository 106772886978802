export const num2name = {
    0: "PRIORITY_UNSPECIFIED",
    1: "CREATE_TIME",
    2: "DISPLAY_NAME",
    3: "REFERENCE_COUNT",
};
export const name2num = {
    PRIORITY_UNSPECIFIED: 0,
    CREATE_TIME: 1,
    DISPLAY_NAME: 2,
    REFERENCE_COUNT: 3,
};
