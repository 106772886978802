export const num2name = {
    0: "STATUS_UNSPECIFIED",
    1: "STATUS_CREATED",
    2: "STATUS_SOLVING",
    3: "STATUS_FINISHED",
};
export const name2num = {
    STATUS_UNSPECIFIED: 0,
    STATUS_CREATED: 1,
    STATUS_SOLVING: 2,
    STATUS_FINISHED: 3,
};
