// @ts-nocheck
import { name2num, num2name, } from "./Domain";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./LanguageTag";
import { name2num as name2num_1, num2name as num2name_1, } from "./Country";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../google/protobuf/Timestamp";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(ContentGroup)/Tag";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, unpackFns, } from "../../runtime/wire/scalar";
import { default as Long, } from "../../runtime/Long";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "",
        displayName: "",
        domain: "UNSPECIFIED_DOMAIN",
        languageTag: undefined,
        regions: [],
        contentIds: [],
        createTime: undefined,
        updatedTime: undefined,
        valueName: "",
        tags: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.string(value.id);
    if (value.displayName !== undefined)
        result.displayName = tsValueToJsonValueFns.string(value.displayName);
    if (value.domain !== undefined)
        result.domain = tsValueToJsonValueFns.enum(value.domain);
    if (value.languageTag !== undefined)
        result.languageTag = encodeJson_1(value.languageTag);
    result.regions = value.regions.map(value => tsValueToJsonValueFns.enum(value));
    result.contentIds = value.contentIds.map(value => tsValueToJsonValueFns.string(value));
    if (value.createTime !== undefined)
        result.createTime = encodeJson_2(value.createTime);
    if (value.updatedTime !== undefined)
        result.updatedTime = encodeJson_2(value.updatedTime);
    if (value.valueName !== undefined)
        result.valueName = tsValueToJsonValueFns.string(value.valueName);
    result.tags = value.tags.map(value => encodeJson_3(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f;
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.string(value.id);
    if (value.displayName !== undefined)
        result.displayName = jsonValueToTsValueFns.string(value.displayName);
    if (value.domain !== undefined)
        result.domain = jsonValueToTsValueFns.enum(value.domain);
    if (value.languageTag !== undefined)
        result.languageTag = decodeJson_1(value.languageTag);
    result.regions = (_b = (_a = value.regions) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _b !== void 0 ? _b : [];
    result.contentIds = (_d = (_c = value.contentIds) === null || _c === void 0 ? void 0 : _c.map((value) => jsonValueToTsValueFns.string(value))) !== null && _d !== void 0 ? _d : [];
    if (value.createTime !== undefined)
        result.createTime = decodeJson_2(value.createTime);
    if (value.updatedTime !== undefined)
        result.updatedTime = decodeJson_2(value.updatedTime);
    if (value.valueName !== undefined)
        result.valueName = jsonValueToTsValueFns.string(value.valueName);
    result.tags = (_f = (_e = value.tags) === null || _e === void 0 ? void 0 : _e.map((value) => decodeJson_3(value))) !== null && _f !== void 0 ? _f : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.displayName !== undefined) {
        const tsValue = value.displayName;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.domain !== undefined) {
        const tsValue = value.domain;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.languageTag !== undefined) {
        const tsValue = value.languageTag;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.regions) {
        result.push([5, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    for (const tsValue of value.contentIds) {
        result.push([6, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.createTime !== undefined) {
        const tsValue = value.createTime;
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.updatedTime !== undefined) {
        const tsValue = value.updatedTime;
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.valueName !== undefined) {
        const tsValue = value.valueName;
        result.push([9, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.tags) {
        result.push([10, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.displayName = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.domain = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.languageTag = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_1[num]);
        if (!value.length)
            break collection;
        result.regions = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 6).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValueToTsValueFns.string(wireValue)).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.contentIds = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createTime = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.updatedTime = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.valueName = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 10).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.tags = value;
    }
    return result;
}
