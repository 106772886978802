// @ts-nocheck
import { name2num, num2name, } from "./NextTaskSection";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, unpackFns, } from "../../../../../../../runtime/wire/scalar";
import { default as Long, } from "../../../../../../../runtime/Long";
import { default as deserialize, } from "../../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        idx: "0",
        contentIdx: "0",
        questionIndexes: [],
        contentId: "",
        elementId: [],
        isLcLast: false,
        progress: "0",
        nextTaskSection: "LC",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.idx !== undefined)
        result.idx = tsValueToJsonValueFns.int64(value.idx);
    if (value.contentIdx !== undefined)
        result.contentIdx = tsValueToJsonValueFns.int64(value.contentIdx);
    result.questionIndexes = value.questionIndexes.map(value => tsValueToJsonValueFns.int64(value));
    if (value.contentId !== undefined)
        result.contentId = tsValueToJsonValueFns.string(value.contentId);
    result.elementId = value.elementId.map(value => tsValueToJsonValueFns.string(value));
    if (value.isLcLast !== undefined)
        result.isLcLast = tsValueToJsonValueFns.bool(value.isLcLast);
    if (value.progress !== undefined)
        result.progress = tsValueToJsonValueFns.int64(value.progress);
    if (value.nextTaskSection !== undefined)
        result.nextTaskSection = tsValueToJsonValueFns.enum(value.nextTaskSection);
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.idx !== undefined)
        result.idx = jsonValueToTsValueFns.int64(value.idx);
    if (value.contentIdx !== undefined)
        result.contentIdx = jsonValueToTsValueFns.int64(value.contentIdx);
    result.questionIndexes = (_b = (_a = value.questionIndexes) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.int64(value))) !== null && _b !== void 0 ? _b : [];
    if (value.contentId !== undefined)
        result.contentId = jsonValueToTsValueFns.string(value.contentId);
    result.elementId = (_d = (_c = value.elementId) === null || _c === void 0 ? void 0 : _c.map((value) => jsonValueToTsValueFns.string(value))) !== null && _d !== void 0 ? _d : [];
    if (value.isLcLast !== undefined)
        result.isLcLast = jsonValueToTsValueFns.bool(value.isLcLast);
    if (value.progress !== undefined)
        result.progress = jsonValueToTsValueFns.int64(value.progress);
    if (value.nextTaskSection !== undefined)
        result.nextTaskSection = jsonValueToTsValueFns.enum(value.nextTaskSection);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.idx !== undefined) {
        const tsValue = value.idx;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.contentIdx !== undefined) {
        const tsValue = value.contentIdx;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    for (const tsValue of value.questionIndexes) {
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.contentId !== undefined) {
        const tsValue = value.contentId;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.elementId) {
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.isLcLast !== undefined) {
        const tsValue = value.isLcLast;
        result.push([6, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.progress !== undefined) {
        const tsValue = value.progress;
        result.push([7, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.nextTaskSection !== undefined) {
        const tsValue = value.nextTaskSection;
        result.push([8, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.idx = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.contentIdx = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int64(wireValues));
        if (!value.length)
            break collection;
        result.questionIndexes = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.contentId = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValueToTsValueFns.string(wireValue)).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.elementId = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isLcLast = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.progress = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.nextTaskSection = value;
    }
    return result;
}
