// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../google/protobuf/Int64Value";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        name: "",
        phoneNumber: "",
        officialScore: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.name !== undefined)
        result.name = tsValueToJsonValueFns.string(value.name);
    if (value.phoneNumber !== undefined)
        result.phoneNumber = tsValueToJsonValueFns.string(value.phoneNumber);
    if (value.officialScore !== undefined)
        result.officialScore = value.officialScore;
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.name !== undefined)
        result.name = jsonValueToTsValueFns.string(value.name);
    if (value.phoneNumber !== undefined)
        result.phoneNumber = jsonValueToTsValueFns.string(value.phoneNumber);
    if (value.officialScore !== undefined)
        result.officialScore = value.officialScore;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.name !== undefined) {
        const tsValue = value.name;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.phoneNumber !== undefined) {
        const tsValue = value.phoneNumber;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.officialScore !== undefined) {
        const tsValue = value.officialScore;
        result.push([3, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.name = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.phoneNumber = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.officialScore = value;
    }
    return result;
}
