export const num2name = {
    0: "TYPE_UNSPECIFIED",
    1: "LESSON",
    2: "QUESTION",
    3: "VOCABULARY",
    4: "MOCK_EXAM",
    5: "DIAGNOSIS",
};
export const name2num = {
    TYPE_UNSPECIFIED: 0,
    LESSON: 1,
    QUESTION: 2,
    VOCABULARY: 3,
    MOCK_EXAM: 4,
    DIAGNOSIS: 5,
};
