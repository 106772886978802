// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, unpackFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        bookmarked: false,
        correctness: [],
        completedAt: undefined,
        description: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.bookmarked !== undefined)
        result.bookmarked = tsValueToJsonValueFns.bool(value.bookmarked);
    result.correctness = value.correctness.map(value => tsValueToJsonValueFns.bool(value));
    if (value.completedAt !== undefined)
        result.completedAt = encodeJson_1(value.completedAt);
    switch ((_a = value.description) === null || _a === void 0 ? void 0 : _a.field) {
        case "question": {
            result.question = tsValueToJsonValueFns.string(value.description.value);
            break;
        }
        case "passage": {
            result.passage = tsValueToJsonValueFns.string(value.description.value);
            break;
        }
        case "imageUrl": {
            result.imageUrl = tsValueToJsonValueFns.string(value.description.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.bookmarked !== undefined)
        result.bookmarked = jsonValueToTsValueFns.bool(value.bookmarked);
    result.correctness = (_b = (_a = value.correctness) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.bool(value))) !== null && _b !== void 0 ? _b : [];
    if (value.completedAt !== undefined)
        result.completedAt = decodeJson_1(value.completedAt);
    if (value.question !== undefined)
        result.description = { field: "question", value: jsonValueToTsValueFns.string(value.question) };
    if (value.passage !== undefined)
        result.description = { field: "passage", value: jsonValueToTsValueFns.string(value.passage) };
    if (value.imageUrl !== undefined)
        result.description = { field: "imageUrl", value: jsonValueToTsValueFns.string(value.imageUrl) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.bookmarked !== undefined) {
        const tsValue = value.bookmarked;
        result.push([1, tsValueToWireValueFns.bool(tsValue)]);
    }
    for (const tsValue of value.correctness) {
        result.push([2, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.completedAt !== undefined) {
        const tsValue = value.completedAt;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    switch ((_a = value.description) === null || _a === void 0 ? void 0 : _a.field) {
        case "question": {
            const tsValue = value.description.value;
            result.push([4, tsValueToWireValueFns.string(tsValue)]);
            break;
        }
        case "passage": {
            const tsValue = value.description.value;
            result.push([5, tsValueToWireValueFns.string(tsValue)]);
            break;
        }
        case "imageUrl": {
            const tsValue = value.description.value;
            result.push([6, tsValueToWireValueFns.string(tsValue)]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    description: new Set([4, 5, 6]),
};
const oneofFieldNamesMap = {
    description: new Map([
        [4, "question"],
        [5, "passage"],
        [6, "imageUrl"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.bookmarked = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 2).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.bool(wireValues));
        if (!value.length)
            break collection;
        result.correctness = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.completedAt = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.description;
        const oneofFieldNames = oneofFieldNamesMap.description;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [4](wireValue) { return wireValueToTsValueFns.string(wireValue); },
            [5](wireValue) { return wireValueToTsValueFns.string(wireValue); },
            [6](wireValue) { return wireValueToTsValueFns.string(wireValue); },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.description = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
