// @ts-nocheck
import { name2num, num2name, } from "./SelfLearningHierarchyType";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../Pageable";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../../runtime/Long";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
import { unpackFns, } from "../../../../../runtime/wire/scalar";
export function getDefaultValue() {
    return {
        typeSelections: [],
        pageable: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.typeSelections = value.typeSelections.map(value => tsValueToJsonValueFns.enum(value));
    if (value.pageable !== undefined)
        result.pageable = encodeJson_1(value.pageable);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    result.typeSelections = (_b = (_a = value.typeSelections) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _b !== void 0 ? _b : [];
    if (value.pageable !== undefined)
        result.pageable = decodeJson_1(value.pageable);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.typeSelections) {
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.pageable !== undefined) {
        const tsValue = value.pageable;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name[num]);
        if (!value.length)
            break collection;
        result.typeSelections = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.pageable = value;
    }
    return result;
}
