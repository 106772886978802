// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../inside/ContentGroup";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../ToeicResourceSingle";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        contentGroup: undefined,
        audioResource: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.contentGroup !== undefined)
        result.contentGroup = encodeJson_1(value.contentGroup);
    if (value.audioResource !== undefined)
        result.audioResource = encodeJson_2(value.audioResource);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.contentGroup !== undefined)
        result.contentGroup = decodeJson_1(value.contentGroup);
    if (value.audioResource !== undefined)
        result.audioResource = decodeJson_2(value.audioResource);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.contentGroup !== undefined) {
        const tsValue = value.contentGroup;
        result.push([9, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.audioResource !== undefined) {
        const tsValue = value.audioResource;
        result.push([10, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.contentGroup = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.audioResource = value;
    }
    return result;
}
