// @ts-nocheck
import { name2num, num2name, } from "./SelfLearningHierarchyType";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(SelfLearningHierarchyInfo)/LearningStatus";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(SelfLearningHierarchyInfo)/DisplayConfig";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { default as Long, } from "../../../../../runtime/Long";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        selfLearningHierarchyId: "0",
        type: "SELF_LEARNING_HIERARCHY_TYPE_UNSPECIFIED",
        learningStatus: undefined,
        displayConfig: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.selfLearningHierarchyId !== undefined)
        result.selfLearningHierarchyId = tsValueToJsonValueFns.int64(value.selfLearningHierarchyId);
    if (value.type !== undefined)
        result.type = tsValueToJsonValueFns.enum(value.type);
    if (value.learningStatus !== undefined)
        result.learningStatus = encodeJson_1(value.learningStatus);
    if (value.displayConfig !== undefined)
        result.displayConfig = encodeJson_2(value.displayConfig);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.selfLearningHierarchyId !== undefined)
        result.selfLearningHierarchyId = jsonValueToTsValueFns.int64(value.selfLearningHierarchyId);
    if (value.type !== undefined)
        result.type = jsonValueToTsValueFns.enum(value.type);
    if (value.learningStatus !== undefined)
        result.learningStatus = decodeJson_1(value.learningStatus);
    if (value.displayConfig !== undefined)
        result.displayConfig = decodeJson_2(value.displayConfig);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.selfLearningHierarchyId !== undefined) {
        const tsValue = value.selfLearningHierarchyId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.type !== undefined) {
        const tsValue = value.type;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.learningStatus !== undefined) {
        const tsValue = value.learningStatus;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.displayConfig !== undefined) {
        const tsValue = value.displayConfig;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.selfLearningHierarchyId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.type = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.learningStatus = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.displayConfig = value;
    }
    return result;
}
