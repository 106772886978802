export const num2name = {
    0: "AUTHORITY_STATUS_UNSPECIFIED",
    1: "ACTIVE",
    2: "REVOKED",
};
export const name2num = {
    AUTHORITY_STATUS_UNSPECIFIED: 0,
    ACTIVE: 1,
    REVOKED: 2,
};
