export const num2name = {
    0: "STATE_UNSPECIFIED",
    1: "READY",
    2: "ONGOING",
    3: "DONE",
};
export const name2num = {
    STATE_UNSPECIFIED: 0,
    READY: 1,
    ONGOING: 2,
    DONE: 3,
};
