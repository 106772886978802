export const num2name = {
    0: "UNSPECIFIED",
    1: "CONTENT_NOT_FOUND",
    2: "CONTENT_VERSION_NOT_FOUND",
};
export const name2num = {
    UNSPECIFIED: 0,
    CONTENT_NOT_FOUND: 1,
    CONTENT_VERSION_NOT_FOUND: 2,
};
