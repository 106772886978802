// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./EstimatedScore";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./LevelInformation";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./TagProbabilityWithTarget";
import { name2num, num2name, } from "./(User)/(EstimatedInfo)/UserSeg";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as Long, } from "../../runtime/Long";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        currentScore: undefined,
        targetScore: "0",
        predictProbability: 0,
        pentagon: undefined,
        chapterInformation: [],
        dDay: "0",
        remainCycle: "0",
        userSeg: "USER_SAG_UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.currentScore !== undefined)
        result.currentScore = encodeJson_1(value.currentScore);
    if (value.targetScore !== undefined)
        result.targetScore = tsValueToJsonValueFns.int64(value.targetScore);
    if (value.predictProbability !== undefined)
        result.predictProbability = tsValueToJsonValueFns.float(value.predictProbability);
    if (value.pentagon !== undefined)
        result.pentagon = encodeJson_2(value.pentagon);
    result.chapterInformation = value.chapterInformation.map(value => encodeJson_3(value));
    if (value.dDay !== undefined)
        result.dDay = tsValueToJsonValueFns.int64(value.dDay);
    if (value.remainCycle !== undefined)
        result.remainCycle = tsValueToJsonValueFns.int64(value.remainCycle);
    if (value.userSeg !== undefined)
        result.userSeg = tsValueToJsonValueFns.enum(value.userSeg);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.currentScore !== undefined)
        result.currentScore = decodeJson_1(value.currentScore);
    if (value.targetScore !== undefined)
        result.targetScore = jsonValueToTsValueFns.int64(value.targetScore);
    if (value.predictProbability !== undefined)
        result.predictProbability = jsonValueToTsValueFns.float(value.predictProbability);
    if (value.pentagon !== undefined)
        result.pentagon = decodeJson_2(value.pentagon);
    result.chapterInformation = (_b = (_a = value.chapterInformation) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_3(value))) !== null && _b !== void 0 ? _b : [];
    if (value.dDay !== undefined)
        result.dDay = jsonValueToTsValueFns.int64(value.dDay);
    if (value.remainCycle !== undefined)
        result.remainCycle = jsonValueToTsValueFns.int64(value.remainCycle);
    if (value.userSeg !== undefined)
        result.userSeg = jsonValueToTsValueFns.enum(value.userSeg);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.currentScore !== undefined) {
        const tsValue = value.currentScore;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.targetScore !== undefined) {
        const tsValue = value.targetScore;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.predictProbability !== undefined) {
        const tsValue = value.predictProbability;
        result.push([3, tsValueToWireValueFns.float(tsValue)]);
    }
    if (value.pentagon !== undefined) {
        const tsValue = value.pentagon;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    for (const tsValue of value.chapterInformation) {
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.dDay !== undefined) {
        const tsValue = value.dDay;
        result.push([6, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.remainCycle !== undefined) {
        const tsValue = value.remainCycle;
        result.push([7, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.userSeg !== undefined) {
        const tsValue = value.userSeg;
        result.push([8, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.currentScore = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.targetScore = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.float(wireValue);
        if (value === undefined)
            break field;
        result.predictProbability = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.pentagon = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.chapterInformation = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.dDay = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.remainCycle = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.userSeg = value;
    }
    return result;
}
