// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../google/protobuf/Int64Value";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        cursorLearningCycleId: undefined,
        size: 0,
        createNewCycleIfLastOneIsCompleted: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.cursorLearningCycleId !== undefined)
        result.cursorLearningCycleId = value.cursorLearningCycleId;
    if (value.size !== undefined)
        result.size = tsValueToJsonValueFns.int32(value.size);
    if (value.createNewCycleIfLastOneIsCompleted !== undefined)
        result.createNewCycleIfLastOneIsCompleted = tsValueToJsonValueFns.bool(value.createNewCycleIfLastOneIsCompleted);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.cursorLearningCycleId !== undefined)
        result.cursorLearningCycleId = value.cursorLearningCycleId;
    if (value.size !== undefined)
        result.size = jsonValueToTsValueFns.int32(value.size);
    if (value.createNewCycleIfLastOneIsCompleted !== undefined)
        result.createNewCycleIfLastOneIsCompleted = jsonValueToTsValueFns.bool(value.createNewCycleIfLastOneIsCompleted);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.cursorLearningCycleId !== undefined) {
        const tsValue = value.cursorLearningCycleId;
        result.push([1, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.size !== undefined) {
        const tsValue = value.size;
        result.push([2, tsValueToWireValueFns.int32(tsValue)]);
    }
    if (value.createNewCycleIfLastOneIsCompleted !== undefined) {
        const tsValue = value.createNewCycleIfLastOneIsCompleted;
        result.push([3, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.cursorLearningCycleId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.size = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.createNewCycleIfLastOneIsCompleted = value;
    }
    return result;
}
