export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_SUBSCRIPTION_ORDER_NOT_FOUND",
    2: "ERROR_SUBSCRIPTION_ORDER_SUBMIT_TRANSACTION_NOT_AVAILABLE",
    3: "ERROR_INVALID_TRANSACTION",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_SUBSCRIPTION_ORDER_NOT_FOUND: 1,
    ERROR_SUBSCRIPTION_ORDER_SUBMIT_TRANSACTION_NOT_AVAILABLE: 2,
    ERROR_INVALID_TRANSACTION: 3,
};
