// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        word: "",
        isSubjectSpan: false,
        isMainVerbSpan: false,
        isAnswerSpan: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.word !== undefined)
        result.word = tsValueToJsonValueFns.string(value.word);
    if (value.isSubjectSpan !== undefined)
        result.isSubjectSpan = tsValueToJsonValueFns.bool(value.isSubjectSpan);
    if (value.isMainVerbSpan !== undefined)
        result.isMainVerbSpan = tsValueToJsonValueFns.bool(value.isMainVerbSpan);
    if (value.isAnswerSpan !== undefined)
        result.isAnswerSpan = tsValueToJsonValueFns.bool(value.isAnswerSpan);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.word !== undefined)
        result.word = jsonValueToTsValueFns.string(value.word);
    if (value.isSubjectSpan !== undefined)
        result.isSubjectSpan = jsonValueToTsValueFns.bool(value.isSubjectSpan);
    if (value.isMainVerbSpan !== undefined)
        result.isMainVerbSpan = jsonValueToTsValueFns.bool(value.isMainVerbSpan);
    if (value.isAnswerSpan !== undefined)
        result.isAnswerSpan = jsonValueToTsValueFns.bool(value.isAnswerSpan);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.word !== undefined) {
        const tsValue = value.word;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.isSubjectSpan !== undefined) {
        const tsValue = value.isSubjectSpan;
        result.push([2, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.isMainVerbSpan !== undefined) {
        const tsValue = value.isMainVerbSpan;
        result.push([3, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.isAnswerSpan !== undefined) {
        const tsValue = value.isAnswerSpan;
        result.push([4, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.word = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isSubjectSpan = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isMainVerbSpan = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isAnswerSpan = value;
    }
    return result;
}
