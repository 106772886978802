// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(DisplayConfig)/Undefined";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(DisplayConfig)/QuestionRoot";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(DisplayConfig)/Question1Depth";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./(DisplayConfig)/Question2Depth";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./(DisplayConfig)/LessonRoot";
import { encodeJson as encodeJson_6, decodeJson as decodeJson_6, encodeBinary as encodeBinary_6, decodeBinary as decodeBinary_6, } from "./(DisplayConfig)/Lesson1Depth";
import { encodeJson as encodeJson_7, decodeJson as decodeJson_7, encodeBinary as encodeBinary_7, decodeBinary as decodeBinary_7, } from "./(DisplayConfig)/Lesson2Depth";
import { WireType, } from "../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        value: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    switch ((_a = value.value) === null || _a === void 0 ? void 0 : _a.field) {
        case "undefined": {
            result.undefined = encodeJson_1(value.value.value);
            break;
        }
        case "questionRoot": {
            result.questionRoot = encodeJson_2(value.value.value);
            break;
        }
        case "question1depth": {
            result.question1depth = encodeJson_3(value.value.value);
            break;
        }
        case "question2depth": {
            result.question2depth = encodeJson_4(value.value.value);
            break;
        }
        case "lessonRoot": {
            result.lessonRoot = encodeJson_5(value.value.value);
            break;
        }
        case "lesson1depth": {
            result.lesson1depth = encodeJson_6(value.value.value);
            break;
        }
        case "lesson2depth": {
            result.lesson2depth = encodeJson_7(value.value.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.undefined !== undefined)
        result.value = { field: "undefined", value: decodeJson_1(value.undefined) };
    if (value.questionRoot !== undefined)
        result.value = { field: "questionRoot", value: decodeJson_2(value.questionRoot) };
    if (value.question1depth !== undefined)
        result.value = { field: "question1depth", value: decodeJson_3(value.question1depth) };
    if (value.question2depth !== undefined)
        result.value = { field: "question2depth", value: decodeJson_4(value.question2depth) };
    if (value.lessonRoot !== undefined)
        result.value = { field: "lessonRoot", value: decodeJson_5(value.lessonRoot) };
    if (value.lesson1depth !== undefined)
        result.value = { field: "lesson1depth", value: decodeJson_6(value.lesson1depth) };
    if (value.lesson2depth !== undefined)
        result.value = { field: "lesson2depth", value: decodeJson_7(value.lesson2depth) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    switch ((_a = value.value) === null || _a === void 0 ? void 0 : _a.field) {
        case "undefined": {
            const tsValue = value.value.value;
            result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
        case "questionRoot": {
            const tsValue = value.value.value;
            result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "question1depth": {
            const tsValue = value.value.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
            break;
        }
        case "question2depth": {
            const tsValue = value.value.value;
            result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
            break;
        }
        case "lessonRoot": {
            const tsValue = value.value.value;
            result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
            break;
        }
        case "lesson1depth": {
            const tsValue = value.value.value;
            result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_6(tsValue) }]);
            break;
        }
        case "lesson2depth": {
            const tsValue = value.value.value;
            result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_7(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    value: new Set([1, 2, 3, 4, 5, 6, 7]),
};
const oneofFieldNamesMap = {
    value: new Map([
        [1, "undefined"],
        [2, "questionRoot"],
        [3, "question1depth"],
        [4, "question2depth"],
        [5, "lessonRoot"],
        [6, "lesson1depth"],
        [7, "lesson2depth"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.value;
        const oneofFieldNames = oneofFieldNamesMap.value;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [1](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
            [2](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined; },
            [4](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined; },
            [5](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined; },
            [6](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_6(wireValue.value) : undefined; },
            [7](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_7(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.value = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
