// @ts-nocheck
import { name2num, num2name, } from "./(ContentInteractionStateBehavior)/ActionType";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { default as Long, } from "../../../../../runtime/Long";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        sessionId: "0",
        contentInteractionStateId: "0",
        actionType: "UNSPECIFIED",
        time: undefined,
        metadata: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.sessionId !== undefined)
        result.sessionId = tsValueToJsonValueFns.int64(value.sessionId);
    if (value.contentInteractionStateId !== undefined)
        result.contentInteractionStateId = tsValueToJsonValueFns.int64(value.contentInteractionStateId);
    if (value.actionType !== undefined)
        result.actionType = tsValueToJsonValueFns.enum(value.actionType);
    if (value.time !== undefined)
        result.time = encodeJson_1(value.time);
    if (value.metadata !== undefined)
        result.metadata = tsValueToJsonValueFns.string(value.metadata);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.sessionId !== undefined)
        result.sessionId = jsonValueToTsValueFns.int64(value.sessionId);
    if (value.contentInteractionStateId !== undefined)
        result.contentInteractionStateId = jsonValueToTsValueFns.int64(value.contentInteractionStateId);
    if (value.actionType !== undefined)
        result.actionType = jsonValueToTsValueFns.enum(value.actionType);
    if (value.time !== undefined)
        result.time = decodeJson_1(value.time);
    if (value.metadata !== undefined)
        result.metadata = jsonValueToTsValueFns.string(value.metadata);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.sessionId !== undefined) {
        const tsValue = value.sessionId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.contentInteractionStateId !== undefined) {
        const tsValue = value.contentInteractionStateId;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.actionType !== undefined) {
        const tsValue = value.actionType;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.time !== undefined) {
        const tsValue = value.time;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.metadata !== undefined) {
        const tsValue = value.metadata;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.sessionId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.contentInteractionStateId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.actionType = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.time = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.metadata = value;
    }
    return result;
}
