export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_SUBSCRIPTION_ORDER_NOT_FOUND",
    2: "ERROR_SUBSCRIPTION_ORDER_NOT_IN_CONFIGURABLE_STATUS",
    3: "ERROR_INVALID_PAYMENT_PROVIDER",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_SUBSCRIPTION_ORDER_NOT_FOUND: 1,
    ERROR_SUBSCRIPTION_ORDER_NOT_IN_CONFIGURABLE_STATUS: 2,
    ERROR_INVALID_PAYMENT_PROVIDER: 3,
};
