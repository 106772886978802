export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_SUBSCRIPTION_ORDER_NOT_FOUND",
    2: "ERROR_SUBSCRIPTION_ORDER_NOT_IN_CANCELLABLE_STATUS",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_SUBSCRIPTION_ORDER_NOT_FOUND: 1,
    ERROR_SUBSCRIPTION_ORDER_NOT_IN_CANCELLABLE_STATUS: 2,
};
