// @ts-nocheck
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(FastCatSession)/Task";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../../../../google/protobuf/Empty";
import { name2num, num2name, } from "./(PutInteractionResponse)/Error";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(FastCatSession)/ScoreEstimation";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../../../runtime/Long";
import { default as deserialize, } from "../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        error: "ERROR_UNSPECIFIED",
        scoreEstimation: undefined,
        task: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.error !== undefined)
        result.error = tsValueToJsonValueFns.enum(value.error);
    if (value.scoreEstimation !== undefined)
        result.scoreEstimation = encodeJson_1(value.scoreEstimation);
    switch ((_a = value.task) === null || _a === void 0 ? void 0 : _a.field) {
        case "nextTask": {
            result.nextTask = encodeJson_2(value.task.value);
            break;
        }
        case "empty": {
            result.empty = encodeJson_3(value.task.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.error !== undefined)
        result.error = jsonValueToTsValueFns.enum(value.error);
    if (value.scoreEstimation !== undefined)
        result.scoreEstimation = decodeJson_1(value.scoreEstimation);
    if (value.nextTask !== undefined)
        result.task = { field: "nextTask", value: decodeJson_2(value.nextTask) };
    if (value.empty !== undefined)
        result.task = { field: "empty", value: decodeJson_3(value.empty) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.error !== undefined) {
        const tsValue = value.error;
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.scoreEstimation !== undefined) {
        const tsValue = value.scoreEstimation;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    switch ((_a = value.task) === null || _a === void 0 ? void 0 : _a.field) {
        case "nextTask": {
            const tsValue = value.task.value;
            result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "empty": {
            const tsValue = value.task.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    task: new Set([2, 3]),
};
const oneofFieldNamesMap = {
    task: new Map([
        [2, "nextTask"],
        [3, "empty"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.error = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.scoreEstimation = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.task;
        const oneofFieldNames = oneofFieldNamesMap.task;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [2](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.task = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
