import { encodeBinary, decodeBinary, encodeJson, } from "../../../../../messages/santa/events/gtelp/v1/GetStatusRequest";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, encodeJson as encodeJson_1, } from "../../../../../messages/santa/events/gtelp/v1/GetStatusResponse";
import { encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, encodeJson as encodeJson_2, } from "../../../../../messages/santa/events/gtelp/v1/GetProfileRequest";
import { encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, encodeJson as encodeJson_3, } from "../../../../../messages/santa/events/gtelp/v1/GetProfileResponse";
import { encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, encodeJson as encodeJson_4, } from "../../../../../messages/santa/events/gtelp/v1/GetContentInfosRequest";
import { encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, encodeJson as encodeJson_5, } from "../../../../../messages/santa/events/gtelp/v1/GetContentInfosResponse";
import { encodeBinary as encodeBinary_6, decodeBinary as decodeBinary_6, encodeJson as encodeJson_6, } from "../../../../../messages/santa/events/gtelp/v1/GetAnswerStatesRequest";
import { encodeBinary as encodeBinary_7, decodeBinary as decodeBinary_7, encodeJson as encodeJson_7, } from "../../../../../messages/santa/events/gtelp/v1/GetAnswerStatesResponse";
import { encodeBinary as encodeBinary_8, decodeBinary as decodeBinary_8, encodeJson as encodeJson_8, } from "../../../../../messages/santa/events/gtelp/v1/GetResultRequest";
import { encodeBinary as encodeBinary_9, decodeBinary as decodeBinary_9, encodeJson as encodeJson_9, } from "../../../../../messages/santa/events/gtelp/v1/GetResultResponse";
import { encodeBinary as encodeBinary_10, decodeBinary as decodeBinary_10, encodeJson as encodeJson_10, } from "../../../../../messages/santa/events/gtelp/v1/UpdateProfileRequest";
import { encodeBinary as encodeBinary_11, decodeBinary as decodeBinary_11, encodeJson as encodeJson_11, } from "../../../../../messages/santa/events/gtelp/v1/UpdateProfileResponse";
import { encodeBinary as encodeBinary_12, decodeBinary as decodeBinary_12, encodeJson as encodeJson_12, } from "../../../../../messages/santa/events/gtelp/v1/UpdateAnswerStateRequest";
import { encodeBinary as encodeBinary_13, decodeBinary as decodeBinary_13, encodeJson as encodeJson_13, } from "../../../../../messages/santa/events/gtelp/v1/UpdateAnswerStateResponse";
import { encodeBinary as encodeBinary_14, decodeBinary as decodeBinary_14, encodeJson as encodeJson_14, } from "../../../../../messages/santa/events/gtelp/v1/FinishRequest";
import { encodeBinary as encodeBinary_15, decodeBinary as decodeBinary_15, encodeJson as encodeJson_15, } from "../../../../../messages/santa/events/gtelp/v1/FinishResponse";
import { fromSingle, first, } from "../../../../../runtime/async/async-generator";
import { wrapRpcClientImpl, getDevtoolsConfig, } from "../../../../../runtime/client-devtools";
export const methodDescriptors = {
    getStatus: {
        methodName: "GetStatus",
        service: { serviceName: "santa.events.gtelp.v1.GtelpService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary,
            deserializeBinary: decodeBinary,
            serializeJson: (value) => JSON.stringify(encodeJson(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_1,
            deserializeBinary: decodeBinary_1,
            serializeJson: (value) => JSON.stringify(encodeJson_1(value)),
        },
    },
    getProfile: {
        methodName: "GetProfile",
        service: { serviceName: "santa.events.gtelp.v1.GtelpService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_2,
            deserializeBinary: decodeBinary_2,
            serializeJson: (value) => JSON.stringify(encodeJson_2(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_3,
            deserializeBinary: decodeBinary_3,
            serializeJson: (value) => JSON.stringify(encodeJson_3(value)),
        },
    },
    getContentInfos: {
        methodName: "GetContentInfos",
        service: { serviceName: "santa.events.gtelp.v1.GtelpService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_4,
            deserializeBinary: decodeBinary_4,
            serializeJson: (value) => JSON.stringify(encodeJson_4(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_5,
            deserializeBinary: decodeBinary_5,
            serializeJson: (value) => JSON.stringify(encodeJson_5(value)),
        },
    },
    getAnswerStates: {
        methodName: "GetAnswerStates",
        service: { serviceName: "santa.events.gtelp.v1.GtelpService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_6,
            deserializeBinary: decodeBinary_6,
            serializeJson: (value) => JSON.stringify(encodeJson_6(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_7,
            deserializeBinary: decodeBinary_7,
            serializeJson: (value) => JSON.stringify(encodeJson_7(value)),
        },
    },
    getResult: {
        methodName: "GetResult",
        service: { serviceName: "santa.events.gtelp.v1.GtelpService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_8,
            deserializeBinary: decodeBinary_8,
            serializeJson: (value) => JSON.stringify(encodeJson_8(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_9,
            deserializeBinary: decodeBinary_9,
            serializeJson: (value) => JSON.stringify(encodeJson_9(value)),
        },
    },
    updateProfile: {
        methodName: "UpdateProfile",
        service: { serviceName: "santa.events.gtelp.v1.GtelpService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_10,
            deserializeBinary: decodeBinary_10,
            serializeJson: (value) => JSON.stringify(encodeJson_10(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_11,
            deserializeBinary: decodeBinary_11,
            serializeJson: (value) => JSON.stringify(encodeJson_11(value)),
        },
    },
    updateAnswerState: {
        methodName: "UpdateAnswerState",
        service: { serviceName: "santa.events.gtelp.v1.GtelpService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_12,
            deserializeBinary: decodeBinary_12,
            serializeJson: (value) => JSON.stringify(encodeJson_12(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_13,
            deserializeBinary: decodeBinary_13,
            serializeJson: (value) => JSON.stringify(encodeJson_13(value)),
        },
    },
    finish: {
        methodName: "Finish",
        service: { serviceName: "santa.events.gtelp.v1.GtelpService" },
        requestStream: false,
        responseStream: false,
        requestType: {
            serializeBinary: encodeBinary_14,
            deserializeBinary: decodeBinary_14,
            serializeJson: (value) => JSON.stringify(encodeJson_14(value)),
        },
        responseType: {
            serializeBinary: encodeBinary_15,
            deserializeBinary: decodeBinary_15,
            serializeJson: (value) => JSON.stringify(encodeJson_15(value)),
        },
    },
};
export class RpcError extends Error {
    constructor(trailer) {
        super();
        this.trailer = trailer;
    }
}
export function createServiceClient(rpcClientImpl, config) {
    var _a, _b;
    let _rpcClientImpl = rpcClientImpl;
    const responseOnly = (_a = config === null || config === void 0 ? void 0 : config.responseOnly) !== null && _a !== void 0 ? _a : true;
    const devtools = (_b = config === null || config === void 0 ? void 0 : config.devtools) !== null && _b !== void 0 ? _b : false;
    if (devtools) {
        const tags = devtools === true ? [] : devtools.tags;
        const devtoolsConfig = getDevtoolsConfig();
        _rpcClientImpl = wrapRpcClientImpl({ rpcClientImpl, devtoolsConfig, tags });
    }
    return Object.fromEntries(Object.entries(methodDescriptors).map(([camelRpcName, methodDescriptor]) => {
        const { requestStream, responseStream } = methodDescriptor;
        const rpcMethodImpl = _rpcClientImpl(methodDescriptor);
        const rpcMethodHandler = async (request, metadata) => {
            const reqAsyncGenerator = requestStream ? request : fromSingle(request);
            const rpcMethodResult = rpcMethodImpl(reqAsyncGenerator, metadata);
            const resAsyncGenerator = rpcMethodResult[0];
            const headerPromise = rpcMethodResult[1];
            const trailerPromise = rpcMethodResult[2];
            const [header, response] = await Promise.all([
                getHeaderBeforeTrailer(headerPromise, trailerPromise),
                responseStream ? resAsyncGenerator : first(resAsyncGenerator),
            ]);
            return responseOnly ? response : [response, header, trailerPromise];
        };
        return [camelRpcName, rpcMethodHandler];
    }));
}
function getHeaderBeforeTrailer(headerPromise, trailerPromise) {
    return Promise.race([
        headerPromise,
        trailerPromise.then(trailer => { throw new RpcError(trailer); }),
    ]);
}
