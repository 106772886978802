// @ts-nocheck
import { name2num, num2name, } from "../(Feedback)/Type";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        sessionId: "0",
        preference: false,
        text: "",
        type: "UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.sessionId !== undefined)
        result.sessionId = tsValueToJsonValueFns.int64(value.sessionId);
    if (value.preference !== undefined)
        result.preference = tsValueToJsonValueFns.bool(value.preference);
    if (value.text !== undefined)
        result.text = tsValueToJsonValueFns.string(value.text);
    if (value.type !== undefined)
        result.type = tsValueToJsonValueFns.enum(value.type);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.sessionId !== undefined)
        result.sessionId = jsonValueToTsValueFns.int64(value.sessionId);
    if (value.preference !== undefined)
        result.preference = jsonValueToTsValueFns.bool(value.preference);
    if (value.text !== undefined)
        result.text = jsonValueToTsValueFns.string(value.text);
    if (value.type !== undefined)
        result.type = jsonValueToTsValueFns.enum(value.type);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.sessionId !== undefined) {
        const tsValue = value.sessionId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.preference !== undefined) {
        const tsValue = value.preference;
        result.push([2, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.text !== undefined) {
        const tsValue = value.text;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.type !== undefined) {
        const tsValue = value.type;
        result.push([4, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.sessionId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.preference = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.text = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.type = value;
    }
    return result;
}
