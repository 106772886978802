// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../google/protobuf/Duration";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./LearningInformation";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./ScoreInformation";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./TagProbabilityWithPrevious";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./TagProbabilityWithRecommendedLesson";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        cycleId: "0",
        ordinalNumber: "0",
        totalElapsedTime: undefined,
        learningInformation: undefined,
        scoreInformation: [],
        skillInformation: [],
        chapterInformation: [],
        chapterInformationWithRecommendedLesson: [],
        targetTotalScore: 0,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.cycleId !== undefined)
        result.cycleId = tsValueToJsonValueFns.int64(value.cycleId);
    if (value.ordinalNumber !== undefined)
        result.ordinalNumber = tsValueToJsonValueFns.int64(value.ordinalNumber);
    if (value.totalElapsedTime !== undefined)
        result.totalElapsedTime = encodeJson_1(value.totalElapsedTime);
    if (value.learningInformation !== undefined)
        result.learningInformation = encodeJson_2(value.learningInformation);
    result.scoreInformation = value.scoreInformation.map(value => encodeJson_3(value));
    result.skillInformation = value.skillInformation.map(value => encodeJson_4(value));
    result.chapterInformation = value.chapterInformation.map(value => encodeJson_4(value));
    result.chapterInformationWithRecommendedLesson = value.chapterInformationWithRecommendedLesson.map(value => encodeJson_5(value));
    if (value.targetTotalScore !== undefined)
        result.targetTotalScore = tsValueToJsonValueFns.int32(value.targetTotalScore);
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const result = getDefaultValue();
    if (value.cycleId !== undefined)
        result.cycleId = jsonValueToTsValueFns.int64(value.cycleId);
    if (value.ordinalNumber !== undefined)
        result.ordinalNumber = jsonValueToTsValueFns.int64(value.ordinalNumber);
    if (value.totalElapsedTime !== undefined)
        result.totalElapsedTime = decodeJson_1(value.totalElapsedTime);
    if (value.learningInformation !== undefined)
        result.learningInformation = decodeJson_2(value.learningInformation);
    result.scoreInformation = (_b = (_a = value.scoreInformation) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_3(value))) !== null && _b !== void 0 ? _b : [];
    result.skillInformation = (_d = (_c = value.skillInformation) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_4(value))) !== null && _d !== void 0 ? _d : [];
    result.chapterInformation = (_f = (_e = value.chapterInformation) === null || _e === void 0 ? void 0 : _e.map((value) => decodeJson_4(value))) !== null && _f !== void 0 ? _f : [];
    result.chapterInformationWithRecommendedLesson = (_h = (_g = value.chapterInformationWithRecommendedLesson) === null || _g === void 0 ? void 0 : _g.map((value) => decodeJson_5(value))) !== null && _h !== void 0 ? _h : [];
    if (value.targetTotalScore !== undefined)
        result.targetTotalScore = jsonValueToTsValueFns.int32(value.targetTotalScore);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.cycleId !== undefined) {
        const tsValue = value.cycleId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.ordinalNumber !== undefined) {
        const tsValue = value.ordinalNumber;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.totalElapsedTime !== undefined) {
        const tsValue = value.totalElapsedTime;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.learningInformation !== undefined) {
        const tsValue = value.learningInformation;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    for (const tsValue of value.scoreInformation) {
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    for (const tsValue of value.skillInformation) {
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    for (const tsValue of value.chapterInformation) {
        result.push([7, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    for (const tsValue of value.chapterInformationWithRecommendedLesson) {
        result.push([8, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
    }
    if (value.targetTotalScore !== undefined) {
        const tsValue = value.targetTotalScore;
        result.push([9, tsValueToWireValueFns.int32(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.cycleId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.ordinalNumber = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.totalElapsedTime = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.learningInformation = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.scoreInformation = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 6).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.skillInformation = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 7).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.chapterInformation = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 8).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.chapterInformationWithRecommendedLesson = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.targetTotalScore = value;
    }
    return result;
}
