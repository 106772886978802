export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_INVALID_CIS_HIGHLIGHT_INFO_TEMPLATES",
    2: "ERROR_INVALID_CONTENT_INTERACTION_STATE_ID",
    3: "ERROR_CONTENT_BODY_NOT_FOUND",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_INVALID_CIS_HIGHLIGHT_INFO_TEMPLATES: 1,
    ERROR_INVALID_CONTENT_INTERACTION_STATE_ID: 2,
    ERROR_CONTENT_BODY_NOT_FOUND: 3,
};
