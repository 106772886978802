// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(LearningSelfCard)/Lesson";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(LearningSelfCard)/Vocabulary";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./(LearningSelfCard)/VirtualExam";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./(LearningSelfCard)/LessonV2";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../google/protobuf/Int64Value";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        sessionId: undefined,
        self: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.sessionId !== undefined)
        result.sessionId = value.sessionId;
    switch ((_a = value.self) === null || _a === void 0 ? void 0 : _a.field) {
        case "lesson": {
            result.lesson = encodeJson_1(value.self.value);
            break;
        }
        case "vocabulary": {
            result.vocabulary = encodeJson_2(value.self.value);
            break;
        }
        case "virtualExam": {
            result.virtualExam = encodeJson_3(value.self.value);
            break;
        }
        case "lessonV2": {
            result.lessonV2 = encodeJson_4(value.self.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.sessionId !== undefined)
        result.sessionId = value.sessionId;
    if (value.lesson !== undefined)
        result.self = { field: "lesson", value: decodeJson_1(value.lesson) };
    if (value.vocabulary !== undefined)
        result.self = { field: "vocabulary", value: decodeJson_2(value.vocabulary) };
    if (value.virtualExam !== undefined)
        result.self = { field: "virtualExam", value: decodeJson_3(value.virtualExam) };
    if (value.lessonV2 !== undefined)
        result.self = { field: "lessonV2", value: decodeJson_4(value.lessonV2) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.sessionId !== undefined) {
        const tsValue = value.sessionId;
        result.push([1, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    switch ((_a = value.self) === null || _a === void 0 ? void 0 : _a.field) {
        case "lesson": {
            const tsValue = value.self.value;
            result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "vocabulary": {
            const tsValue = value.self.value;
            result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
            break;
        }
        case "virtualExam": {
            const tsValue = value.self.value;
            result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
            break;
        }
        case "lessonV2": {
            const tsValue = value.self.value;
            result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    self: new Set([2, 4, 5, 6]),
};
const oneofFieldNamesMap = {
    self: new Map([
        [2, "lesson"],
        [4, "vocabulary"],
        [5, "virtualExam"],
        [6, "lessonV2"],
    ]),
};
export function decodeBinary(binary) {
    var _a, _b;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.sessionId = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.self;
        const oneofFieldNames = oneofFieldNamesMap.self;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [2](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [4](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined; },
            [5](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined; },
            [6](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined; },
        };
        const value = (_b = wireValueToTsValueMap[fieldNumber]) === null || _b === void 0 ? void 0 : _b(wireValue);
        if (value === undefined)
            break oneof;
        result.self = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
