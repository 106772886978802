export const num2name = {
    0: "TYPE_UNSPECIFIED",
    1: "LC_RC",
    2: "RC",
};
export const name2num = {
    TYPE_UNSPECIFIED: 0,
    LC_RC: 1,
    RC: 2,
};
