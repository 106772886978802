export const num2name = {
    0: "PARENT_TYPE_UNSPECIFIED",
    1: "CYCLE",
    2: "SELF",
    3: "MY_NOTE_QUIZ",
    4: "VIRTUAL_EXAM",
};
export const name2num = {
    PARENT_TYPE_UNSPECIFIED: 0,
    CYCLE: 1,
    SELF: 2,
    MY_NOTE_QUIZ: 3,
    VIRTUAL_EXAM: 4,
};
