export const num2name = {
    0: "USER_SAG_UNSPECIFIED",
    1: "BASIC",
    2: "INTERMEDIATE",
    3: "ADVANCED",
    4: "BASIC_N",
    5: "INTERMEDIATE_N",
    6: "ADVANCED_N",
};
export const name2num = {
    USER_SAG_UNSPECIFIED: 0,
    BASIC: 1,
    INTERMEDIATE: 2,
    ADVANCED: 3,
    BASIC_N: 4,
    INTERMEDIATE_N: 5,
    ADVANCED_N: 6,
};
