export const num2name = {
    0: "UNSPECIFIED",
    1: "ASC",
    2: "DESC",
};
export const name2num = {
    UNSPECIFIED: 0,
    ASC: 1,
    DESC: 2,
};
