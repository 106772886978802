// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(GetLearningSelfCardRequest)/LessonV2";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        argument: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    switch ((_a = value.argument) === null || _a === void 0 ? void 0 : _a.field) {
        case "lessonV2": {
            result.lessonV2 = encodeJson_1(value.argument.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.lessonV2 !== undefined)
        result.argument = { field: "lessonV2", value: decodeJson_1(value.lessonV2) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    switch ((_a = value.argument) === null || _a === void 0 ? void 0 : _a.field) {
        case "lessonV2": {
            const tsValue = value.argument.value;
            result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    argument: new Set([1]),
};
const oneofFieldNamesMap = {
    argument: new Map([
        [1, "lessonV2"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.argument;
        const oneofFieldNames = oneofFieldNamesMap.argument;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [1](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.argument = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
