// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../runtime/json/scalar";
import { default as serialize, } from "../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../runtime/wire/scalar";
import { default as deserialize, } from "../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        page: 0,
        size: 0,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.page !== undefined)
        result.page = tsValueToJsonValueFns.uint32(value.page);
    if (value.size !== undefined)
        result.size = tsValueToJsonValueFns.uint32(value.size);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.page !== undefined)
        result.page = jsonValueToTsValueFns.uint32(value.page);
    if (value.size !== undefined)
        result.size = jsonValueToTsValueFns.uint32(value.size);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.page !== undefined) {
        const tsValue = value.page;
        result.push([1, tsValueToWireValueFns.uint32(tsValue)]);
    }
    if (value.size !== undefined) {
        const tsValue = value.size;
        result.push([2, tsValueToWireValueFns.uint32(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint32(wireValue);
        if (value === undefined)
            break field;
        result.page = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint32(wireValue);
        if (value === undefined)
            break field;
        result.size = value;
    }
    return result;
}
