// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../my_note/(MyNote)/Selection";
import { name2num, num2name, } from "./(CreateMyNoteQuizRequest)/ContentOrder";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        myNoteSelection: undefined,
        contentOrder: "QUIZ_ORDER_UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.myNoteSelection !== undefined)
        result.myNoteSelection = encodeJson_1(value.myNoteSelection);
    if (value.contentOrder !== undefined)
        result.contentOrder = tsValueToJsonValueFns.enum(value.contentOrder);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.myNoteSelection !== undefined)
        result.myNoteSelection = decodeJson_1(value.myNoteSelection);
    if (value.contentOrder !== undefined)
        result.contentOrder = jsonValueToTsValueFns.enum(value.contentOrder);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.myNoteSelection !== undefined) {
        const tsValue = value.myNoteSelection;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.contentOrder !== undefined) {
        const tsValue = value.contentOrder;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.myNoteSelection = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.contentOrder = value;
    }
    return result;
}
