// @ts-nocheck
import { name2num, num2name, } from "../(SelfCardCategory)/Lesson";
import { name2num as name2num_1, num2name as num2name_1, } from "../(SelfCardCategory)/Vocabulary";
import { name2num as name2num_2, num2name as num2name_2, } from "../(SelfCardCategory)/VirtualExam";
import { name2num as name2num_3, num2name as num2name_3, } from "../(SelfCardCategory)/LessonV2";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(ContentCardInfo)/Tag";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Int64Value";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        totalCount: "0",
        studiedCount: "0",
        unnecessaryCount: undefined,
        isFree: false,
        tags: [],
        contentGroupList: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.totalCount !== undefined)
        result.totalCount = tsValueToJsonValueFns.int64(value.totalCount);
    if (value.studiedCount !== undefined)
        result.studiedCount = tsValueToJsonValueFns.int64(value.studiedCount);
    if (value.unnecessaryCount !== undefined)
        result.unnecessaryCount = value.unnecessaryCount;
    if (value.isFree !== undefined)
        result.isFree = tsValueToJsonValueFns.bool(value.isFree);
    result.tags = value.tags.map(value => encodeJson_1(value));
    switch ((_a = value.contentGroupList) === null || _a === void 0 ? void 0 : _a.field) {
        case "lesson": {
            result.lesson = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
        case "vocabulary": {
            result.vocabulary = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
        case "virtualExam": {
            result.virtualExam = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
        case "lessonV2": {
            result.lessonV2 = tsValueToJsonValueFns.enum(value.contentGroupList.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.totalCount !== undefined)
        result.totalCount = jsonValueToTsValueFns.int64(value.totalCount);
    if (value.studiedCount !== undefined)
        result.studiedCount = jsonValueToTsValueFns.int64(value.studiedCount);
    if (value.unnecessaryCount !== undefined)
        result.unnecessaryCount = value.unnecessaryCount;
    if (value.isFree !== undefined)
        result.isFree = jsonValueToTsValueFns.bool(value.isFree);
    result.tags = (_b = (_a = value.tags) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    if (value.lesson !== undefined)
        result.contentGroupList = { field: "lesson", value: jsonValueToTsValueFns.enum(value.lesson) };
    if (value.vocabulary !== undefined)
        result.contentGroupList = { field: "vocabulary", value: jsonValueToTsValueFns.enum(value.vocabulary) };
    if (value.virtualExam !== undefined)
        result.contentGroupList = { field: "virtualExam", value: jsonValueToTsValueFns.enum(value.virtualExam) };
    if (value.lessonV2 !== undefined)
        result.contentGroupList = { field: "lessonV2", value: jsonValueToTsValueFns.enum(value.lessonV2) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.totalCount !== undefined) {
        const tsValue = value.totalCount;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.studiedCount !== undefined) {
        const tsValue = value.studiedCount;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.unnecessaryCount !== undefined) {
        const tsValue = value.unnecessaryCount;
        result.push([3, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.isFree !== undefined) {
        const tsValue = value.isFree;
        result.push([4, tsValueToWireValueFns.bool(tsValue)]);
    }
    for (const tsValue of value.tags) {
        result.push([11, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    switch ((_a = value.contentGroupList) === null || _a === void 0 ? void 0 : _a.field) {
        case "lesson": {
            const tsValue = value.contentGroupList.value;
            result.push([5, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
            break;
        }
        case "vocabulary": {
            const tsValue = value.contentGroupList.value;
            result.push([7, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
            break;
        }
        case "virtualExam": {
            const tsValue = value.contentGroupList.value;
            result.push([8, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
            break;
        }
        case "lessonV2": {
            const tsValue = value.contentGroupList.value;
            result.push([9, { type: WireType.Varint, value: new Long(name2num_3[tsValue]) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    contentGroupList: new Set([5, 7, 8, 9]),
};
const oneofFieldNamesMap = {
    contentGroupList: new Map([
        [5, "lesson"],
        [7, "vocabulary"],
        [8, "virtualExam"],
        [9, "lessonV2"],
    ]),
};
export function decodeBinary(binary) {
    var _a, _b;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.totalCount = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.studiedCount = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.unnecessaryCount = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isFree = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 11).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.tags = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.contentGroupList;
        const oneofFieldNames = oneofFieldNamesMap.contentGroupList;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [5](wireValue) { return wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined; },
            [7](wireValue) { return wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined; },
            [8](wireValue) { return wireValue.type === WireType.Varint ? num2name_2[wireValue.value[0]] : undefined; },
            [9](wireValue) { return wireValue.type === WireType.Varint ? num2name_3[wireValue.value[0]] : undefined; },
        };
        const value = (_b = wireValueToTsValueMap[fieldNumber]) === null || _b === void 0 ? void 0 : _b(wireValue);
        if (value === undefined)
            break oneof;
        result.contentGroupList = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
