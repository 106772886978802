// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(CheckLearningAuthorityRequest)/SelfCardCategorySelection";
import { name2num, num2name, } from "../authority/AuthorityType";
import { name2num as name2num_1, num2name as num2name_1, } from "./(CheckLearningAuthorityRequest)/LearningDomain";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as Long, } from "../../../runtime/Long";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        type: "AUTHORITY_TYPE_UNSPECIFIED",
        learningDomain: "LEARNING_DOMAIN_UNSPECIFIED",
        extra: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.type !== undefined)
        result.type = tsValueToJsonValueFns.enum(value.type);
    if (value.learningDomain !== undefined)
        result.learningDomain = tsValueToJsonValueFns.enum(value.learningDomain);
    switch ((_a = value.extra) === null || _a === void 0 ? void 0 : _a.field) {
        case "selfCardCategory": {
            result.selfCardCategory = encodeJson_1(value.extra.value);
            break;
        }
        case "selfLearningHierarchyId": {
            result.selfLearningHierarchyId = tsValueToJsonValueFns.int64(value.extra.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.type !== undefined)
        result.type = jsonValueToTsValueFns.enum(value.type);
    if (value.learningDomain !== undefined)
        result.learningDomain = jsonValueToTsValueFns.enum(value.learningDomain);
    if (value.selfCardCategory !== undefined)
        result.extra = { field: "selfCardCategory", value: decodeJson_1(value.selfCardCategory) };
    if (value.selfLearningHierarchyId !== undefined)
        result.extra = { field: "selfLearningHierarchyId", value: jsonValueToTsValueFns.int64(value.selfLearningHierarchyId) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.type !== undefined) {
        const tsValue = value.type;
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.learningDomain !== undefined) {
        const tsValue = value.learningDomain;
        result.push([7, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    switch ((_a = value.extra) === null || _a === void 0 ? void 0 : _a.field) {
        case "selfCardCategory": {
            const tsValue = value.extra.value;
            result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
        case "selfLearningHierarchyId": {
            const tsValue = value.extra.value;
            result.push([6, tsValueToWireValueFns.int64(tsValue)]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    extra: new Set([5, 6]),
};
const oneofFieldNamesMap = {
    extra: new Map([
        [5, "selfCardCategory"],
        [6, "selfLearningHierarchyId"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.type = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name_1[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.learningDomain = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.extra;
        const oneofFieldNames = oneofFieldNamesMap.extra;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [5](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
            [6](wireValue) { return wireValueToTsValueFns.int64(wireValue); },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.extra = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
