export const num2name = {
    0: "LEARNING_CYCLE_TYPE_UNSPECIFIED",
    1: "LEARNING_CYCLE_TYPE_COURSE",
    2: "LEARNING_CYCLE_TYPE_DIAGNOSIS",
};
export const name2num = {
    LEARNING_CYCLE_TYPE_UNSPECIFIED: 0,
    LEARNING_CYCLE_TYPE_COURSE: 1,
    LEARNING_CYCLE_TYPE_DIAGNOSIS: 2,
};
