import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {};
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    return result;
}
export function encodeBinary(value) {
    const result = [];
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    return result;
}
