export const num2name = {
    0: "STATUS_UNSPECIFIED",
    1: "SAVE",
    2: "ACTIVE",
    3: "END",
};
export const name2num = {
    STATUS_UNSPECIFIED: 0,
    SAVE: 1,
    ACTIVE: 2,
    END: 3,
};
