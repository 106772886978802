// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        text: "",
        appUrl: "",
        webUrl: "",
        webUrlIsExternal: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.text !== undefined)
        result.text = tsValueToJsonValueFns.string(value.text);
    if (value.appUrl !== undefined)
        result.appUrl = tsValueToJsonValueFns.string(value.appUrl);
    if (value.webUrl !== undefined)
        result.webUrl = tsValueToJsonValueFns.string(value.webUrl);
    if (value.webUrlIsExternal !== undefined)
        result.webUrlIsExternal = tsValueToJsonValueFns.bool(value.webUrlIsExternal);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.text !== undefined)
        result.text = jsonValueToTsValueFns.string(value.text);
    if (value.appUrl !== undefined)
        result.appUrl = jsonValueToTsValueFns.string(value.appUrl);
    if (value.webUrl !== undefined)
        result.webUrl = jsonValueToTsValueFns.string(value.webUrl);
    if (value.webUrlIsExternal !== undefined)
        result.webUrlIsExternal = jsonValueToTsValueFns.bool(value.webUrlIsExternal);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.text !== undefined) {
        const tsValue = value.text;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.appUrl !== undefined) {
        const tsValue = value.appUrl;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.webUrl !== undefined) {
        const tsValue = value.webUrl;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.webUrlIsExternal !== undefined) {
        const tsValue = value.webUrlIsExternal;
        result.push([4, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.text = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.appUrl = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.webUrl = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.webUrlIsExternal = value;
    }
    return result;
}
