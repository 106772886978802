export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_NOT_SUBMITTED_YET",
    2: "ERROR_SESSION_NOT_DONE_YET",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_NOT_SUBMITTED_YET: 1,
    ERROR_SESSION_NOT_DONE_YET: 2,
};
