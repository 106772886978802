import {atomWithDefault} from 'jotai/utils';

import {createGrpcServices} from '@app/api/grpc-services';
import tokenManagerAtom from '@app/atoms/core/token-manager';

const grpcServicesAtom = atomWithDefault(async get => {
  const tokenManager = await get(tokenManagerAtom);
  const grpcServices = createGrpcServices(tokenManager);
  return grpcServices;
});

export default grpcServicesAtom;

grpcServicesAtom.debugPrivate = true;
