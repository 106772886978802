export const num2name = {
    0: "UNSPECIFIED",
    1: "SELF_REGISTER_NOT_ALLOWED",
    2: "DUPLICATE_REFERRAL_CODE_REGISTER_NOT_ALLOWED",
};
export const name2num = {
    UNSPECIFIED: 0,
    SELF_REGISTER_NOT_ALLOWED: 1,
    DUPLICATE_REFERRAL_CODE_REGISTER_NOT_ALLOWED: 2,
};
