// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        availableCouponCount: 0,
        hasNewCoupon: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.availableCouponCount !== undefined)
        result.availableCouponCount = tsValueToJsonValueFns.int32(value.availableCouponCount);
    if (value.hasNewCoupon !== undefined)
        result.hasNewCoupon = tsValueToJsonValueFns.bool(value.hasNewCoupon);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.availableCouponCount !== undefined)
        result.availableCouponCount = jsonValueToTsValueFns.int32(value.availableCouponCount);
    if (value.hasNewCoupon !== undefined)
        result.hasNewCoupon = jsonValueToTsValueFns.bool(value.hasNewCoupon);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.availableCouponCount !== undefined) {
        const tsValue = value.availableCouponCount;
        result.push([1, tsValueToWireValueFns.int32(tsValue)]);
    }
    if (value.hasNewCoupon !== undefined) {
        const tsValue = value.hasNewCoupon;
        result.push([2, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int32(wireValue);
        if (value === undefined)
            break field;
        result.availableCouponCount = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.hasNewCoupon = value;
    }
    return result;
}
