export const num2name = {
    0: "UNSPECIFIED",
    1: "Q_START",
    2: "Q_END",
    3: "HINT_ON",
    4: "HINT_OFF",
    5: "QUIZ_ANSWER_MARK",
};
export const name2num = {
    UNSPECIFIED: 0,
    Q_START: 1,
    Q_END: 2,
    HINT_ON: 3,
    HINT_OFF: 4,
    QUIZ_ANSWER_MARK: 5,
};
