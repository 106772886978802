// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./AuthorityConfig";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../google/protobuf/Timestamp";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./(Authority)/Source";
import { name2num, num2name, } from "./(Authority)/AuthorityStatus";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        userId: "",
        config: undefined,
        validFrom: undefined,
        validUntil: undefined,
        source: undefined,
        status: "AUTHORITY_STATUS_UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.userId !== undefined)
        result.userId = tsValueToJsonValueFns.string(value.userId);
    if (value.config !== undefined)
        result.config = encodeJson_1(value.config);
    if (value.validFrom !== undefined)
        result.validFrom = encodeJson_2(value.validFrom);
    if (value.validUntil !== undefined)
        result.validUntil = encodeJson_2(value.validUntil);
    if (value.source !== undefined)
        result.source = encodeJson_3(value.source);
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.userId !== undefined)
        result.userId = jsonValueToTsValueFns.string(value.userId);
    if (value.config !== undefined)
        result.config = decodeJson_1(value.config);
    if (value.validFrom !== undefined)
        result.validFrom = decodeJson_2(value.validFrom);
    if (value.validUntil !== undefined)
        result.validUntil = decodeJson_2(value.validUntil);
    if (value.source !== undefined)
        result.source = decodeJson_3(value.source);
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.userId !== undefined) {
        const tsValue = value.userId;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.config !== undefined) {
        const tsValue = value.config;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.validFrom !== undefined) {
        const tsValue = value.validFrom;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.validUntil !== undefined) {
        const tsValue = value.validUntil;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.source !== undefined) {
        const tsValue = value.source;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([7, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.userId = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.config = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.validFrom = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.validUntil = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.source = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    return result;
}
