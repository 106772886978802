// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./TagProbabilityWithPrevious";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./TagProbabilityWithTarget";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./LearningSelfCard";
import { name2num, num2name, } from "./(SelfCardCategory)/LessonV2";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "../santa/contentlearning/self_learning/v1/SelfLearningHierarchyInfo";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { default as Long, } from "../../runtime/Long";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        tagProbability: undefined,
        tagProbabilityOfTargetScore: undefined,
        lessonV2: undefined,
        lessonV2Category: "LESSON_V2_UNSPECIFIED",
        lessonSelfLearningHierarchy: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.tagProbability !== undefined)
        result.tagProbability = encodeJson_1(value.tagProbability);
    if (value.tagProbabilityOfTargetScore !== undefined)
        result.tagProbabilityOfTargetScore = encodeJson_2(value.tagProbabilityOfTargetScore);
    if (value.lessonV2 !== undefined)
        result.lessonV2 = encodeJson_3(value.lessonV2);
    if (value.lessonV2Category !== undefined)
        result.lessonV2Category = tsValueToJsonValueFns.enum(value.lessonV2Category);
    if (value.lessonSelfLearningHierarchy !== undefined)
        result.lessonSelfLearningHierarchy = encodeJson_4(value.lessonSelfLearningHierarchy);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.tagProbability !== undefined)
        result.tagProbability = decodeJson_1(value.tagProbability);
    if (value.tagProbabilityOfTargetScore !== undefined)
        result.tagProbabilityOfTargetScore = decodeJson_2(value.tagProbabilityOfTargetScore);
    if (value.lessonV2 !== undefined)
        result.lessonV2 = decodeJson_3(value.lessonV2);
    if (value.lessonV2Category !== undefined)
        result.lessonV2Category = jsonValueToTsValueFns.enum(value.lessonV2Category);
    if (value.lessonSelfLearningHierarchy !== undefined)
        result.lessonSelfLearningHierarchy = decodeJson_4(value.lessonSelfLearningHierarchy);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.tagProbability !== undefined) {
        const tsValue = value.tagProbability;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.tagProbabilityOfTargetScore !== undefined) {
        const tsValue = value.tagProbabilityOfTargetScore;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.lessonV2 !== undefined) {
        const tsValue = value.lessonV2;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.lessonV2Category !== undefined) {
        const tsValue = value.lessonV2Category;
        result.push([4, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.lessonSelfLearningHierarchy !== undefined) {
        const tsValue = value.lessonSelfLearningHierarchy;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.tagProbability = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.tagProbabilityOfTargetScore = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.lessonV2 = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.lessonV2Category = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.lessonSelfLearningHierarchy = value;
    }
    return result;
}
