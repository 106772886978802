// @ts-nocheck
import { name2num, num2name, } from "./Order";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../runtime/json/scalar";
import { WireType, } from "../runtime/wire/index";
import { default as serialize, } from "../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../runtime/wire/scalar";
import { default as Long, } from "../runtime/Long";
import { default as deserialize, } from "../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        priority: 0,
        fieldNumber: 0,
        order: "UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.priority !== undefined)
        result.priority = tsValueToJsonValueFns.uint32(value.priority);
    if (value.fieldNumber !== undefined)
        result.fieldNumber = tsValueToJsonValueFns.uint32(value.fieldNumber);
    if (value.order !== undefined)
        result.order = tsValueToJsonValueFns.enum(value.order);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.priority !== undefined)
        result.priority = jsonValueToTsValueFns.uint32(value.priority);
    if (value.fieldNumber !== undefined)
        result.fieldNumber = jsonValueToTsValueFns.uint32(value.fieldNumber);
    if (value.order !== undefined)
        result.order = jsonValueToTsValueFns.enum(value.order);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.priority !== undefined) {
        const tsValue = value.priority;
        result.push([1, tsValueToWireValueFns.uint32(tsValue)]);
    }
    if (value.fieldNumber !== undefined) {
        const tsValue = value.fieldNumber;
        result.push([2, tsValueToWireValueFns.uint32(tsValue)]);
    }
    if (value.order !== undefined) {
        const tsValue = value.order;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint32(wireValue);
        if (value === undefined)
            break field;
        result.priority = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint32(wireValue);
        if (value === undefined)
            break field;
        result.fieldNumber = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.order = value;
    }
    return result;
}
