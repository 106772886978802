// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, unpackFns, } from "../../../../../../../runtime/wire/scalar";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../../../google/protobuf/StringValue";
import { default as deserialize, } from "../../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        title: "",
        skillTranslated: undefined,
        part: "",
        partNumbers: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.title !== undefined)
        result.title = tsValueToJsonValueFns.string(value.title);
    if (value.skillTranslated !== undefined)
        result.skillTranslated = value.skillTranslated;
    if (value.part !== undefined)
        result.part = tsValueToJsonValueFns.string(value.part);
    result.partNumbers = value.partNumbers.map(value => tsValueToJsonValueFns.int32(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.title !== undefined)
        result.title = jsonValueToTsValueFns.string(value.title);
    if (value.skillTranslated !== undefined)
        result.skillTranslated = value.skillTranslated;
    if (value.part !== undefined)
        result.part = jsonValueToTsValueFns.string(value.part);
    result.partNumbers = (_b = (_a = value.partNumbers) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.int32(value))) !== null && _b !== void 0 ? _b : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.title !== undefined) {
        const tsValue = value.title;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.skillTranslated !== undefined) {
        const tsValue = value.skillTranslated;
        result.push([2, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.part !== undefined) {
        const tsValue = value.part;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.partNumbers) {
        result.push([4, tsValueToWireValueFns.int32(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.title = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.skillTranslated = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.part = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues));
        if (!value.length)
            break collection;
        result.partNumbers = value;
    }
    return result;
}
