// @ts-nocheck
import { name2num, num2name, } from "./(GetVerbUseResponse)/Error";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(GetVerbUseResponse)/VerbDifferentUse";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./(GetVerbUseResponse)/VerbSimilarUse";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../../runtime/Long";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        error: "ERROR_UNSPECIFIED",
        errorMessage: "",
        verbUseRule: "",
        verbDifferentUse: undefined,
        verbSimilarUse: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.error !== undefined)
        result.error = tsValueToJsonValueFns.enum(value.error);
    if (value.errorMessage !== undefined)
        result.errorMessage = tsValueToJsonValueFns.string(value.errorMessage);
    if (value.verbUseRule !== undefined)
        result.verbUseRule = tsValueToJsonValueFns.string(value.verbUseRule);
    if (value.verbDifferentUse !== undefined)
        result.verbDifferentUse = encodeJson_1(value.verbDifferentUse);
    result.verbSimilarUse = value.verbSimilarUse.map(value => encodeJson_2(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.error !== undefined)
        result.error = jsonValueToTsValueFns.enum(value.error);
    if (value.errorMessage !== undefined)
        result.errorMessage = jsonValueToTsValueFns.string(value.errorMessage);
    if (value.verbUseRule !== undefined)
        result.verbUseRule = jsonValueToTsValueFns.string(value.verbUseRule);
    if (value.verbDifferentUse !== undefined)
        result.verbDifferentUse = decodeJson_1(value.verbDifferentUse);
    result.verbSimilarUse = (_b = (_a = value.verbSimilarUse) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_2(value))) !== null && _b !== void 0 ? _b : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.error !== undefined) {
        const tsValue = value.error;
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.errorMessage !== undefined) {
        const tsValue = value.errorMessage;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.verbUseRule !== undefined) {
        const tsValue = value.verbUseRule;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.verbDifferentUse !== undefined) {
        const tsValue = value.verbDifferentUse;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.verbSimilarUse) {
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.error = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.errorMessage = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.verbUseRule = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.verbDifferentUse = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.verbSimilarUse = value;
    }
    return result;
}
