// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./QueryParamData";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../ToeicResourceImageSet";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        message: "",
        link: "",
        webLink: "",
        isExternal: false,
        queryParamData: [],
        backgroundColor: "",
        messageColor: "",
        titleMessage: "",
        descriptionMessage: "",
        bannerImageSet: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.message !== undefined)
        result.message = tsValueToJsonValueFns.string(value.message);
    if (value.link !== undefined)
        result.link = tsValueToJsonValueFns.string(value.link);
    if (value.webLink !== undefined)
        result.webLink = tsValueToJsonValueFns.string(value.webLink);
    if (value.isExternal !== undefined)
        result.isExternal = tsValueToJsonValueFns.bool(value.isExternal);
    result.queryParamData = value.queryParamData.map(value => encodeJson_1(value));
    if (value.backgroundColor !== undefined)
        result.backgroundColor = tsValueToJsonValueFns.string(value.backgroundColor);
    if (value.messageColor !== undefined)
        result.messageColor = tsValueToJsonValueFns.string(value.messageColor);
    if (value.titleMessage !== undefined)
        result.titleMessage = tsValueToJsonValueFns.string(value.titleMessage);
    if (value.descriptionMessage !== undefined)
        result.descriptionMessage = tsValueToJsonValueFns.string(value.descriptionMessage);
    if (value.bannerImageSet !== undefined)
        result.bannerImageSet = encodeJson_2(value.bannerImageSet);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.message !== undefined)
        result.message = jsonValueToTsValueFns.string(value.message);
    if (value.link !== undefined)
        result.link = jsonValueToTsValueFns.string(value.link);
    if (value.webLink !== undefined)
        result.webLink = jsonValueToTsValueFns.string(value.webLink);
    if (value.isExternal !== undefined)
        result.isExternal = jsonValueToTsValueFns.bool(value.isExternal);
    result.queryParamData = (_b = (_a = value.queryParamData) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    if (value.backgroundColor !== undefined)
        result.backgroundColor = jsonValueToTsValueFns.string(value.backgroundColor);
    if (value.messageColor !== undefined)
        result.messageColor = jsonValueToTsValueFns.string(value.messageColor);
    if (value.titleMessage !== undefined)
        result.titleMessage = jsonValueToTsValueFns.string(value.titleMessage);
    if (value.descriptionMessage !== undefined)
        result.descriptionMessage = jsonValueToTsValueFns.string(value.descriptionMessage);
    if (value.bannerImageSet !== undefined)
        result.bannerImageSet = decodeJson_2(value.bannerImageSet);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.message !== undefined) {
        const tsValue = value.message;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.link !== undefined) {
        const tsValue = value.link;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.webLink !== undefined) {
        const tsValue = value.webLink;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.isExternal !== undefined) {
        const tsValue = value.isExternal;
        result.push([4, tsValueToWireValueFns.bool(tsValue)]);
    }
    for (const tsValue of value.queryParamData) {
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.backgroundColor !== undefined) {
        const tsValue = value.backgroundColor;
        result.push([6, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.messageColor !== undefined) {
        const tsValue = value.messageColor;
        result.push([7, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.titleMessage !== undefined) {
        const tsValue = value.titleMessage;
        result.push([8, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.descriptionMessage !== undefined) {
        const tsValue = value.descriptionMessage;
        result.push([9, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.bannerImageSet !== undefined) {
        const tsValue = value.bannerImageSet;
        result.push([10, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.message = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.link = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.webLink = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isExternal = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.queryParamData = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.backgroundColor = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.messageColor = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.titleMessage = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.descriptionMessage = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.bannerImageSet = value;
    }
    return result;
}
