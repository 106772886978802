export const num2name = {
    0: "CORRECTNESS_UNSPECIFIED",
    1: "CORRECT",
    2: "INCORRECT",
};
export const name2num = {
    CORRECTNESS_UNSPECIFIED: 0,
    CORRECT: 1,
    INCORRECT: 2,
};
