// @ts-nocheck
import { name2num, num2name, } from "./(CreateOrderRequest)/Error";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../google/protobuf/StringValue";
import { default as Long, } from "../../../../runtime/Long";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        productId: "0",
        sourceBoardCode: undefined,
        error: "ERROR_UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.productId !== undefined)
        result.productId = tsValueToJsonValueFns.int64(value.productId);
    if (value.sourceBoardCode !== undefined)
        result.sourceBoardCode = value.sourceBoardCode;
    if (value.error !== undefined)
        result.error = tsValueToJsonValueFns.enum(value.error);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.productId !== undefined)
        result.productId = jsonValueToTsValueFns.int64(value.productId);
    if (value.sourceBoardCode !== undefined)
        result.sourceBoardCode = value.sourceBoardCode;
    if (value.error !== undefined)
        result.error = jsonValueToTsValueFns.enum(value.error);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.productId !== undefined) {
        const tsValue = value.productId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.sourceBoardCode !== undefined) {
        const tsValue = value.sourceBoardCode;
        result.push([2, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.error !== undefined) {
        const tsValue = value.error;
        result.push([3, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.productId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.sourceBoardCode = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.error = value;
    }
    return result;
}
