// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../../../../../toeic/ToeicResourceImageSet";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../../runtime/wire/scalar";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../../../google/protobuf/StringValue";
import { default as deserialize, } from "../../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        title: "",
        skillTranslated: undefined,
        superordinateCategoryName: "",
        imageSet: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.title !== undefined)
        result.title = tsValueToJsonValueFns.string(value.title);
    if (value.skillTranslated !== undefined)
        result.skillTranslated = value.skillTranslated;
    if (value.superordinateCategoryName !== undefined)
        result.superordinateCategoryName = tsValueToJsonValueFns.string(value.superordinateCategoryName);
    if (value.imageSet !== undefined)
        result.imageSet = encodeJson_1(value.imageSet);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.title !== undefined)
        result.title = jsonValueToTsValueFns.string(value.title);
    if (value.skillTranslated !== undefined)
        result.skillTranslated = value.skillTranslated;
    if (value.superordinateCategoryName !== undefined)
        result.superordinateCategoryName = jsonValueToTsValueFns.string(value.superordinateCategoryName);
    if (value.imageSet !== undefined)
        result.imageSet = decodeJson_1(value.imageSet);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.title !== undefined) {
        const tsValue = value.title;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.skillTranslated !== undefined) {
        const tsValue = value.skillTranslated;
        result.push([2, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    if (value.superordinateCategoryName !== undefined) {
        const tsValue = value.superordinateCategoryName;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.imageSet !== undefined) {
        const tsValue = value.imageSet;
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.title = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.skillTranslated = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.superordinateCategoryName = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.imageSet = value;
    }
    return result;
}
