export const num2name = {
    0: "STATUS_UNSPECIFIED",
    1: "STATUS_UNREAD",
    2: "STATUS_READ",
};
export const name2num = {
    STATUS_UNSPECIFIED: 0,
    STATUS_UNREAD: 1,
    STATUS_READ: 2,
};
