export const num2name = {
    0: "BLOCK_TYPE_UNSPECIFIED",
    1: "INFORMATION",
    2: "INCREASE",
    3: "DECREASE",
    4: "AI_PICK",
};
export const name2num = {
    BLOCK_TYPE_UNSPECIFIED: 0,
    INFORMATION: 1,
    INCREASE: 2,
    DECREASE: 3,
    AI_PICK: 4,
};
