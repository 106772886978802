// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        dalc: "",
        skill: "",
        deprecatedPart: "",
        chapter: "",
        parts: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.dalc !== undefined)
        result.dalc = tsValueToJsonValueFns.string(value.dalc);
    if (value.skill !== undefined)
        result.skill = tsValueToJsonValueFns.string(value.skill);
    if (value.deprecatedPart !== undefined)
        result.deprecatedPart = tsValueToJsonValueFns.string(value.deprecatedPart);
    if (value.chapter !== undefined)
        result.chapter = tsValueToJsonValueFns.string(value.chapter);
    result.parts = value.parts.map(value => tsValueToJsonValueFns.string(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.dalc !== undefined)
        result.dalc = jsonValueToTsValueFns.string(value.dalc);
    if (value.skill !== undefined)
        result.skill = jsonValueToTsValueFns.string(value.skill);
    if (value.deprecatedPart !== undefined)
        result.deprecatedPart = jsonValueToTsValueFns.string(value.deprecatedPart);
    if (value.chapter !== undefined)
        result.chapter = jsonValueToTsValueFns.string(value.chapter);
    result.parts = (_b = (_a = value.parts) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.string(value))) !== null && _b !== void 0 ? _b : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.dalc !== undefined) {
        const tsValue = value.dalc;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.skill !== undefined) {
        const tsValue = value.skill;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.deprecatedPart !== undefined) {
        const tsValue = value.deprecatedPart;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.chapter !== undefined) {
        const tsValue = value.chapter;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.parts) {
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.dalc = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.skill = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.deprecatedPart = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.chapter = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValueToTsValueFns.string(wireValue)).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.parts = value;
    }
    return result;
}
