export const num2name = {
    0: "CELL_TYPE_SELECTION_UNSPECIFIED",
    1: "QUESTION",
    2: "MOCK_EXAM",
    3: "DIAGNOSIS",
};
export const name2num = {
    CELL_TYPE_SELECTION_UNSPECIFIED: 0,
    QUESTION: 1,
    MOCK_EXAM: 2,
    DIAGNOSIS: 3,
};
