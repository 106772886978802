// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./ToeicVirtualExam";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./ContentUserAnswerState";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "../../inside/ContentInfo";
import { name2num, num2name, } from "./(ToeicVirtualExamInstance)/Status";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        sessionId: "0",
        examData: undefined,
        contentUserAnswerStates: [],
        totalElapsedTimeMs: "0",
        contentInfos: [],
        status: "STATUS_UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.sessionId !== undefined)
        result.sessionId = tsValueToJsonValueFns.int64(value.sessionId);
    if (value.examData !== undefined)
        result.examData = encodeJson_1(value.examData);
    result.contentUserAnswerStates = value.contentUserAnswerStates.map(value => encodeJson_2(value));
    if (value.totalElapsedTimeMs !== undefined)
        result.totalElapsedTimeMs = tsValueToJsonValueFns.int64(value.totalElapsedTimeMs);
    result.contentInfos = value.contentInfos.map(value => encodeJson_3(value));
    if (value.status !== undefined)
        result.status = tsValueToJsonValueFns.enum(value.status);
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.sessionId !== undefined)
        result.sessionId = jsonValueToTsValueFns.int64(value.sessionId);
    if (value.examData !== undefined)
        result.examData = decodeJson_1(value.examData);
    result.contentUserAnswerStates = (_b = (_a = value.contentUserAnswerStates) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_2(value))) !== null && _b !== void 0 ? _b : [];
    if (value.totalElapsedTimeMs !== undefined)
        result.totalElapsedTimeMs = jsonValueToTsValueFns.int64(value.totalElapsedTimeMs);
    result.contentInfos = (_d = (_c = value.contentInfos) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_3(value))) !== null && _d !== void 0 ? _d : [];
    if (value.status !== undefined)
        result.status = jsonValueToTsValueFns.enum(value.status);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.sessionId !== undefined) {
        const tsValue = value.sessionId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.examData !== undefined) {
        const tsValue = value.examData;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.contentUserAnswerStates) {
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.totalElapsedTimeMs !== undefined) {
        const tsValue = value.totalElapsedTimeMs;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    for (const tsValue of value.contentInfos) {
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
    }
    if (value.status !== undefined) {
        const tsValue = value.status;
        result.push([6, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.sessionId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.examData = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.contentUserAnswerStates = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.totalElapsedTimeMs = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.contentInfos = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.status = value;
    }
    return result;
}
