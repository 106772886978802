// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./Question";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "./MockExam";
import { encodeJson as encodeJson_3, decodeJson as decodeJson_3, encodeBinary as encodeBinary_3, decodeBinary as decodeBinary_3, } from "./Lesson";
import { encodeJson as encodeJson_4, decodeJson as decodeJson_4, encodeBinary as encodeBinary_4, decodeBinary as decodeBinary_4, } from "./Vocabulary";
import { encodeJson as encodeJson_5, decodeJson as decodeJson_5, encodeBinary as encodeBinary_5, decodeBinary as decodeBinary_5, } from "./Diagnosis";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        cisOneof: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    switch ((_a = value.cisOneof) === null || _a === void 0 ? void 0 : _a.field) {
        case "question": {
            result.question = encodeJson_1(value.cisOneof.value);
            break;
        }
        case "mockExam": {
            result.mockExam = encodeJson_2(value.cisOneof.value);
            break;
        }
        case "lesson": {
            result.lesson = encodeJson_3(value.cisOneof.value);
            break;
        }
        case "vocabulary": {
            result.vocabulary = encodeJson_4(value.cisOneof.value);
            break;
        }
        case "diagnosis": {
            result.diagnosis = encodeJson_5(value.cisOneof.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.question !== undefined)
        result.cisOneof = { field: "question", value: decodeJson_1(value.question) };
    if (value.mockExam !== undefined)
        result.cisOneof = { field: "mockExam", value: decodeJson_2(value.mockExam) };
    if (value.lesson !== undefined)
        result.cisOneof = { field: "lesson", value: decodeJson_3(value.lesson) };
    if (value.vocabulary !== undefined)
        result.cisOneof = { field: "vocabulary", value: decodeJson_4(value.vocabulary) };
    if (value.diagnosis !== undefined)
        result.cisOneof = { field: "diagnosis", value: decodeJson_5(value.diagnosis) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    switch ((_a = value.cisOneof) === null || _a === void 0 ? void 0 : _a.field) {
        case "question": {
            const tsValue = value.cisOneof.value;
            result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
        case "mockExam": {
            const tsValue = value.cisOneof.value;
            result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
            break;
        }
        case "lesson": {
            const tsValue = value.cisOneof.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_3(tsValue) }]);
            break;
        }
        case "vocabulary": {
            const tsValue = value.cisOneof.value;
            result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_4(tsValue) }]);
            break;
        }
        case "diagnosis": {
            const tsValue = value.cisOneof.value;
            result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_5(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    cisOneof: new Set([1, 2, 3, 4, 5]),
};
const oneofFieldNamesMap = {
    cisOneof: new Map([
        [1, "question"],
        [2, "mockExam"],
        [3, "lesson"],
        [4, "vocabulary"],
        [5, "diagnosis"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.cisOneof;
        const oneofFieldNames = oneofFieldNamesMap.cisOneof;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [1](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
            [2](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined; },
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_3(wireValue.value) : undefined; },
            [4](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_4(wireValue.value) : undefined; },
            [5](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_5(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.cisOneof = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
