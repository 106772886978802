export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_INVALID_CIS_HIGHLIGHT_INFO_TEMPLATE",
    2: "ERROR_INVALID_CIS_HIGHLIGHT_INFO_ID",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_INVALID_CIS_HIGHLIGHT_INFO_TEMPLATE: 1,
    ERROR_INVALID_CIS_HIGHLIGHT_INFO_ID: 2,
};
