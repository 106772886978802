export const num2name = {
    0: "LEARNING_DOMAIN_UNSPECIFIED",
    1: "TOEIC",
    2: "G_TELP",
};
export const name2num = {
    LEARNING_DOMAIN_UNSPECIFIED: 0,
    TOEIC: 1,
    G_TELP: 2,
};
