// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./(GiftCode)/GiftCodeData";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../google/protobuf/Int64Value";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "0",
        type: "",
        userData: [],
        manageData: [],
        fulfillmentId: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.int64(value.id);
    if (value.type !== undefined)
        result.type = tsValueToJsonValueFns.string(value.type);
    result.userData = value.userData.map(value => encodeJson_1(value));
    result.manageData = value.manageData.map(value => encodeJson_1(value));
    if (value.fulfillmentId !== undefined)
        result.fulfillmentId = value.fulfillmentId;
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d;
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.int64(value.id);
    if (value.type !== undefined)
        result.type = jsonValueToTsValueFns.string(value.type);
    result.userData = (_b = (_a = value.userData) === null || _a === void 0 ? void 0 : _a.map((value) => decodeJson_1(value))) !== null && _b !== void 0 ? _b : [];
    result.manageData = (_d = (_c = value.manageData) === null || _c === void 0 ? void 0 : _c.map((value) => decodeJson_1(value))) !== null && _d !== void 0 ? _d : [];
    if (value.fulfillmentId !== undefined)
        result.fulfillmentId = value.fulfillmentId;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.type !== undefined) {
        const tsValue = value.type;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    for (const tsValue of value.userData) {
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    for (const tsValue of value.manageData) {
        result.push([4, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.fulfillmentId !== undefined) {
        const tsValue = value.fulfillmentId;
        result.push([5, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.type = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.userData = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 4).map(([, wireValue]) => wireValue);
        const value = wireValues.map((wireValue) => wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined).filter(x => x !== undefined);
        if (!value.length)
            break collection;
        result.manageData = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.fulfillmentId = value;
    }
    return result;
}
