export const num2name = {
    0: "QUIZ_ORDER_UNSPECIFIED",
    1: "DESC_BY_UPDATED_AT",
    2: "ASC_BY_UPDATED_AT",
    3: "RANDOM",
};
export const name2num = {
    QUIZ_ORDER_UNSPECIFIED: 0,
    DESC_BY_UPDATED_AT: 1,
    ASC_BY_UPDATED_AT: 2,
    RANDOM: 3,
};
