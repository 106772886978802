// @ts-nocheck
import { name2num, num2name, } from "../Domain";
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        id: "",
        domain: "UNSPECIFIED_DOMAIN",
        mimeType: "",
        upsertUrl: "",
        displayName: "",
        createTime: undefined,
        createdBy: "",
        referenceCount: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.id !== undefined)
        result.id = tsValueToJsonValueFns.string(value.id);
    if (value.domain !== undefined)
        result.domain = tsValueToJsonValueFns.enum(value.domain);
    if (value.mimeType !== undefined)
        result.mimeType = tsValueToJsonValueFns.string(value.mimeType);
    if (value.upsertUrl !== undefined)
        result.upsertUrl = tsValueToJsonValueFns.string(value.upsertUrl);
    if (value.displayName !== undefined)
        result.displayName = tsValueToJsonValueFns.string(value.displayName);
    if (value.createTime !== undefined)
        result.createTime = encodeJson_1(value.createTime);
    if (value.createdBy !== undefined)
        result.createdBy = tsValueToJsonValueFns.string(value.createdBy);
    if (value.referenceCount !== undefined)
        result.referenceCount = tsValueToJsonValueFns.uint64(value.referenceCount);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.id !== undefined)
        result.id = jsonValueToTsValueFns.string(value.id);
    if (value.domain !== undefined)
        result.domain = jsonValueToTsValueFns.enum(value.domain);
    if (value.mimeType !== undefined)
        result.mimeType = jsonValueToTsValueFns.string(value.mimeType);
    if (value.upsertUrl !== undefined)
        result.upsertUrl = jsonValueToTsValueFns.string(value.upsertUrl);
    if (value.displayName !== undefined)
        result.displayName = jsonValueToTsValueFns.string(value.displayName);
    if (value.createTime !== undefined)
        result.createTime = decodeJson_1(value.createTime);
    if (value.createdBy !== undefined)
        result.createdBy = jsonValueToTsValueFns.string(value.createdBy);
    if (value.referenceCount !== undefined)
        result.referenceCount = jsonValueToTsValueFns.uint64(value.referenceCount);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.id !== undefined) {
        const tsValue = value.id;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.domain !== undefined) {
        const tsValue = value.domain;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.mimeType !== undefined) {
        const tsValue = value.mimeType;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.upsertUrl !== undefined) {
        const tsValue = value.upsertUrl;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.displayName !== undefined) {
        const tsValue = value.displayName;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.createTime !== undefined) {
        const tsValue = value.createTime;
        result.push([6, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.createdBy !== undefined) {
        const tsValue = value.createdBy;
        result.push([7, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.referenceCount !== undefined) {
        const tsValue = value.referenceCount;
        result.push([8, tsValueToWireValueFns.uint64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.id = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.domain = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.mimeType = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.upsertUrl = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.displayName = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.createTime = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.createdBy = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.uint64(wireValue);
        if (value === undefined)
            break field;
        result.referenceCount = value;
    }
    return result;
}
