export const num2name = {
    0: "TARGET_USER_BY_PAYMENT_UNSPECIFIED",
    1: "ALL_USERS",
    2: "PAID_USERS_ONLY",
    3: "NON_PAID_USERS_ONLY",
};
export const name2num = {
    TARGET_USER_BY_PAYMENT_UNSPECIFIED: 0,
    ALL_USERS: 1,
    PAID_USERS_ONLY: 2,
    NON_PAID_USERS_ONLY: 3,
};
