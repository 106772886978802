// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        sentence: "",
        start: "0",
        end: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.sentence !== undefined)
        result.sentence = tsValueToJsonValueFns.string(value.sentence);
    if (value.start !== undefined)
        result.start = tsValueToJsonValueFns.int64(value.start);
    if (value.end !== undefined)
        result.end = tsValueToJsonValueFns.int64(value.end);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.sentence !== undefined)
        result.sentence = jsonValueToTsValueFns.string(value.sentence);
    if (value.start !== undefined)
        result.start = jsonValueToTsValueFns.int64(value.start);
    if (value.end !== undefined)
        result.end = jsonValueToTsValueFns.int64(value.end);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.sentence !== undefined) {
        const tsValue = value.sentence;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.start !== undefined) {
        const tsValue = value.start;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.end !== undefined) {
        const tsValue = value.end;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.sentence = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.start = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.end = value;
    }
    return result;
}
