export const num2name = {
    0: "ERROR_UNSPECIFIED",
    1: "ERROR_VIRTUAL_EXAM_INSTANCE_NOT_FOUND",
    2: "ERROR_LOADING_CONTENT_INFOS",
};
export const name2num = {
    ERROR_UNSPECIFIED: 0,
    ERROR_VIRTUAL_EXAM_INSTANCE_NOT_FOUND: 1,
    ERROR_LOADING_CONTENT_INFOS: 2,
};
