export const num2name = {
    0: "NO_ERROR",
    1: "ERROR_ID_DOES_NOT_MATCH",
    2: "ERROR_INAPPROPRIATE_FOR_CURRENT_USER",
};
export const name2num = {
    NO_ERROR: 0,
    ERROR_ID_DOES_NOT_MATCH: 1,
    ERROR_INAPPROPRIATE_FOR_CURRENT_USER: 2,
};
