// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../../../runtime/json/scalar";
import { WireType, } from "../../../../../../../runtime/wire/index";
import { default as serialize, } from "../../../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../../../runtime/wire/scalar";
import { encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../../../../../google/protobuf/Int64Value";
import { default as deserialize, } from "../../../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        lcScore: "0",
        rcScore: "0",
        lcScoreError: "0",
        rcScoreError: "0",
        totalScoreError: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.lcScore !== undefined)
        result.lcScore = tsValueToJsonValueFns.int64(value.lcScore);
    if (value.rcScore !== undefined)
        result.rcScore = tsValueToJsonValueFns.int64(value.rcScore);
    if (value.lcScoreError !== undefined)
        result.lcScoreError = tsValueToJsonValueFns.int64(value.lcScoreError);
    if (value.rcScoreError !== undefined)
        result.rcScoreError = tsValueToJsonValueFns.int64(value.rcScoreError);
    if (value.totalScoreError !== undefined)
        result.totalScoreError = value.totalScoreError;
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.lcScore !== undefined)
        result.lcScore = jsonValueToTsValueFns.int64(value.lcScore);
    if (value.rcScore !== undefined)
        result.rcScore = jsonValueToTsValueFns.int64(value.rcScore);
    if (value.lcScoreError !== undefined)
        result.lcScoreError = jsonValueToTsValueFns.int64(value.lcScoreError);
    if (value.rcScoreError !== undefined)
        result.rcScoreError = jsonValueToTsValueFns.int64(value.rcScoreError);
    if (value.totalScoreError !== undefined)
        result.totalScoreError = value.totalScoreError;
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.lcScore !== undefined) {
        const tsValue = value.lcScore;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.rcScore !== undefined) {
        const tsValue = value.rcScore;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.lcScoreError !== undefined) {
        const tsValue = value.lcScoreError;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.rcScoreError !== undefined) {
        const tsValue = value.rcScoreError;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.totalScoreError !== undefined) {
        const tsValue = value.totalScoreError;
        result.push([5, ((tsValue) => ({ type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }))({ value: tsValue })]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.lcScore = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.rcScore = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.lcScoreError = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.rcScoreError = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = (_a = (wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined)) === null || _a === void 0 ? void 0 : _a.value;
        if (value === undefined)
            break field;
        result.totalScoreError = value;
    }
    return result;
}
