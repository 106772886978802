// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../google/protobuf/Timestamp";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { WireType, } from "../../runtime/wire/index";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        clientVersion: "",
        os: "",
        osVersion: "",
        deviceId: "",
        timestampDevice: undefined,
        metaString: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.clientVersion !== undefined)
        result.clientVersion = tsValueToJsonValueFns.string(value.clientVersion);
    if (value.os !== undefined)
        result.os = tsValueToJsonValueFns.string(value.os);
    if (value.osVersion !== undefined)
        result.osVersion = tsValueToJsonValueFns.string(value.osVersion);
    if (value.deviceId !== undefined)
        result.deviceId = tsValueToJsonValueFns.string(value.deviceId);
    if (value.timestampDevice !== undefined)
        result.timestampDevice = encodeJson_1(value.timestampDevice);
    if (value.metaString !== undefined)
        result.metaString = tsValueToJsonValueFns.string(value.metaString);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.clientVersion !== undefined)
        result.clientVersion = jsonValueToTsValueFns.string(value.clientVersion);
    if (value.os !== undefined)
        result.os = jsonValueToTsValueFns.string(value.os);
    if (value.osVersion !== undefined)
        result.osVersion = jsonValueToTsValueFns.string(value.osVersion);
    if (value.deviceId !== undefined)
        result.deviceId = jsonValueToTsValueFns.string(value.deviceId);
    if (value.timestampDevice !== undefined)
        result.timestampDevice = decodeJson_1(value.timestampDevice);
    if (value.metaString !== undefined)
        result.metaString = jsonValueToTsValueFns.string(value.metaString);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.clientVersion !== undefined) {
        const tsValue = value.clientVersion;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.os !== undefined) {
        const tsValue = value.os;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.osVersion !== undefined) {
        const tsValue = value.osVersion;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.deviceId !== undefined) {
        const tsValue = value.deviceId;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.timestampDevice !== undefined) {
        const tsValue = value.timestampDevice;
        result.push([5, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.metaString !== undefined) {
        const tsValue = value.metaString;
        result.push([6, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.clientVersion = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.os = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.osVersion = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.deviceId = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.timestampDevice = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.metaString = value;
    }
    return result;
}
