// @ts-nocheck
import { name2num, num2name, } from "./(Filter)/Correctness";
import { name2num as name2num_1, num2name as num2name_1, } from "./(Filter)/Type";
import { name2num as name2num_2, num2name as num2name_2, } from "../../Part";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { default as Long, } from "../../../../runtime/Long";
import { tsValueToWireValueFns, unpackFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        correctness: [],
        type: [],
        part: [],
        bookmarked: false,
        virtualExamIds: [],
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    result.correctness = value.correctness.map(value => tsValueToJsonValueFns.enum(value));
    result.type = value.type.map(value => tsValueToJsonValueFns.enum(value));
    result.part = value.part.map(value => tsValueToJsonValueFns.enum(value));
    if (value.bookmarked !== undefined)
        result.bookmarked = tsValueToJsonValueFns.bool(value.bookmarked);
    result.virtualExamIds = value.virtualExamIds.map(value => tsValueToJsonValueFns.int64(value));
    return result;
}
export function decodeJson(value) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const result = getDefaultValue();
    result.correctness = (_b = (_a = value.correctness) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _b !== void 0 ? _b : [];
    result.type = (_d = (_c = value.type) === null || _c === void 0 ? void 0 : _c.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _d !== void 0 ? _d : [];
    result.part = (_f = (_e = value.part) === null || _e === void 0 ? void 0 : _e.map((value) => jsonValueToTsValueFns.enum(value))) !== null && _f !== void 0 ? _f : [];
    if (value.bookmarked !== undefined)
        result.bookmarked = jsonValueToTsValueFns.bool(value.bookmarked);
    result.virtualExamIds = (_h = (_g = value.virtualExamIds) === null || _g === void 0 ? void 0 : _g.map((value) => jsonValueToTsValueFns.int64(value))) !== null && _h !== void 0 ? _h : [];
    return result;
}
export function encodeBinary(value) {
    const result = [];
    for (const tsValue of value.correctness) {
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    for (const tsValue of value.type) {
        result.push([2, { type: WireType.Varint, value: new Long(name2num_1[tsValue]) }]);
    }
    for (const tsValue of value.part) {
        result.push([3, { type: WireType.Varint, value: new Long(name2num_2[tsValue]) }]);
    }
    if (value.bookmarked !== undefined) {
        const tsValue = value.bookmarked;
        result.push([4, tsValueToWireValueFns.bool(tsValue)]);
    }
    for (const tsValue of value.virtualExamIds) {
        result.push([5, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 1).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name[num]);
        if (!value.length)
            break collection;
        result.correctness = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 2).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_1[num]);
        if (!value.length)
            break collection;
        result.type = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 3).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int32(wireValues)).map(num => num2name_2[num]);
        if (!value.length)
            break collection;
        result.part = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.bookmarked = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 5).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int64(wireValues));
        if (!value.length)
            break collection;
        result.virtualExamIds = value;
    }
    return result;
}
