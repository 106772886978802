export const num2name = {
    0: "UNSPECIFIED",
    1: "SATISFIED",
    2: "UNBALANCE",
    3: "UNRELATED",
    4: "UNCORRELATED",
    5: "MOBILE_DIFFICULT",
    6: "OVERLAP",
    7: "ETC",
};
export const name2num = {
    UNSPECIFIED: 0,
    SATISFIED: 1,
    UNBALANCE: 2,
    UNRELATED: 3,
    UNCORRELATED: 4,
    MOBILE_DIFFICULT: 5,
    OVERLAP: 6,
    ETC: 7,
};
