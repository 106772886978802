export const num2name = {
    0: "STATUS_UNSPECIFIED",
    1: "STATUS_CREATED",
    2: "STATUS_ONGOING",
    3: "STATUS_SUBMITTED",
    4: "STATUS_FINISHED",
};
export const name2num = {
    STATUS_UNSPECIFIED: 0,
    STATUS_CREATED: 1,
    STATUS_ONGOING: 2,
    STATUS_SUBMITTED: 3,
    STATUS_FINISHED: 4,
};
