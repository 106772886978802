// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "./AudioDirectionMappings";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, unpackFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        audioToeicResourceId: "0",
        audioListeningMappings: [],
        audioDirectionMappings: undefined,
        title: "",
        isNew: false,
        volNumber: "0",
        lcDurationMs: "0",
        rcDurationMs: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.audioToeicResourceId !== undefined)
        result.audioToeicResourceId = tsValueToJsonValueFns.int64(value.audioToeicResourceId);
    result.audioListeningMappings = value.audioListeningMappings.map(value => tsValueToJsonValueFns.int64(value));
    if (value.audioDirectionMappings !== undefined)
        result.audioDirectionMappings = encodeJson_1(value.audioDirectionMappings);
    if (value.title !== undefined)
        result.title = tsValueToJsonValueFns.string(value.title);
    if (value.isNew !== undefined)
        result.isNew = tsValueToJsonValueFns.bool(value.isNew);
    if (value.volNumber !== undefined)
        result.volNumber = tsValueToJsonValueFns.int64(value.volNumber);
    if (value.lcDurationMs !== undefined)
        result.lcDurationMs = tsValueToJsonValueFns.int64(value.lcDurationMs);
    if (value.rcDurationMs !== undefined)
        result.rcDurationMs = tsValueToJsonValueFns.int64(value.rcDurationMs);
    return result;
}
export function decodeJson(value) {
    var _a, _b;
    const result = getDefaultValue();
    if (value.audioToeicResourceId !== undefined)
        result.audioToeicResourceId = jsonValueToTsValueFns.int64(value.audioToeicResourceId);
    result.audioListeningMappings = (_b = (_a = value.audioListeningMappings) === null || _a === void 0 ? void 0 : _a.map((value) => jsonValueToTsValueFns.int64(value))) !== null && _b !== void 0 ? _b : [];
    if (value.audioDirectionMappings !== undefined)
        result.audioDirectionMappings = decodeJson_1(value.audioDirectionMappings);
    if (value.title !== undefined)
        result.title = jsonValueToTsValueFns.string(value.title);
    if (value.isNew !== undefined)
        result.isNew = jsonValueToTsValueFns.bool(value.isNew);
    if (value.volNumber !== undefined)
        result.volNumber = jsonValueToTsValueFns.int64(value.volNumber);
    if (value.lcDurationMs !== undefined)
        result.lcDurationMs = jsonValueToTsValueFns.int64(value.lcDurationMs);
    if (value.rcDurationMs !== undefined)
        result.rcDurationMs = jsonValueToTsValueFns.int64(value.rcDurationMs);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.audioToeicResourceId !== undefined) {
        const tsValue = value.audioToeicResourceId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    for (const tsValue of value.audioListeningMappings) {
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.audioDirectionMappings !== undefined) {
        const tsValue = value.audioDirectionMappings;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.title !== undefined) {
        const tsValue = value.title;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.isNew !== undefined) {
        const tsValue = value.isNew;
        result.push([5, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.volNumber !== undefined) {
        const tsValue = value.volNumber;
        result.push([6, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.lcDurationMs !== undefined) {
        const tsValue = value.lcDurationMs;
        result.push([7, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.rcDurationMs !== undefined) {
        const tsValue = value.rcDurationMs;
        result.push([8, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.audioToeicResourceId = value;
    }
    collection: {
        const wireValues = wireMessage.filter(([fieldNumber]) => fieldNumber === 2).map(([, wireValue]) => wireValue);
        const value = Array.from(unpackFns.int64(wireValues));
        if (!value.length)
            break collection;
        result.audioListeningMappings = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.audioDirectionMappings = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.title = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isNew = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.volNumber = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.lcDurationMs = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.rcDurationMs = value;
    }
    return result;
}
