// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        isActive: false,
        text: "",
        appTargetUrl: "",
        webTargetUrl: "",
        webNewWindow: false,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.isActive !== undefined)
        result.isActive = tsValueToJsonValueFns.bool(value.isActive);
    if (value.text !== undefined)
        result.text = tsValueToJsonValueFns.string(value.text);
    if (value.appTargetUrl !== undefined)
        result.appTargetUrl = tsValueToJsonValueFns.string(value.appTargetUrl);
    if (value.webTargetUrl !== undefined)
        result.webTargetUrl = tsValueToJsonValueFns.string(value.webTargetUrl);
    if (value.webNewWindow !== undefined)
        result.webNewWindow = tsValueToJsonValueFns.bool(value.webNewWindow);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.isActive !== undefined)
        result.isActive = jsonValueToTsValueFns.bool(value.isActive);
    if (value.text !== undefined)
        result.text = jsonValueToTsValueFns.string(value.text);
    if (value.appTargetUrl !== undefined)
        result.appTargetUrl = jsonValueToTsValueFns.string(value.appTargetUrl);
    if (value.webTargetUrl !== undefined)
        result.webTargetUrl = jsonValueToTsValueFns.string(value.webTargetUrl);
    if (value.webNewWindow !== undefined)
        result.webNewWindow = jsonValueToTsValueFns.bool(value.webNewWindow);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.isActive !== undefined) {
        const tsValue = value.isActive;
        result.push([1, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.text !== undefined) {
        const tsValue = value.text;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.appTargetUrl !== undefined) {
        const tsValue = value.appTargetUrl;
        result.push([3, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.webTargetUrl !== undefined) {
        const tsValue = value.webTargetUrl;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.webNewWindow !== undefined) {
        const tsValue = value.webNewWindow;
        result.push([5, tsValueToWireValueFns.bool(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isActive = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.text = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.appTargetUrl = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.webTargetUrl = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.webNewWindow = value;
    }
    return result;
}
