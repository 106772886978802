// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../runtime/json/scalar";
import { default as serialize, } from "../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../runtime/wire/scalar";
import { default as deserialize, } from "../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        tag: "",
        currentProbability: 0,
        targetProbability: 0,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.tag !== undefined)
        result.tag = tsValueToJsonValueFns.string(value.tag);
    if (value.currentProbability !== undefined)
        result.currentProbability = tsValueToJsonValueFns.float(value.currentProbability);
    if (value.targetProbability !== undefined)
        result.targetProbability = tsValueToJsonValueFns.float(value.targetProbability);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.tag !== undefined)
        result.tag = jsonValueToTsValueFns.string(value.tag);
    if (value.currentProbability !== undefined)
        result.currentProbability = jsonValueToTsValueFns.float(value.currentProbability);
    if (value.targetProbability !== undefined)
        result.targetProbability = jsonValueToTsValueFns.float(value.targetProbability);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.tag !== undefined) {
        const tsValue = value.tag;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.currentProbability !== undefined) {
        const tsValue = value.currentProbability;
        result.push([2, tsValueToWireValueFns.float(tsValue)]);
    }
    if (value.targetProbability !== undefined) {
        const tsValue = value.targetProbability;
        result.push([3, tsValueToWireValueFns.float(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.tag = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.float(wireValue);
        if (value === undefined)
            break field;
        result.currentProbability = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.float(wireValue);
        if (value === undefined)
            break field;
        result.targetProbability = value;
    }
    return result;
}
