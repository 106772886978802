// @ts-nocheck
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../../runtime/json/scalar";
import { default as serialize, } from "../../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        authId: "",
        json: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.authId !== undefined)
        result.authId = tsValueToJsonValueFns.string(value.authId);
    if (value.json !== undefined)
        result.json = tsValueToJsonValueFns.string(value.json);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.authId !== undefined)
        result.authId = jsonValueToTsValueFns.string(value.authId);
    if (value.json !== undefined)
        result.json = jsonValueToTsValueFns.string(value.json);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.authId !== undefined) {
        const tsValue = value.authId;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.json !== undefined) {
        const tsValue = value.json;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.authId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.json = value;
    }
    return result;
}
