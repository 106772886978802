// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../StringList";
import { name2num, num2name, } from "./(ListAvailableSelfCardIdentifiersResponse)/Error";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { default as Long, } from "../../../runtime/Long";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        error: "ERROR_UNSPECIFIED",
        errorMessage: "",
        identifiers: undefined,
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    var _a;
    const result = {};
    if (value.error !== undefined)
        result.error = tsValueToJsonValueFns.enum(value.error);
    if (value.errorMessage !== undefined)
        result.errorMessage = tsValueToJsonValueFns.string(value.errorMessage);
    switch ((_a = value.identifiers) === null || _a === void 0 ? void 0 : _a.field) {
        case "lessonV2ContentIds": {
            result.lessonV2ContentIds = encodeJson_1(value.identifiers.value);
            break;
        }
    }
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.error !== undefined)
        result.error = jsonValueToTsValueFns.enum(value.error);
    if (value.errorMessage !== undefined)
        result.errorMessage = jsonValueToTsValueFns.string(value.errorMessage);
    if (value.lessonV2ContentIds !== undefined)
        result.identifiers = { field: "lessonV2ContentIds", value: decodeJson_1(value.lessonV2ContentIds) };
    return result;
}
export function encodeBinary(value) {
    var _a;
    const result = [];
    if (value.error !== undefined) {
        const tsValue = value.error;
        result.push([1, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.errorMessage !== undefined) {
        const tsValue = value.errorMessage;
        result.push([2, tsValueToWireValueFns.string(tsValue)]);
    }
    switch ((_a = value.identifiers) === null || _a === void 0 ? void 0 : _a.field) {
        case "lessonV2ContentIds": {
            const tsValue = value.identifiers.value;
            result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
            break;
        }
    }
    return serialize(result);
}
const oneofFieldNumbersMap = {
    identifiers: new Set([3]),
};
const oneofFieldNamesMap = {
    identifiers: new Map([
        [3, "lessonV2ContentIds"],
    ]),
};
export function decodeBinary(binary) {
    var _a;
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    const wireFieldNumbers = Array.from(wireFields.keys()).reverse();
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.error = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.errorMessage = value;
    }
    oneof: {
        const oneofFieldNumbers = oneofFieldNumbersMap.identifiers;
        const oneofFieldNames = oneofFieldNamesMap.identifiers;
        const fieldNumber = wireFieldNumbers.find(v => oneofFieldNumbers.has(v));
        if (fieldNumber == null)
            break oneof;
        const wireValue = wireFields.get(fieldNumber);
        const wireValueToTsValueMap = {
            [3](wireValue) { return wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined; },
        };
        const value = (_a = wireValueToTsValueMap[fieldNumber]) === null || _a === void 0 ? void 0 : _a(wireValue);
        if (value === undefined)
            break oneof;
        result.identifiers = { field: oneofFieldNames.get(fieldNumber), value: value };
    }
    return result;
}
