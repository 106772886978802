export const num2name = {
    0: "LEARNING_CYCLE_STATUS_UNSPECIFIED",
    1: "LEARNING_CYCLE_STATUS_EMPTY",
    2: "LEARNING_CYCLE_STATUS_CELL_CREATED",
    3: "LEARNING_CYCLE_STATUS_COMPLETED",
};
export const name2num = {
    LEARNING_CYCLE_STATUS_UNSPECIFIED: 0,
    LEARNING_CYCLE_STATUS_EMPTY: 1,
    LEARNING_CYCLE_STATUS_CELL_CREATED: 2,
    LEARNING_CYCLE_STATUS_COMPLETED: 3,
};
