// @ts-nocheck
import { name2num, num2name, } from "../virtual_exam/(ToeicVirtualExam)/Type";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as Long, } from "../../../runtime/Long";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        virtualExamId: "0",
        virtualExamType: "TYPE_UNSPECIFIED",
        studiedCount: "0",
        totalCount: "0",
        completeCount: "0",
        title: "",
        isNew: false,
        volNumber: "0",
        lcDurationMs: "0",
        rcDurationMs: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.virtualExamId !== undefined)
        result.virtualExamId = tsValueToJsonValueFns.int64(value.virtualExamId);
    if (value.virtualExamType !== undefined)
        result.virtualExamType = tsValueToJsonValueFns.enum(value.virtualExamType);
    if (value.studiedCount !== undefined)
        result.studiedCount = tsValueToJsonValueFns.int64(value.studiedCount);
    if (value.totalCount !== undefined)
        result.totalCount = tsValueToJsonValueFns.int64(value.totalCount);
    if (value.completeCount !== undefined)
        result.completeCount = tsValueToJsonValueFns.int64(value.completeCount);
    if (value.title !== undefined)
        result.title = tsValueToJsonValueFns.string(value.title);
    if (value.isNew !== undefined)
        result.isNew = tsValueToJsonValueFns.bool(value.isNew);
    if (value.volNumber !== undefined)
        result.volNumber = tsValueToJsonValueFns.int64(value.volNumber);
    if (value.lcDurationMs !== undefined)
        result.lcDurationMs = tsValueToJsonValueFns.int64(value.lcDurationMs);
    if (value.rcDurationMs !== undefined)
        result.rcDurationMs = tsValueToJsonValueFns.int64(value.rcDurationMs);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.virtualExamId !== undefined)
        result.virtualExamId = jsonValueToTsValueFns.int64(value.virtualExamId);
    if (value.virtualExamType !== undefined)
        result.virtualExamType = jsonValueToTsValueFns.enum(value.virtualExamType);
    if (value.studiedCount !== undefined)
        result.studiedCount = jsonValueToTsValueFns.int64(value.studiedCount);
    if (value.totalCount !== undefined)
        result.totalCount = jsonValueToTsValueFns.int64(value.totalCount);
    if (value.completeCount !== undefined)
        result.completeCount = jsonValueToTsValueFns.int64(value.completeCount);
    if (value.title !== undefined)
        result.title = jsonValueToTsValueFns.string(value.title);
    if (value.isNew !== undefined)
        result.isNew = jsonValueToTsValueFns.bool(value.isNew);
    if (value.volNumber !== undefined)
        result.volNumber = jsonValueToTsValueFns.int64(value.volNumber);
    if (value.lcDurationMs !== undefined)
        result.lcDurationMs = jsonValueToTsValueFns.int64(value.lcDurationMs);
    if (value.rcDurationMs !== undefined)
        result.rcDurationMs = jsonValueToTsValueFns.int64(value.rcDurationMs);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.virtualExamId !== undefined) {
        const tsValue = value.virtualExamId;
        result.push([1, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.virtualExamType !== undefined) {
        const tsValue = value.virtualExamType;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    if (value.studiedCount !== undefined) {
        const tsValue = value.studiedCount;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.totalCount !== undefined) {
        const tsValue = value.totalCount;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.completeCount !== undefined) {
        const tsValue = value.completeCount;
        result.push([5, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.title !== undefined) {
        const tsValue = value.title;
        result.push([6, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.isNew !== undefined) {
        const tsValue = value.isNew;
        result.push([7, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.volNumber !== undefined) {
        const tsValue = value.volNumber;
        result.push([8, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.lcDurationMs !== undefined) {
        const tsValue = value.lcDurationMs;
        result.push([9, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.rcDurationMs !== undefined) {
        const tsValue = value.rcDurationMs;
        result.push([10, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.virtualExamId = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.virtualExamType = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.studiedCount = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.totalCount = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.completeCount = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.title = value;
    }
    field: {
        const wireValue = wireFields.get(7);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.isNew = value;
    }
    field: {
        const wireValue = wireFields.get(8);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.volNumber = value;
    }
    field: {
        const wireValue = wireFields.get(9);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.lcDurationMs = value;
    }
    field: {
        const wireValue = wireFields.get(10);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.rcDurationMs = value;
    }
    return result;
}
