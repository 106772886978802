// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Timestamp";
import { encodeJson as encodeJson_2, decodeJson as decodeJson_2, encodeBinary as encodeBinary_2, decodeBinary as decodeBinary_2, } from "../../google/protobuf/Duration";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        bookmarked: false,
        completedAt: undefined,
        playTime: undefined,
        title: "",
        part: "",
        skill: "",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.bookmarked !== undefined)
        result.bookmarked = tsValueToJsonValueFns.bool(value.bookmarked);
    if (value.completedAt !== undefined)
        result.completedAt = encodeJson_1(value.completedAt);
    if (value.playTime !== undefined)
        result.playTime = encodeJson_2(value.playTime);
    if (value.title !== undefined)
        result.title = tsValueToJsonValueFns.string(value.title);
    if (value.part !== undefined)
        result.part = tsValueToJsonValueFns.string(value.part);
    if (value.skill !== undefined)
        result.skill = tsValueToJsonValueFns.string(value.skill);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.bookmarked !== undefined)
        result.bookmarked = jsonValueToTsValueFns.bool(value.bookmarked);
    if (value.completedAt !== undefined)
        result.completedAt = decodeJson_1(value.completedAt);
    if (value.playTime !== undefined)
        result.playTime = decodeJson_2(value.playTime);
    if (value.title !== undefined)
        result.title = jsonValueToTsValueFns.string(value.title);
    if (value.part !== undefined)
        result.part = jsonValueToTsValueFns.string(value.part);
    if (value.skill !== undefined)
        result.skill = jsonValueToTsValueFns.string(value.skill);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.bookmarked !== undefined) {
        const tsValue = value.bookmarked;
        result.push([1, tsValueToWireValueFns.bool(tsValue)]);
    }
    if (value.completedAt !== undefined) {
        const tsValue = value.completedAt;
        result.push([2, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.playTime !== undefined) {
        const tsValue = value.playTime;
        result.push([3, { type: WireType.LengthDelimited, value: encodeBinary_2(tsValue) }]);
    }
    if (value.title !== undefined) {
        const tsValue = value.title;
        result.push([4, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.part !== undefined) {
        const tsValue = value.part;
        result.push([5, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.skill !== undefined) {
        const tsValue = value.skill;
        result.push([6, tsValueToWireValueFns.string(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.bool(wireValue);
        if (value === undefined)
            break field;
        result.bookmarked = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.completedAt = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_2(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.playTime = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.title = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.part = value;
    }
    field: {
        const wireValue = wireFields.get(6);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.skill = value;
    }
    return result;
}
