// @ts-nocheck
import { name2num, num2name, } from "./QueryParamDataType";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../../runtime/json/scalar";
import { WireType, } from "../../../../runtime/wire/index";
import { default as serialize, } from "../../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../../runtime/wire/scalar";
import { default as Long, } from "../../../../runtime/Long";
import { default as deserialize, } from "../../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        key: "",
        valueType: "QUERY_PARAM_DATA_TYPE_UNSPECIFIED",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.key !== undefined)
        result.key = tsValueToJsonValueFns.string(value.key);
    if (value.valueType !== undefined)
        result.valueType = tsValueToJsonValueFns.enum(value.valueType);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.key !== undefined)
        result.key = jsonValueToTsValueFns.string(value.key);
    if (value.valueType !== undefined)
        result.valueType = jsonValueToTsValueFns.enum(value.valueType);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.key !== undefined) {
        const tsValue = value.key;
        result.push([1, tsValueToWireValueFns.string(tsValue)]);
    }
    if (value.valueType !== undefined) {
        const tsValue = value.valueType;
        result.push([2, { type: WireType.Varint, value: new Long(name2num[tsValue]) }]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.string(wireValue);
        if (value === undefined)
            break field;
        result.key = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.Varint ? num2name[wireValue.value[0]] : undefined;
        if (value === undefined)
            break field;
        result.valueType = value;
    }
    return result;
}
