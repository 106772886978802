// @ts-nocheck
import { encodeJson as encodeJson_1, decodeJson as decodeJson_1, encodeBinary as encodeBinary_1, decodeBinary as decodeBinary_1, } from "../../google/protobuf/Duration";
import { tsValueToJsonValueFns, jsonValueToTsValueFns, } from "../../../runtime/json/scalar";
import { WireType, } from "../../../runtime/wire/index";
import { default as serialize, } from "../../../runtime/wire/serialize";
import { tsValueToWireValueFns, wireValueToTsValueFns, } from "../../../runtime/wire/scalar";
import { default as deserialize, } from "../../../runtime/wire/deserialize";
export function getDefaultValue() {
    return {
        totalElapsedTime: undefined,
        knowledgeCount: "0",
        totalVocabularyCount: "0",
        correctCount: "0",
        totalQuestionCount: "0",
    };
}
export function createValue(partialValue) {
    return {
        ...getDefaultValue(),
        ...partialValue,
    };
}
export function encodeJson(value) {
    const result = {};
    if (value.totalElapsedTime !== undefined)
        result.totalElapsedTime = encodeJson_1(value.totalElapsedTime);
    if (value.knowledgeCount !== undefined)
        result.knowledgeCount = tsValueToJsonValueFns.int64(value.knowledgeCount);
    if (value.totalVocabularyCount !== undefined)
        result.totalVocabularyCount = tsValueToJsonValueFns.int64(value.totalVocabularyCount);
    if (value.correctCount !== undefined)
        result.correctCount = tsValueToJsonValueFns.int64(value.correctCount);
    if (value.totalQuestionCount !== undefined)
        result.totalQuestionCount = tsValueToJsonValueFns.int64(value.totalQuestionCount);
    return result;
}
export function decodeJson(value) {
    const result = getDefaultValue();
    if (value.totalElapsedTime !== undefined)
        result.totalElapsedTime = decodeJson_1(value.totalElapsedTime);
    if (value.knowledgeCount !== undefined)
        result.knowledgeCount = jsonValueToTsValueFns.int64(value.knowledgeCount);
    if (value.totalVocabularyCount !== undefined)
        result.totalVocabularyCount = jsonValueToTsValueFns.int64(value.totalVocabularyCount);
    if (value.correctCount !== undefined)
        result.correctCount = jsonValueToTsValueFns.int64(value.correctCount);
    if (value.totalQuestionCount !== undefined)
        result.totalQuestionCount = jsonValueToTsValueFns.int64(value.totalQuestionCount);
    return result;
}
export function encodeBinary(value) {
    const result = [];
    if (value.totalElapsedTime !== undefined) {
        const tsValue = value.totalElapsedTime;
        result.push([1, { type: WireType.LengthDelimited, value: encodeBinary_1(tsValue) }]);
    }
    if (value.knowledgeCount !== undefined) {
        const tsValue = value.knowledgeCount;
        result.push([2, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.totalVocabularyCount !== undefined) {
        const tsValue = value.totalVocabularyCount;
        result.push([3, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.correctCount !== undefined) {
        const tsValue = value.correctCount;
        result.push([4, tsValueToWireValueFns.int64(tsValue)]);
    }
    if (value.totalQuestionCount !== undefined) {
        const tsValue = value.totalQuestionCount;
        result.push([5, tsValueToWireValueFns.int64(tsValue)]);
    }
    return serialize(result);
}
export function decodeBinary(binary) {
    const result = getDefaultValue();
    const wireMessage = deserialize(binary);
    const wireFields = new Map(wireMessage);
    field: {
        const wireValue = wireFields.get(1);
        if (wireValue === undefined)
            break field;
        const value = wireValue.type === WireType.LengthDelimited ? decodeBinary_1(wireValue.value) : undefined;
        if (value === undefined)
            break field;
        result.totalElapsedTime = value;
    }
    field: {
        const wireValue = wireFields.get(2);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.knowledgeCount = value;
    }
    field: {
        const wireValue = wireFields.get(3);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.totalVocabularyCount = value;
    }
    field: {
        const wireValue = wireFields.get(4);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.correctCount = value;
    }
    field: {
        const wireValue = wireFields.get(5);
        if (wireValue === undefined)
            break field;
        const value = wireValueToTsValueFns.int64(wireValue);
        if (value === undefined)
            break field;
        result.totalQuestionCount = value;
    }
    return result;
}
